import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Masks extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f5e50ad9f0ae66120eb1b4f",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Parıldadıcı Sprey",
        "descr": "Quru, boyalı və zəif saçlara parlaqlıq verir. Anti-statikdir və istilikdən qorumaq üçün ideal vasitədir. Bütün saç növlərinə uyğundur. İstifadə qaydası: şəkilləndirilmiş saçlara tətbiq edin. ",
        "code": "Style Perfetto Bling",
        "madein": "İtaliya",
        "price": 20.5,
        "size": "300ml.",
        "photo": "maska36.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e53969f0ae66120eb1b50",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Saç lakı",
        "descr": "Pantenol sayəsində saçı istidən qoruyur və ağırlıq yaratmadan saçları fiksasiya edir. Şakilləndirilimiş saçları uzun müddət saxlayır. İstifadə qaydası: şəkilləndirilmiş saçlara 20-25 sm məsafədən tətbiq edin.",
        "code": "Style Perfetto Fixer",
        "madein": "İtaliya",
        "price": 16,
        "size": "400ml.",
        "photo": "maska44.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e56089f0ae66120eb1b51",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Nəmləndirici və qoruyucu serum",
        "descr": "Quru və saçqıranlı saçları qidalandırır və nəmləndirir. Kətan yağı haçalanmış saçları müalicə edir, fenin və ütünün isti təsirindən qoruyur. Saçlar yumşaq, parlaq və sağlam görünür. Yağlılıq effekti yaratmır. İstifadə qaydası: saçları şəkilləndirmədən əvvəl tətbiq edin.",
        "code": "Style Perfetto Tri Action",
        "madein": "İtaliya",
        "price": 21,
        "size": "115ml.",
        "photo": "maska63.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e581c9f0ae66120eb1b52",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Mat Pasta",
        "descr": "Tərkibində olan gil ekstraktı, arı mumu və vitamin E saçları istənilən formada fiksasiya edir və mat görüntü yaradır. İstifadə qaydası: quru saçlara tətbiq edilir.",
        "code": "Style Perfetto Molding",
        "madein": "İtaliya",
        "price": 17,
        "size": "80ml.",
        "photo": "maska49.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e5ad19f0ae66120eb1b53",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Fiber krem",
        "descr": "Jojoba, badam yağı, düyü ekstraktı və vitamin E şəkilləndirilmiş saçlara həcmli görünüş verir. İstifadə qaydası: ovcunuzun içərisində isidin, təmiz və quru saçlara tətbiq edin.",
        "code": "Style Perfetto Unfinished",
        "madein": "İtaliya",
        "price": 17,
        "size": "80ml.",
        "photo": "maska64.png",
        "__v": 0
    },
    {
        "_id": "5f5e5c659f0ae66120eb1b54",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Qazsız lak",
        "descr": "Qazsız ekstra güclü lak şəkilləndirilmiş saçların uzun müddət qalmasını təmin edir və saçlara parlaqlıq verir. İstifadə qaydası: 20-25 sm məsafədən saçlarınıza tətbiq edin.",
        "code": "Style Perfetto Definer Extra Strong",
        "madein": "İtaliya",
        "price": 18,
        "size": "350ml.",
        "photo": "maska42.png",
        "__v": 0
    },
    {
        "_id": "5f5e63849f0ae66120eb1b55",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Natural şəkilləndirici",
        "descr": "Təbii yağsız yağ saçları şəkilləndirmədən əvvəl qıvrımların qarşısını almaq üçün tətbiq olunur. Saçları ağırlaşdırmadan elastiklik və natural parlaqlıq verir. İstifadə qaydası: nəm saça çəkilir, daranır və qurudulur. Beləliklə, qurumuş saçlara yüngül saç formasl verir.",
        "code": "Style Perfetto Primer",
        "madein": "İtaliya",
        "price": 16,
        "size": "200ml.",
        "photo": "maska58.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e65cd9f0ae66120eb1b56",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Həcmverici muss",
        "descr": "Orta fiksasiyalı həcmverici muss saçları fenin və ütünün təsirindən qoruyur, yumşaq və parlaq edir. Gicitkən və gülümbahar ekstraktları saçları nəmləndirir. İstifadə qaydası: təmiz, nəm saçlara tətbiq edin və qurudun.",
        "code": "Style Perfetto Volook",
        "madein": "İtaliya",
        "price": 17,
        "size": "300ml.",
        "photo": "maska65.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e678d9f0ae66120eb1b57",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Saç düzləşdirici krem",
        "descr": "Şəkilləndirməyə tabe olmayan saçları nəmləndirir və arzu etdiyiniz şəkildə düzləşdirir. Fenin, ütünün, rütubətin təsirindən qoruyur və düzləşdirmə effektini uzun müddət saxlayır. Saçlar parlaq və yumuşaq görünür. İstifadə qaydası: təmiz nəm saçlara çəkin, darayın və qurudun.",
        "code": "Style Perfetto Dazzling",
        "madein": "İtaliya",
        "price": 17,
        "size": "250ml.",
        "photo": "maska41.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e69279f0ae66120eb1b58",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Qıvrım saçlar üçün krem",
        "descr": "Qıvrım və dalğalı saçları qidalandırır və nəmləndirir. Vitamin E, günəbaxan və zeytun yağı tərkibli krem qıvrım saçları ağırlaşdırmadan, onlara yumşaqlıq və elastiklik verir. Fenin və ütünün isti təsirindən qoruyur. İstifadə qaydası: az miqdarda təmiz nəm saçlara çəkilir,təbii və ya diffüzorla forma verilir.",
        "code": "Style Perfetto İnsta Curls",
        "madein": "İtaliya",
        "price": 17,
        "size": "150ml.",
        "photo": "maska47.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5e6bfe9f0ae66120eb1b59",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Quru şampun",
        "descr": "Saçları yuyulmadan anında qaydaya salır. Bir az istifadə saçlara təmizlik və həcm verir. Düyü kraxmalı artıq yağı özünə hopdurur və iz salmadan təmizləyir. İstifadə qaydası: 20 sm məsafədən saça tətbiq edilir, 1 dəqiqə saxladıqdan sonra daranır. İstifadədən əvvəl yaxşı çalxalamaq lazımdır.",
        "code": "Style Perfetto Express",
        "madein": "İtaliya",
        "price": 17,
        "size": "150ml.",
        "photo": "maska43.jpg",
        "__v": 0
    },
    {
        "_id": "5f68999219fdff1a747e8bd1",
        "brand": "FreeLimix",
        "category": 75,
        "subcat": "Nazik saçlar üçün yağ",
        "descr": "FreeLimix Delicate and Thin hair Linseed Oil saçlara güç və həcm verərək onları qidalandırır və canlandırır. Saçları xüsusi təbəqə ilə örtərək atmosferin təsirlərindən qoruyur. Xüsusilə qurulamadan öncə istifadə olunması tövsiyyə olunur. İstifadə qaydası: Nəm saçlara 4-5 damcı tətbiq olunur. Ehtiyac olduğu halda qurulamadan sonra 3-4 damcı istifadə oluna bilər.",
        "code": "Delicate & Thin hair Linseed Oil",
        "madein": "Italiya",
        "price": 16.9,
        "size": "100ml",
        "photo": "oil22.jpg",
        "__v": 0
    },
    {
        "_id": "5f689e4d19fdff1a747e8bd2",
        "brand": "FreeLimix",
        "category": 75,
        "subcat": "Saç yağı",
        "descr": "FreeLimix Frizzy və Flyaway Hair Linseed Oil (Uçan saçlar üçün kətan yağı) saçı quruluğa qarşı qoruyur və düzləşdirməni asanlaşdıraraq qıvrım saçları kontrol altına alır. Yaxşı daranmanı təmin etmək xüsusiyyəti sayəsində saçlar yumşaq, hamar və ipəksayağı olur. Onları xüsusi təbəqə ilə örtərək atmosferin təsirlərindən qoruyur. İstifadə qaydası: nəm saça 4-5 damcı tətbiq edilir. Ehtiyac olarsa, qurulamadan sonra 3-4 damcı istifadə edilir.",
        "code": "Oil for Dry Frizzy & Flyaway Hair",
        "madein": "Italiya",
        "price": 16.9,
        "size": "100ml",
        "photo": "oil23.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a08419fdff1a747e8bd3",
        "brand": "FreeLimix",
        "category": 75,
        "subcat": "Saç yağı",
        "descr": "Uzun saçlar üçün əla vasitə olmaqla, FreeLimix End and Length Rebuilding Linseed Oil onlara parlaqlıq və güc verir. Haçalanmış ucları ağırlaşdırmadan parlaqlıq və yumşaqlıq verərək bərpa edir və onları xüsusi təbəqə ilə örtərək atmosferin təsbrərindən qoruyur. İstifadə qaydası: nəm saça 4-5 damcı tətbiq edilir. Ehtiyac olarsa qurulamadan sonra 3-4 damcı istifadə edilir.",
        "code": "Ends and Length Rebuilding Linseed Oil",
        "madein": "Italiya",
        "price": 18,
        "size": "100ml",
        "photo": "oil24.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a49f19fdff1a747e8bd5",
        "brand": "FreeLimix",
        "category": 75,
        "subcat": "Bədən və saç üçün yağ",
        "descr": "Saç və dəri üçün günəş filtri olan Free Limix Sun Summer biphasic oil (Free Limix Sun Summer iki mərhələli yağ) tərkibindəki  aloe və E vitamini sayəsində dərini və saçı yağlandırmadan yumşaqlıq və parlaqlıq verir. Aloenin nəmləndirici və sakitləşdirici təsiri, radiasiyadan qoruyucu günəş filtri və vitamin E-nin antioksidant gücü nəmliyini itirmiş quru saç və dəri üçün optimal məhsuldur. Günəş filtrinin olması duza qarşı təsir etmə vəzifəsini yerinə yetirir. İstifadə qaydası: məhsul günəş təsirinə məruz qalmadan və ya günəş vannası qəbul etmədən öncə istifadə olunur.",
        "code": "Bi-Sun Biphasic",
        "madein": "Italiya",
        "price": 25.7,
        "size": "250ml",
        "photo": "shower2.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          style={1}
          prodtype={this.state.products[a].subcat}
          descr={this.state.products[a].code + " " + this.state.products[a].size}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Stilinq vasitələri'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
