import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Paints extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f5e74da9f0ae66120eb1b5a",
        "brand": "Kaaral",
        "category": 74,
        "subcat": "Boya",
        "descr": "Ipək proteini və keratinlə zənginləştirilmiş piqment saçlara dəbdəbəli rənglər verir.",
        "code": "Color Splash 11",
        "madein": "İtaliya",
        "price": 20.5,
        "size": "200ml.",
        "photo": "maska38.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e74ed9f0ae66120eb1b5b",
        "brand": "Kaaral",
        "category": 74,
        "subcat": "Boya",
        "code": "Color Splash 7",
        "descr": "Ipək proteini və keratinlə zənginləştirilmiş piqment saçlara dəbdəbəli rənglər verir.",
        "madein": "İtaliya",
        "price": 32,
        "size": "200ml.",
        "photo": "maska39.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id}
          id={this.state.products[a]._id} productimg={prodLogo}
          loaded={true} pname={this.state.products[a].brand}
          style={2}
          prodtype={this.state.products[a].subcat}
          descr={this.state.products[a].code + " " + this.state.products[a].size}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Boyalar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
