import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Product from './Product.js'
import Mainpage from './Mainpage.js'
import Scissors from './Scissors.js'
import PaintTrollers from './PaintTrollers.js'
import Brushes from './Brushes.js'
import Combs from './Combs.js'
import ManicPedicTools from './ManicPedicTools.js'
import HairWashingUnit from './HairWashingUnit.js'
import HairWashingTur from './HairWashingTur.js'
import BarberChairs from './BarberChairs.js'
import WomanChairs from './WomanChairs.js'
import Paints from './Paints.js'
import Stools from './Stools.js'
import Sterilizator from './Sterilizator.js'
import Vaporisator from './Vaporisator.js'
import Hairdryers from './Hairdryers.js'
import Stationaries from './Stationaries.js'
import Clippers from './Clippers.js'
import Processors from './Processors.js'
import Others from './Others.js'
import Parafins from './Parafins.js'
import Balsams from './Balsams.js'
import Lamps from './Lamps.js'
import Pedic from './Pedic.js'
import Depilation from './Depilation.js'
import CurDryers from './CurDryers.js'
import MassageBeds from './MassageBeds.js'
import CosmeticBeds from './CosmeticBeds.js'
import Shampoos from './Shampoos.js'
import CosmeticTroller from './CosmeticTroller.js'
import StylingS from './StylingS.js'
import Men from './Men.js'
import Masks from './Masks.js'
import ManicTables from './ManicTables.js'
import WeomanF from './WeomanF.js'
import HairWashOther from './HairWashOther.js'
import ManF from './ManF.js'
import Nail from './Nail.js'
import MassageOil from './MassageOil.js'
import Apron from './Apron.js'
import Mirror from './Mirror.js'
import history from './history.js';

export default class Routes extends Component {
  render() {
      return (
          <Router history={history}>
              <Switch>
                  <Route path="/" exact component={Mainpage} />
                  <Route path="/Scissors" component={Scissors} />
                  <Route path="/PaintTrollers" component={PaintTrollers} />
                  <Route path="/Brushes" exact component={Brushes} />
                  <Route path="/ManicPedicTools" component={ManicPedicTools} />
                  <Route path="/ManF" component={ManF} />
                  <Route path="/HairWashOther" component={HairWashOther} />
                  <Route path="/HairWashingUnit" component={HairWashingUnit} />
                  <Route path="/HairWashingTur" component={HairWashingTur} />
                  <Route path="/BarberChairs" exact component={BarberChairs} />
                  <Route path="/WomanChairs" component={WomanChairs} />
                  <Route path="/Stools" component={Stools} />
                  <Route path="/Combs" component={Combs} />
                  <Route path="/Sterilizator" exact component={Sterilizator} />
                  <Route path="/Vaporisator" component={Vaporisator} />
                  <Route path="/Hairdryers" component={Hairdryers} />
                  <Route path="/Stationaries" exact component={Stationaries} />
                  <Route path="/Stationaries" exact component={Stationaries} />
                  <Route path="/Processors" component={Processors} />
                  <Route path="/Apron" component={Apron} />
                  <Route path="/Parafins" component={Parafins} />
                  <Route path="/Nail" component={Nail} />
                  <Route path="/Others" component={Others} />
                  <Route path="/Lamps" component={Lamps} />
                  <Route path="/CurDryers" component={CurDryers} />
                  <Route path="/MassageBeds" component={MassageBeds} />
                  <Route path="/CosmeticBeds" component={CosmeticBeds} />
                  <Route path="/CosmeticTroller" component={CosmeticTroller} />
                  <Route path="/Mirror" component={Mirror} />
                  <Route path="/Pedic" component={Pedic} />
                  <Route path="/Shampoos" component={Shampoos} />
                  <Route path="/MassageOil" component={MassageOil} />
                  <Route path="/Masks" component={Masks} />
                  <Route path="/StylingS" component={StylingS} />
                  <Route path="/Clippers" component={Clippers} />
                  <Route path="/Paints" component={Paints} />
                  <Route path="/Balsams" component={Balsams} />
                  <Route path="/Depilation" component={Depilation} />
                  <Route path="/ManicTables" component={ManicTables} />
                  <Route path="/WeomanF" component={WeomanF} />
                  <Route path="/Men" component={Men} />
                  <Route path="/Product/:_id" component={Product} />

              </Switch>
          </Router>
      )
  }
}
