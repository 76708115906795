import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import FootImg from './images/aksasince.png'
import './Footer.css'

export default class Footer extends Component{
  render() {
    return (
      <div className="Footer">
          <div className="upperf">
          </div>


        <div className="lowerf">
          <div className="lowercolumn">
            <h4 className="cath"> Əlaqə: </h4>
            <a className="phoneNumb" href="tel:(+994-50)-245-85-39">Telefon:(050)245-85-39</a>
            <br></br>
            <a className="phoneNumb" href="tel:(+994-12)-464-30-17">Telefon:(012)464-30-17</a>
            <br></br>
            <a className="phoneNumb" href="mailto: info@aksa.az">Email: info@aksa.az</a>
            <br></br>
            <a className="phoneNumb" href="https://goo.gl/maps/XPWCQZKDLUAkNpbi7">Adres: 54D Sabit Orucov küç., Xətai, Bakı</a>
            <div className="phoneNumb">
              <div style={{height: '1rem'}}>
              </div>
              <a className='Instagram' href='https://www.instagram.com/aksa.cosmetics/'> <FontAwesomeIcon icon={faInstagram} size="2x"/> </a>
              <a className='Instagram' href='https://www.facebook.com/aksa.cosmetics/'> <FontAwesomeIcon icon={faFacebook} size="2x"/> </a>
              <a className='Instagram' href='https://api.whatsapp.com/send?phone=994502458539'> <FontAwesomeIcon icon={faWhatsapp} size="2x"/> </a>
            </div>
        </div>

          <div className="lowermiddlecol">
            <h4 className="cath"> Kateqoriyalar: </h4>
            <a className="cats"> Qadın Mebəlləri </a>
            <br></br>
            <a className="cats"> Kişi Mebelləri </a>
            <br></br>
            <a href='/HairWashingUnit' className="cats"> Saç Yuma Dəstləri (İtaliya) </a>
            <br></br>
            <a href='/HairWashingTur' className="cats"> Saç Yuma Dəstləri (Türkiyə) </a>
            <br></br>
            <a href='/WomanChairs' className="cats"> Qadın Qoltuqları </a>
            <br></br>
            <a href='/BarberChairs' className="cats"> Bərbər Qoltuqları </a>
            <br></br>
            <a href='/Stools' className="cats"> Stullar </a>
            <br></br>
            <a href='/CosmeticBeds' className="cats"> Kosmetoloji Yataqlar </a>
            <br></br>
            <a href='/MassageBeds' className="cats"> Masaj Yataqları </a>
            <br></br>
            <a href='/ManicTables' className="cats"> Manikur </a>
            <br></br>
          </div>
          <div className="lowermiddlecol">
            <a href='/Pedic' className="cats"> Pedikür </a>
            <br></br>
            <a href='/Stationaries' className="cats"> Stasionar Fenlər </a>
            <br></br>
            <a href='/Hairdryers' className="cats"> Hava Fenləri </a>
            <br></br>
            <a href='/Vaporisator' className="cats"> Vaporizatorlar </a>
            <br></br>
            <a href='/Processors' className="cats"> Klimazonlar </a>
            <br></br>
            <a href='/Sterilizator' className="cats"> Sterilizatorlar </a>
            <br></br>
            <a href='/PaintTrollers' className="cats"> Bərbər Arabaları </a>
            <br></br>
            <a className="cats"> Kosmetoloji Arabalar </a>
            <br></br>
            <a href='/Scissors' className="cats"> Qayçılar </a>
            <br></br>
            <a href='/Combs' className="cats"> Daraqlar </a>
            <br></br>
            <a href='/Brushes' className="cats"> Fırçalar </a>
          </div>
          <div className="lowercolumn">
            <img className="Footie" src={FootImg}  alt="Aksa Cosmetics"/>
          </div>
        </div>
        <div className="rights">
          <h4 className="rightInfo">BADR MMC | Bütün hüquqları qorunur © 2020</h4>
        </div>
      </div>

    );
  }
}
