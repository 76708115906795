import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Parafins extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f57ecdeaf7ec209d0a79497",
        "brand": "Xanitalia",
        "category": 59,
        "subcat": "Mum Aparatı",
        "madein": "Italiya",
        "price": 40,
        "code": "1 ədədli Kartric",
        "photo": "aparat01.jpg",
        "power": "25W",
        "__v": 0
    },
    {
        "_id": "5f57ed04af7ec209d0a79498",
        "brand": "Xanitalia",
        "category": 59,
        "subcat": "Mum Aparatı",
        "madein": "Italiya",
        "price": 110,
        "code": "2 ədədli Kartric",
        "photo": "aparat02.jpg",
        "power": "50W",
        "__v": 0
    },
    {
        "_id": "5f57ed71af7ec209d0a79499",
        "brand": "Xanitalia",
        "category": 59,
        "subcat": "Mum Aparatı",
        "madein": "Italiya",
        "price": 130,
        "code": "3 ədədli Kartric ",
        "photo": "aparat03.jpg",
        "power": "75W",
        "__v": 0
    },
    {
        "_id": "5f57edb1af7ec209d0a7949a",
        "brand": "Xanitalia",
        "category": 59,
        "subcat": "Mum Aparatı",
        "code": "Elegance 800",
        "madein": "Italiya",
        "price": 130,
        "photo": "aparat04.jpg",
        "power": "100W",
        "__v": 0
    },
    {
        "_id": "5f57ee28af7ec209d0a7949b",
        "brand": "Rica",
        "category": 59,
        "subcat": "Mum Aparatı",
        "code": "1 ədədli Kartric ",
        "madein": "Italiya",
        "price": 63,
        "photo": "aparat05.jpg",
        "power": "35W",
        "__v": 0
    },
    {
        "_id": "5f57ee7baf7ec209d0a7949c",
        "brand": "Prowax",
        "category": 59,
        "subcat": "Mum Aparatı",
        "code": " ",
        "madein": "Çin",
        "price": 44,
        "photo": "aparat06.jpg",
        "power": "70W",
        "__v": 0
    },
    {
        "_id": "5f57eea1af7ec209d0a7949d",
        "brand": "Angel Beauty",
        "category": 59,
        "subcat": " ",
        "code": "Mum Aparatı",
        "madein": "Çin",
        "price": 68.4,
        "photo": "aparat07.jpg",
        "power": "105W",
        "__v": 0
    },
    {
        "_id": "5f57ef06af7ec209d0a7949e",
        "brand": "Parafin Aparatı",
        "category": 59,
        "subcat": "Parafin Aparatı ",
        "code": " ",
        "madein": "Çin",
        "price": 60,
        "photo": "aparat08.jpg",
        "power": "200W",
        "__v": 0
    },
    {
        "_id": "5f57ef5faf7ec209d0a7949f",
        "brand": "Rica",
        "category": 59,
        "subcat": "Mum Aparatı",
        "code": " ",
        "madein": "İtaliya",
        "price": 378,
        "photo": "aparat09.jpg",
        "power": "130W",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Parafin və Mum Aparatları'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
