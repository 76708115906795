import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row,  Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class BarberChairs extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f4964e4de4d2a0ed85461d0",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "descr": "Italiya istehsalı saç kəsim qayçısı",
        "code": "266",
        "madein": "Italiya",
        "price": 102,
        "color": "Qızılı(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci01.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4969b8b0fb9e0ae03ade27",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı filirovka",
        "code": "2259",
        "madein": "Italiya",
        "price": 86.9,
        "color": "Gümüşü(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci02.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4a07a0202e45028c59f5ef",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "244",
        "madein": "Italiya",
        "price": 71.8,
        "color": "Gümüşü(Mat)",
        "size": "5.0\"",
        "photo": "qayci03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a07b1202e45028c59f5f0",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "244",
        "madein": "Italiya",
        "price": 75,
        "color": "Gümüşü(Mat)",
        "size": "5.5\"",
        "photo": "qayci03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a07ce202e45028c59f5f1",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "244",
        "madein": "Italiya",
        "price": 78.4,
        "color": "Gümüşü(Mat)",
        "size": "6.0\"",
        "photo": "qayci03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a08fd202e45028c59f5f2",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı filirovka",
        "code": "221",
        "madein": "Italiya",
        "price": 218.7,
        "color": "Gümüşü(Parlaq)",
        "size": "6.0\"",
        "photo": "qayci04.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a09f3202e45028c59f5f3",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "217",
        "madein": "Italiya",
        "price": 450,
        "color": "Qara(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci05.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a0aef202e45028c59f5f4",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "255",
        "madein": "Italiya",
        "price": 109.4,
        "color": "Gümüşü(Parlaq)",
        "size": "5.0\"",
        "photo": "qayci06.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4a0e97202e45028c59f5f5",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2438",
        "madein": "Italiya",
        "price": 87.5,
        "color": "Qırmızı/Ağ(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci07.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a0ea3202e45028c59f5f6",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "257",
        "madein": "Italiya",
        "price": 100.6,
        "color": "Gümüşü(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci08.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a0f15202e45028c59f5f7",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "260",
        "madein": "Italiya",
        "price": 106.9,
        "color": "Gümüşü(Parlaq)",
        "size": "5.0\"",
        "photo": "qayci09.png",
        "__v": 0
    },
    {
        "_id": "5f4a0f4f202e45028c59f5f8",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı filirovka",
        "code": "272",
        "madein": "Italiya",
        "price": 45.9,
        "color": "Gümüşü(Mat)",
        "size": "6.5\"",
        "photo": "qayci10.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a0fc3202e45028c59f5f9",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı filirovka",
        "code": "273",
        "madein": "Italiya",
        "price": 72.6,
        "color": "Gümüşü(Mat)",
        "size": "5.5\"",
        "photo": "qayci11.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1009202e45028c59f5fa",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "278",
        "madein": "Italiya",
        "price": 43,
        "color": "Gümüşü(Mat)",
        "size": "5.5\"",
        "photo": "qayci12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a101e202e45028c59f5fb",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "278",
        "madein": "Italiya",
        "price": 44.5,
        "color": "Gümüşü(Mat)",
        "size": "6.0\"",
        "photo": "qayci12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1029202e45028c59f5fc",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "278",
        "madein": "Italiya",
        "price": 45.5,
        "color": "Gümüşü(Mat)",
        "size": "6.5\"",
        "photo": "qayci12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1084202e45028c59f5fd",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2115",
        "madein": "Italiya",
        "price": 19.8,
        "color": "Tutacaq Hissəsi Göy Plastik / Metal Hissəsi Gümüşü(Mat)",
        "size": "5.5\"",
        "photo": "qayci13.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1169202e45028c59f5fe",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2115",
        "madein": "Italiya",
        "price": 19.8,
        "color": "Tutacaq Hissəsi Göy Plastik / Metal Hissəsi Gümüşü(Mat)",
        "size": "6.0\"",
        "photo": "qayci13.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a119d202e45028c59f5ff",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2127",
        "madein": "Italiya",
        "price": 31.8,
        "color": "Gümüşü(Mat)",
        "size": "4.5\"",
        "photo": "qayci14.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a11ce202e45028c59f600",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2127",
        "madein": "Italiya",
        "price": 33.4,
        "color": "Gümüşü(Mat)",
        "size": "5.0\"",
        "photo": "qayci14.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a11dc202e45028c59f601",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2127",
        "madein": "Italiya",
        "price": 35.7,
        "color": "Gümüşü(Mat)",
        "size": "6.5\"",
        "photo": "qayci14.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1227202e45028c59f602",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "282",
        "madein": "Italiya",
        "price": 161.4,
        "color": "Gümüşü(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci15.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a125a202e45028c59f603",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2260",
        "madein": "Italiya",
        "price": 64.8,
        "color": "Gümüşü(Parlaq)",
        "size": "5.0\"",
        "photo": "qayci16.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1267202e45028c59f604",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2260",
        "madein": "Italiya",
        "price": 69.4,
        "color": "Gümüşü(Parlaq)",
        "size": "6.0\"",
        "photo": "qayci16.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          price={this.state.products[a].price}
          loaded={true} pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Qayçılar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
