import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Stationaries extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f514734c1baa704681d927c",
        "brand": "Ceriotti",
        "category": 51,
        "subcat": "Stasionar Fen",
        "code": "Apollo 3000",
        "madein": "Italiya",
        "price": 490,
        "photo": "fen08.jpg",
        "power": "220-240V / 1000W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f514799c1baa704681d927d",
        "brand": "Ceriotti",
        "category": 51,
        "subcat": "Stasionar Fen",
        "code": "Gong Ionic 1",
        "madein": "Italiya",
        "price": 760,
        "photo": "fen09.jpg",
        "power": "220-240V / 1400W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f5147b9c1baa704681d927e",
        "brand": "Ceriotti",
        "category": 51,
        "subcat": "Stasionar Fen",
        "code": "Egg",
        "madein": "Italiya",
        "price": 670,
        "photo": "fen10.jpg",
        "power": "220-240V / 1000W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f51480fc1baa704681d927f",
        "brand": "Ceriotti",
        "category": 51,
        "subcat": "Stasionar Fen",
        "code": "Glob 3000",
        "madein": "Italiya",
        "price": 610,
        "photo": "fen11.jpg",
        "power": "220-240V / 1000W / 50Hz",
        "__v": 0
    }
],
      loaded: true
    };
  }

  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
           key={this.state.products[a]._id}
           id={this.state.products[a]._id}
           productimg={prodLogo} loaded={true}
           pname={this.state.products[a].brand}
           descr={this.state.products[a].subcat + " " + this.state.products[a].code}
           price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Stasionar Fenlər'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
