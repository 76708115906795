import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Sterilizator extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f513f6bc1baa704681d926c",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Ultrabənövşəyi Sterilizator",
        "code": "New Germix",
        "madein": "Italiya",
        "price": 257,
        "photo": "sterilizator01.jpg",
        "power": "220-240V / 15W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f51400bc1baa704681d926d",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Ultrabənövşəyi Sterilizator",
        "code": "GX-4",
        "madein": "Italiya",
        "price": 270,
        "photo": "sterilizator02.jpg",
        "power": "220-240V / 15W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51402bc1baa704681d926e",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Ultrabənövşəyi Sterilizator",
        "code": "Stelth",
        "madein": "Italiya",
        "price": 350,
        "photo": "sterilizator03.jpg",
        "power": "220-240V / W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51407dc1baa704681d926f",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Sterilizator",
        "code": "GX-7",
        "madein": "Italiya",
        "price": 230,
        "photo": "sterilizator04.jpg",
        "power": "220-240V / 75W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f514091c1baa704681d9270",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Sterilizator",
        "code": "Microstop",
        "madein": "Italiya",
        "price": 250,
        "photo": "sterilizator05.jpg",
        "power": "220-240V / 75W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f5140bac1baa704681d9271",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Sterilizator",
        "code": "Sanity Security",
        "madein": "Italiya",
        "price": 650,
        "photo": "sterilizator06.jpg",
        "power": "220-240V / 400W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f5140d4c1baa704681d9272",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Ultrabənövşəyi Sterilizator",
        "code": "New Germix 2",
        "madein": "Italiya",
        "price": 330,
        "photo": "sterilizator07.jpg",
        "power": "220-240V / 15W / 50Hz",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id} productimg={prodLogo}
          loaded={true}
          style={2}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Sterilizatorlar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
