import React, { Component } from "react";
import './Card.css'

export default class Card extends Component{
  render() {
    if(this.props.style===1){

      return (
        <div className='CardBig'>
          <a className='ProductCard' href={'/Product/'+this.props.id}>
          {this.props.loaded &&
            <img className="cardImage" src={this.props.productimg} alt='Product'/>
          }

          {this.props.descr &&
            <p className="descr">{this.props.descr}</p>
          }
          {this.props.prodtype &&
            <p className="prodtype">{this.props.prodtype}</p>
          }
          {this.props.price &&
            <p className="price">{this.props.price} ₼</p>
          }

          </a>
        </div>
      );
    }
    else if(this.props.style===2){

      return (
        <div className='CardMedium'>
          <a className='ProductCard' href={'/Product/'+this.props.id}>
          {this.props.loaded &&
            <img className="cardImage" src={this.props.productimg} alt='Product'/>
          }

          {this.props.descr &&
            <p className="descr">{this.props.descr}</p>
          }
          {this.props.prodtype &&
            <p className="prodtype">{this.props.prodtype}</p>
          }
          {this.props.price &&
            <p className="price">{this.props.price} ₼</p>
          }

          </a>
        </div>
      );
    }
    else{
      return (
        <div className='Card'>
          <a className='ProductCard' href={'/Product/'+this.props.id}>
          {this.props.loaded &&
            <img className="cardImage" src={this.props.productimg} alt='Product'/>
          }

          {this.props.descr &&
            <p className="descr">{this.props.descr}</p>
          }
          {this.props.prodtype &&
            <p className="prodtype">{this.props.prodtype}</p>
          }
          {this.props.price &&
            <p className="price">{this.props.price} ₼</p>
          }

          </a>
        </div>
      );
    }
  }
}
