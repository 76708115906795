import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class VAporizator extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f514280c1baa704681d9273",
        "brand": "Ceriotti",
        "category": 53,
        "subcat": "Vaporizator",
        "code": "Geyser",
        "madein": "Italiya",
        "photo": "vaporizator01.jpg",
        "power": "220-240V / 700W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51437ec1baa704681d9274",
        "brand": "Ceriotti",
        "category": 53,
        "subcat": "Vaporizator",
        "code": "X-Steam 02 Digital",
        "madein": "Italiya",
        "photo": "vaporizator02.jpg",
        "power": "220-240V / 1000W / 50-60Hz",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        console.log(prodLogo);
        console.log('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id} id={this.state.products[a]._id} productimg={prodLogo} loaded={true} pname={this.state.products[a].brand} descr={this.state.products[a].subcat + " " + this.state.products[a].code} price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Vaporizatorlar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
