import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class CurDryers extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f58a504b4d0b92db854f946",
        "brand": "UKI",
        "category": 54,
        "subcat": "Ütü Fen",
        "code": "Online Plus",
        "madein": "Italiya",
        "price": 145,
        "photo": "bfen01.jpg",
        "power": "50W",
        "heat": "210",
        "__v": 0
    },
    {
        "_id": "5f58a59fb4d0b92db854f947",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Ütü Fen",
        "code": "BAB2654EPE",
        "madein": "Fransa",
        "price": 135,
        "photo": "bfen02.jpg",
        "power": "33W",
        "heat": "210",
        "__v": 0
    },
    {
        "_id": "5f58a5d1b4d0b92db854f948",
        "brand": "Jaguar",
        "category": 54,
        "subcat": "Ütü Fen",
        "code": "Silk Power",
        "madein": "ABŞ",
        "price": 90,
        "photo": "bfen03.jpg",
        "power": "60W",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a61bb4d0b92db854f949",
        "brand": "Sthauer",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "Doll Brush '38 ",
        "madein": "İtaliya",
        "price": 65,
        "photo": "bfen04.jpg",
        "power": "50-55w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a647b4d0b92db854f94a",
        "brand": "Ceriotti",
        "category": 54,
        "subcat": "Ütü Fen",
        "code": "Keram",
        "madein": "İtaliya",
        "price": 90,
        "photo": "bfen05.jpg",
        "power": "60w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a678b4d0b92db854f94b",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "BAB2369TTE",
        "madein": "Fransa",
        "price": 210,
        "photo": "bfen06.jpg",
        "power": "79w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a6a4b4d0b92db854f94c",
        "brand": "UKİ",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "Conic '19 ",
        "madein": "Italiya",
        "price": 99,
        "photo": "bfen07.jpg",
        "power": "40w",
        "heat": "210",
        "__v": 0
    },
    {
        "_id": "5f58a6c7b4d0b92db854f94d",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "BAB2335TTE",
        "madein": "Fransa",
        "price": 150,
        "photo": "bfen08.jpg",
        "power": "33w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a6e4b4d0b92db854f94e",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "271 CE",
        "madein": "Fransa",
        "price": 65,
        "photo": "bfen09.jpg",
        "power": "18w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a71ab4d0b92db854f94f",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "Mira Curl",
        "madein": "Fransa",
        "price": 345,
        "photo": "bfen10.jpg",
        "power": "44w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a779b4d0b92db854f950",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Termal Biqudi",
        "code": "BAB3021E",
        "madein": "Fransa",
        "price": 95,
        "photo": "bfen11.jpg",
        "power": "400W",
        "__v": 0
    },
    {
        "_id": "5f58a78ab4d0b92db854f951",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Termal Biqudi",
        "code": "3060E",
        "madein": "Fransa",
        "price": 150,
        "photo": "bfen12.jpg",
        "power": "400W",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          style={2}
          productimg={prodLogo} loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}
          heat={this.state.products[a].heat}
          />);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Ütü və Saç Burma Alətləri'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
