import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Depilation extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f60f22929d32f55081c0bca",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Titanium tərkibli mum",
        "madein": "İtaliya",
        "price": 9,
        "size": "400ml",
        "photo": "mum01.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f24829d32f55081c0bcb",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Bal tərkibli mum",
        "madein": "İtaliya",
        "price": 9,
        "size": "400ml",
        "photo": "mum02.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f25829d32f55081c0bcc",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Xlorofil tərkibli mum",
        "madein": "İtaliya",
        "price": 9,
        "size": "400ml",
        "photo": "mum03.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f27329d32f55081c0bcd",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Azulen tərkibli mum",
        "madein": "İtaliya",
        "price": 9,
        "size": "400ml",
        "photo": "mum04.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f29e29d32f55081c0bce",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün şuqarinq mum",
        "code": "Sugaring Spatula mum",
        "madein": "İtaliya",
        "price": 8.5,
        "size": "500ml",
        "photo": "mum05.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f2b129d32f55081c0bcf",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün şuqarinq mum",
        "code": "Sugaring Paste mum",
        "madein": "İtaliya",
        "price": 8.5,
        "size": "500ml",
        "photo": "mum06.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f2de29d32f55081c0bd0",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Titanium tərkibli mum",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "800ml",
        "photo": "mum07.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f2fd29d32f55081c0bd1",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Bal tərkibli mum",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "800ml",
        "photo": "mum08.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f30d29d32f55081c0bd2",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Xlorofil tərkibli mum",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "800ml",
        "photo": "mum09.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f32629d32f55081c0bd3",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Azulen tərkibli mum",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "800ml",
        "photo": "mum10.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f34629d32f55081c0bd4",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün şuqarinq mum",
        "code": "Sugaring Spatula mum",
        "madein": "İtaliya",
        "price": 17,
        "size": "1000ml",
        "photo": "mum11.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f37229d32f55081c0bd5",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün şuqarinq mum",
        "code": "Sugaring Paste mum",
        "madein": "İtaliya",
        "price": 17,
        "size": "1000ml",
        "photo": "mum12.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f3ac29d32f55081c0bd6",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Titanium tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "1000ml",
        "photo": "mum13.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f3ce29d32f55081c0bd7",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Bal tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "100ml",
        "photo": "mum14.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f40229d32f55081c0bd8",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Talk tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "100ml",
        "photo": "mum15.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f41729d32f55081c0bd9",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Gold tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "100ml",
        "photo": "mum16.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f42c29d32f55081c0bda",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Xlorofil tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "100ml",
        "photo": "mum17.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f44e29d32f55081c0bdb",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Titanium tərkibli Disk mum",
        "madein": "İtaliya",
        "price": 11,
        "size": "400qr",
        "photo": "mum18.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f45d29d32f55081c0bdc",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Bal tərkibli Disk mum",
        "madein": "İtaliya",
        "price": 11,
        "size": "400qr",
        "photo": "mum19.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f47e29d32f55081c0bdd",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Titanium tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum20.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f49129d32f55081c0bde",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Bal tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum21.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f4a529d32f55081c0bdf",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Azulen tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum22.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f4ef29d32f55081c0be0",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Xlorofil tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum23.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f50829d32f55081c0be1",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Şokolad tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum24.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f52029d32f55081c0be2",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Bal tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum25.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f53629d32f55081c0be3",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Xlorofil tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum26.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f54729d32f55081c0be4",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Azulen tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum27.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f55729d32f55081c0be5",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Titanium tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum28.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f57029d32f55081c0be6",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Şokolad tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum29.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f5ac29d32f55081c0be7",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya sonrası yağ",
        "code": "Arqan tərkibli depilyasiya sonrası yağ",
        "madein": "İtaliya",
        "price": 13,
        "size": "500ml",
        "photo": "mum30.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f5ca29d32f55081c0be8",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya sonrası yağ",
        "code": "Evkalipt tərkibli depilyasiya sonrası yağ",
        "madein": "İtaliya",
        "price": 13,
        "size": "500ml",
        "photo": "mum31.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f5cf29d32f55081c0be9",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya sonrası yağ",
        "code": "Çobanyastığı tərkibli depilyasiya sonrası yağ",
        "madein": "İtaliya",
        "price": 13,
        "size": "500ml",
        "photo": "mum32.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f5d729d32f55081c0bea",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya sonrası yağ",
        "code": "Aloe Vera tərkibli depilyasiya sonrası yağ",
        "madein": "İtaliya",
        "price": 13,
        "size": "500ml",
        "photo": "mum33.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f78729d32f55081c0beb",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Parafin",
        "code": "Neutral tərkibli paraffin",
        "madein": "İtaliya",
        "price": 10,
        "size": "500ml",
        "photo": "parafin01.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f7b629d32f55081c0bec",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Parafin",
        "code": "Şaftalı tərkibli paraffin",
        "madein": "İtaliya",
        "price": 10,
        "size": "500ml",
        "photo": "parafin02.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id}
          id={this.state.products[a]._id} productimg={prodLogo}
          loaded={true} pname={this.state.products[a].brand}
          style={1} prodtype={this.state.products[a].subcat}
          descr={this.state.products[a].code + " " + this.state.products[a].size}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Depilyasiya Vasitələri'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
