import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class ManicTables extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f6511740f313379283cf18c",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1001L",
        "madein": "Azərbaycan",
        "price": 140,
        "size": "110*52*73sm",
        "photo": "manic01.jpg",
        "material": "Laminant",
        "__v": 0
    },
    {
        "_id": "5f6511ed0f313379283cf18d",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1002M",
        "madein": "Azərbaycan",
        "price": 570,
        "size": "135*50*75sm",
        "photo": "manic02.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6512510f313379283cf18e",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1003LM",
        "madein": "Azərbaycan",
        "price": 250,
        "size": "120*55*75sm",
        "photo": "manic03.jpg",
        "material": "Laminat / MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6512980f313379283cf18f",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1004M",
        "madein": "Azərbaycan",
        "price": 600,
        "size": "135*50*75sm",
        "photo": "manic04.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6512d80f313379283cf190",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1005M",
        "madein": "Azərbaycan",
        "price": 450,
        "size": "120*50*75sm",
        "photo": "manic05.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f65139d0f313379283cf192",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Dırnaq Boyası Rəfi",
        "code": "1007RM",
        "madein": "Azərbaycan",
        "price": 70,
        "size": "50*10*80sm",
        "photo": "manic07.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6513cb0f313379283cf193",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Dırnaq Boyası Rəfi",
        "code": "1008RM",
        "madein": "Azərbaycan",
        "price": 90,
        "size": "50*10*80sm",
        "photo": "manic08.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6513e80f313379283cf194",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Dırnaq Boyası Rəfi",
        "code": "1009RM",
        "madein": "Azərbaycan",
        "price": 90,
        "size": "50*10*80sm",
        "photo": "manic09.jpg",
        "material": "MDF boya",
        "__v": 0
    }
],
      loaded: true
    };
  }

  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id}
          id={this.state.products[a]._id} productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          style={1} prodtype={this.state.products[a].subcat}
          descr={this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Manikür'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
