import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Masks extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f5debd69f0ae66120eb1b42",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Saç üçün skrab",
        "descr": "Badam və fındıq  mikroqranulları baş dərisinin üst qatını yumşaq bir şəkildə yeniləyir. Çirkləri, həddindən artıq yağı, kəpəyi və saç şəkilləndirmə məhsullarının baş dərisinə hopmuş qalıqlarını təmizləyir. Saçları K-05 müalicəvi bölümü üçün hazırlayır. İstifadə qaydası: Şampunla yumazdan əvvəl skrabı nəmli saça çəkin,  3-5 dəqiqə masaj edin və yuyun. ",
        "code": "K-05 Exfoliating Scrub",
        "madein": "İtaliya",
        "price": 33,
        "size": "100ml",
        "photo": "maska06.jpg",
        "__v": 0
    },
    {
        "_id": "5f5df26c9f0ae66120eb1b43",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Kəpəyə qarşı losyon",
        "descr": "Çay ağacı yağı və Pirokton Olamin maddəsi sayəsində losyon saç dərisini normallaşdırır, qidalandırır və qabıqlanmanın qarşısını alır. Yağlı və quru kəpəklərə qarşı effektivdir. İstifadə qaydası: saçınızı Anti Dandruff şampunu ilə yuduqdan sonra losyonu saçınıza çəkin, 3-5 dəqiqə masaj edin və darayın (Yuyulmur). Losyonun həftədə 2 dəfə, 6-12 həftə muddətində istifadə olunması tövsiyyə olunur.",
        "code": "K-05 Dandruff Removing Lotion",
        "madein": "İtaliya",
        "price": 35,
        "size": "10ml.x12ədəd",
        "photo": "maska12.jpg",
        "__v": 0
    },
    {
        "_id": "5f5df51a9f0ae66120eb1b44",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Boyalı və quru saçlar üçün maska",
        "descr": "Monoi De Taiti, Şi yağı və Keratin tərkibli maska boyalı, zədəli və quru saçları dərindən qidalandırır. Zəif və zədəli saç tellərini bərpa edir və möhkəmləndirir, boyanın axmasının qarşısını alır və parlaqlıq verir. İstifadə qaydası: nəm saçlara çəkin, masaj edin, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Maraes Nourishing Mask",
        "madein": "İtaliya",
        "price": 19,
        "size": "200ml.",
        "photo": "maska13.jpg",
        "__v": 0
    },
    {
        "_id": "5f5df5669f0ae66120eb1b45",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Boyalı və quru saçlar üçün maska",
        "descr": "Monoi De Taiti, Şi yağı və Keratin tərkibli maska boyalı, zədəli və quru saçları dərindən qidalandırır. Zəif və zədəli saç tellərini bərpa edir və möhkəmləndirir, boyanın axmasının qarşısını alır və parlaqlıq verir. İstifadə qaydası: nəm saçlara çəkin, masaj edin, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Maraes Nourishing Mask",
        "madein": "İtaliya",
        "price": 33,
        "size": "1000ml.",
        "photo": "maska14.jpg",
        "__v": 0
    },
    {
        "_id": "5f5df8d39f0ae66120eb1b46",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Zədəli və quru saçlar üçün yağ",
        "descr": "Zədəli və zəif saçlar ücün xüsusi qidalandırıcı müalicəvi yağ tərkibində olan, Monoi De Taiti, Kətan toxumu yağı, E vitamini ilə zənginləşdirilimişdir. Saç tellərinin qırılmasının, haçalanmasının qarşını alır, saç rəngini qoruyur,yumşaqlıq, parlaqlıq verir. İstifadə qaydası: nəm saça çəkin və qurudun.",
        "code": "Maraes Nourishing Olium",
        "madein": "İtaliya",
        "price": 24,
        "size": "30ml.",
        "photo": "maska17.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dfcdb9f0ae66120eb1b47",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Qıvrım saçlar üçün bərpaedici maska",
        "descr": "Qıvrım və dalğalı saçlar ücün nəmləndirici və canlandırıcı müalicəvi maska tərkibindəki, Monoi De Taiti, Marula yağı ilə zənginləşdirilimişdir. Saç kutikullarını hamarlayır və saçın natural elastikliyini bərpa edir. İstifadə qaydası: saçın uzunluğuna uyğun olaraq əvvəlcədən yuyulmuş və dəsmalla qurulanmış saça çəkin və masaj edin. Darayın, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Maraes Curl Revitalizing Mask",
        "madein": "İtaliya",
        "price": 19,
        "size": "200ml.",
        "photo": "maska20.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dfd019f0ae66120eb1b48",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Qıvrım saçlar üçün bərpaedici maska",
        "descr": "Qıvrım və dalğalı saçlar ücün nəmləndirici və canlandırıcı müalicəvi maska tərkibindəki, Monoi De Taiti, Marula yağı ilə zənginləşdirilimişdir. Saç kutikullarını hamarlayır və saçın natural elastikliyini bərpa edir. İstifadə qaydası: Saçın uzunluğuna uyğun olaraq,əvvəlcədən yuyulmuş və dəsmalla qurulanmış saça çəkin və masaj edin. Darayın, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Maraes Curl Revitalizing Mask",
        "madein": "İtaliya",
        "price": 33,
        "size": "1000ml.",
        "photo": "maska21.png",
        "__v": 0
    },
    {
        "_id": "5f5e0be79f0ae66120eb1b49",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Rəngli və quru saçlar üçün sprey",
        "descr": "Sprey, Monoi De Taiti və Limantes yağının qidalandırıcı komponentlərini özündə birləşdirir.Saça tətbiq olunan boyaların saç tellərini zədələməsinin qarşısını alır, saçı hava fenlərindən və saç burma alətlərindən qoruyur.İstifadə qaydası: yuyulmuş və dəsmalla qurudulmuş saça çəkin və darayın. (Yuyulmur)",
        "code": "Maraes Color Nourishing Shield",
        "madein": "İtaliya",
        "price": 15,
        "size": "150ml.",
        "photo": "maska22.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f55989f0ae66120eb1b67",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Yeniləyici maska",
        "descr": "Keratin və hialuron turşusu saçın keratin təbəqəsinə dərindən təsir edir və zədələnmiş hissələri bərpa edir.Saça möhkəmlik verir və epiderm təbəqəsindəki məsamələri bağlayır. Maska saçı gücləndirir,havalandıraraq saçı daha möhkəm və parlaq göstərir. İstifadə qaydası: Yaş saça vurun, 5-10 dəqiqə saxlayın və yuyun.",
        "code": "Kerayonic restructuring mask",
        "madein": "İtaliya",
        "price": 16.3,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri24.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f5ba69f0ae66120eb1b69",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Yeniləyici krem",
        "descr": "Dəniz elastinləri ilə zənginləşdirilmiş maska,saç tellərinə dərindən nüfuz edərək,onları qidalandırır. Alfa hidroksid turşusu saçları nəmləndirir və qoruyur. Maska saç boyasının matlaşmasının qarşısını alır və saçları möhkəmləndirir. Istifadə qaydası: maskanı saça çəkin,3-5 dəqiqə saxlayın və yuyun.",
        "code": "Colour Save Mask",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "200ml.",
        "photo": "sacaqulluqvasiteleri26.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f5ee29f0ae66120eb1b6b",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Qıvrımlar üçün tənzimləyici spray maska",
        "descr": "Maska arqan yağı, böyürtkən sirkəsi, keramidlər vasitəsilə saç dərisində olan quruluğu aradan qaldırır və buruqlara həcm, parlaqlıq, yumşaqlıq verir. Arqan yağı saçı nəmləndirərək saç buruqlarına ipək parlaqlığı bəxş edir. İstifadə qaydası: yuyulmuş nəm saçlara vurulur, bir neçə dəqiqə saxladıqdan sonra yuyulur.",
        "code": "Perfect Curls Defining spray mask",
        "madein": "İtaliya",
        "price": 7,
        "size": "200ml.",
        "photo": "sacaqulluqvasiteleri29.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f5f7f9f0ae66120eb1b6c",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Düzləşdirici spray maska",
        "descr": "Kənar elementlərin saça hopmasının qarşısını alır, saçın daxilinə nüfuz edərək zədələnmiş saçları bərpa edir, onlara təbii və güclü görüntü bəxş edir. Şaftalı ekstraktının nəmləndirici effekti saçı kənar təsirlərdən mühafizə edir. Quşburnu ekstraktı saçların asan düzləşdirilməsini təmin edir və saxlonlara uzunmüddətli düzlük bəxş edir. Istifadə qaydası: saçları şampunla yuduqdan sonra maska saça tətbiq edilir. Bir neçə dəqiqə saçda saxlanılır və daha sonra ilıq su ilə yuyulur.",
        "code": "Lixperfection Smoothing spray mask",
        "madein": "İtaliya",
        "price": 10,
        "size": "200ml.",
        "photo": "sacaqulluqvasiteleri30.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f61e19f0ae66120eb1b6f",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Bərpaedici maska",
        "descr": "Maska quru və zədəli saçları amin turşuları ilə təmin edərək,onların elastikliyini və parlaqlığını geri qaytarır. Düyü proteinləri və E vitamini saçlara həcm və quruluş verir. Istifadə qaydası: yuyulmuş nəm saça tətbiq edin, masaj edin, 5 dəqiqə saxlanıldıqdan sonra yuyun.",
        "code": "Reconstruction mask",
        "madein": "İtaliya",
        "price": 15,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri28.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f700d9f0ae66120eb1b72",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Bərpaedici losyon",
        "descr": "Silikon komponentləri zəif saç tellərini yenidən canlandırır, saçlara parlaqlıq və yumşaqlıq verir. Xüsusilə kimyəvi təsirə məruz qalmış zədəli saçlar üçün idealdır. İstifadə qaydası: losyonu saç dərisinə çəkin, dairəvi hərəkətlə masaj edin (yumayın). Həftədə 2-3 dəfə tətbiq olunmalıdır.",
        "code": "Restructuring Treatment",
        "madein": "İtaliya",
        "price": 24,
        "size": "12x10ml.",
        "photo": "ampul01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f701f9f0ae66120eb1b73",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Saç tökülməsinə qarşı ampul",
        "descr": "Losyon saç dərisindəki qan dövranını sürətləndirir və saç follikullarını qidalandırır. Saçlara canlılıq və möhkəmlik verir. Daha yaxşı nəticə üçün FreeLimix Hair Loss Shampoo ilə birlikdə istifadə olunması tövsiyyə olunur. İstifadə qaydası: Losyonu saç dərisinə çəkin, dairəvi masaj edin (yumayın). Həftədə 2-3 dəfə tətbiq olunması tövsiyyə olunur.",
        "code": "Hair Loss Adjuvant Treatment",
        "madein": "İtaliya",
        "price": 32,
        "size": "12x10ml.",
        "photo": "ampul02.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fad5029d32f55081c0ba5",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Qidalandırıcı maska",
        "descr": "Keratin kondisioneri saçları intensiv bərpa edir. Amin turşuları və bambuk ekstraktı ilə zəngindir. Saçları nəmləndirir və onlara intensiv  parlaqlıq verir. İstifadə qaydası: yuyulmuş nəm  saçlara çəkilir, 3-5 dəqiqədən sonra  yuyulur.",
        "code": "Nourishing mask",
        "madein": "İtaliya",
        "price": 14.5,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri27.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fc30629d32f55081c0bae",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Zədələnmiş saçlar üçün müalicəvi maska",
        "descr": "Sarımsaqdan alınmış vitaminlər və mineral duzlar kimyəvi təsirə qalmış zədəli saçlar üçün ideal vasitədir. Saçı bərpa edir, yumşaqlıq və parlaqlıq verir. İstifadə qaydası: Saçlarınızı Vita-Mineral şampunu ilə yuduqdan sonra saç uclarına qədər çəkin, masaj edin, 5 dəqiqə gözləyin, ilıq su ilə yuyun.",
        "code": "Vita-Mineral",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml",
        "photo": "maska66.jpg",
        "__v": 0
    },
    {
        "_id": "5f68adf319fdff1a747e8bdc",
        "brand": "FreeLimix",
        "category": 72,
        "subcat": "Saç Dolğunlaşdırıcı Gel",
        "descr": "Tərkibindəki hialuron turşusu, keramidlər və üzüm sapı elementləri sayəsində Botutech Filler saçın daxilinə işləyərək, onu yenidən bərpa edir, saç liflərini gücləndirir və elastiklik verir. Saçlara dərhal qalınlıq effekti verir. İstifadə qaydası: Tam hopdurana qədər tətbiq edin, darayın, 5 dəqiqə saxlayın, durulamayın, Filler System Kondisioner tətbiq edin.",
        "code": "Botutech Filler Gel",
        "madein": "Italiya",
        "price": 25.9,
        "size": "150ml",
        "photo": "botutech02.jpeg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
           key={this.state.products[a]._id}
           id={this.state.products[a]._id}
           productimg={prodLogo}
           loaded={true}
           pname={this.state.products[a].brand}
           style={1} prodtype={this.state.products[a].subcat}
           descr={this.state.products[a].code + " " + this.state.products[a].size}
           price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Maskalar və Losyonlar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
