import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Lamps extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loaded: false
    };
  }
  componentDidMount(){
    axios.get(`http://45.93.100.164:3050/getProducts/57`)
      .then(res => {
        const addedProducts = res.data;
        this.setState({
          products: addedProducts,
          loaded: true
         });
      })
  }

  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        console.log(prodLogo);
        console.log('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id} id={this.state.products[a]._id} productimg={prodLogo} loaded={true} pname={this.state.products[a].brand} descr={this.state.products[a].subcat + " " + this.state.products[a].code} price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Lampalar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
