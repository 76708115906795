import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class HairWashingUnit extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f4e08c895919438c44b33bf",
        "brand": "Ceriotti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Class",
        "madein": "İtaliya",
        "photo": "hwu07.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e091495919438c44b33c0",
        "brand": "Ceriotti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Relax Elektrikli",
        "madein": "İtaliya",
        "photo": "hwu08.png",
        "__v": 0
    },
    {
        "_id": "5f4e095d95919438c44b33c1",
        "brand": "Ceriotti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Manta",
        "madein": "İtaliya",
        "photo": "hwu09.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e098395919438c44b33c2",
        "brand": "Maletti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Comfort Elektrikli",
        "madein": "İtaliya",
        "photo": "hwu10.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e09ad95919438c44b33c3",
        "brand": "Maletti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Eco Fun",
        "madein": "İtaliya",
        "photo": "hwu11.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e09cd95919438c44b33c4",
        "brand": "Maletti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Lazarro",
        "madein": "İtaliya",
        "photo": "hwu12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e09dd95919438c44b33c5",
        "brand": "Maletti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Lift Elektrikli",
        "madein": "İtaliya",
        "photo": "hwu13.jpg",
        "__v": 0
    },
    {
        "_id": "5f513d94a693e7417c40af31",
        "brand": "Ceriotti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Oxford",
        "madein": "Italiya",
        "photo": "hwu18.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id}
          id={this.state.products[a]._id} productimg={prodLogo}
          loaded={true} pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Saç Yuma Dəstləri'}/>
        <div className="listDiv">
          <Container className="listCont" style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
