import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import './Product.css'
import ProductCard from './ProductCard'
import Footer from './Footer'
import {products} from './AllProducts'
import axios from 'axios'


function finder(products, id){
  for(let a=0; a<products.length; a++){
    if(products[a]._id==id){
      return products[a]
    }
  }
}

export default class Product extends Component{

  constructor(props) {
    super(props);
    this.state = {
      product: [],
      loaded: true
    };
  }



  render() {

    const mainProduct = finder(products, this.props.match.params._id);
    return (
      <div className="WomanFirnutire">
        <Navbar/>
        <Lowernav/>
        {this.state.loaded &&
        <ProductCard
          name={mainProduct.brand}
          subcat={mainProduct.subcat}
          size={mainProduct.size}
          price={mainProduct.price}
          code={mainProduct.code}
          madein={mainProduct.madein}
          power={mainProduct.power}
          heat={mainProduct.heat}
          descr={mainProduct.descr}
          color={mainProduct.color}
          material={mainProduct.material}
          size={mainProduct.size}
          category={mainProduct.category}
          loaded={true}
          image={require('./images/'+ mainProduct.photo)}
          className="productcard"
        />
      }
      <Footer upperFooter={true}/>
      </div>
    );
  }
}
