import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class HairWashingUnitTuw extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f4e081d95919438c44b33b8",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Uniq",
        "madein": "Türkiyə",
        "photo": "hwu01.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e082d95919438c44b33b9",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Nitro",
        "madein": "Türkiyə",
        "photo": "hwu02.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e083d95919438c44b33ba",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "YS 001",
        "madein": "Türkiyə",
        "photo": "hwu03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e085995919438c44b33bb",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "YS 001 Elektrikli",
        "madein": "Türkiyə",
        "photo": "hwu03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e086a95919438c44b33bc",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Relax",
        "madein": "Türkiyə",
        "photo": "hwu04.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e087d95919438c44b33bd",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Basic",
        "madein": "Türkiyə",
        "photo": "hwu05.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e08a895919438c44b33be",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Rest",
        "madein": "Türkiyə",
        "photo": "hwu06.jpg",
        "__v": 0
    },
    {
        "_id": "5f513bf3a693e7417c40af2e",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Maya",
        "madein": "Türkiyə",
        "photo": "hwu15.JPG",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id}
          id={this.state.products[a]._id} productimg={prodLogo}
          loaded={true} pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Saç Yuma Dəstləri'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
