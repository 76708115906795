import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Hairdryers extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f5144e4c1baa704681d9275",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Line Up",
        "madein": "Italiya",
        "price": 135,
        "photo": "fen01.jpg",
        "power": "220-240V / 2100-2500W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51453ec1baa704681d9276",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Up Ionic",
        "madein": "Italiya",
        "price": 185,
        "photo": "fen02.jpg",
        "power": "220-240V / 2150-2500W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f514579c1baa704681d9277",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "DJ 3600",
        "madein": "Italiya",
        "price": 126,
        "photo": "fen03.jpg",
        "power": "220-240V / 1700-2000W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f5145abc1baa704681d9278",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "DJ 4200",
        "madein": "Italiya",
        "price": 155,
        "photo": "fen04.jpeg",
        "power": "220-240V / 2150-2500W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f5145e8c1baa704681d9279",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Marco",
        "madein": "Italiya",
        "price": 108,
        "photo": "fen05.jpg",
        "power": "220-240V / 1750-2050W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51460ec1baa704681d927a",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Zero",
        "madein": "Italiya",
        "price": 126,
        "photo": "fen06.jpg",
        "power": "220-240V / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51465cc1baa704681d927b",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Bi",
        "madein": "Italiya",
        "price": 234,
        "photo": "fen07.jpg",
        "power": "220-240V / 1900-2200W / 50-60Hz",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true} pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Hava Fenləri'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
