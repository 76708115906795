import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Balsams extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f5e3fcc9f0ae66120eb1b4a",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Quru saçlar üçün nəmləndirici kondisioner",
        "descr": "Düyü ekstraktı ilə zənginləşdirilmiş unikal tərkibli kondisioner quru və zəif saçlar üçün işlənib hazırlanmışdır. Şirin badam yağı saçları yumşaldır, qidalandırır və parlaqlıq verir. Saçlar həcmli və sağlam görünür. Rəng qoruyucudur.İstifadə qaydası: saçınızı şampunla yuduqdan sonra kondisioneri saçınıza çəkin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Hydra Conditioner",
        "madein": "İtaliya",
        "price": 12,
        "size": "250ml.",
        "photo": "maska28.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e3fe79f0ae66120eb1b4b",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Quru saçlar üçün nəmləndirici kondisioner",
        "descr": "Quru və zəif saçlar üçün işlənib hazırlanmışdır. Düyü ekstraktları ilə zənginləşdirilmiş unikal tərkib, saçları anında qidalandırır və nəmləndirir. Şirin badam yağı saçları yumşaldır və parlaqlıq verir. Saçlar həcmli və sağlam gürünür. Rəng qoruyucudur.İstifadə qaydası: saçınızı şampunla yuduqdan sonra kondisioneri saçınıza çəkin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Hydra Conditioner",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "1000ml.",
        "photo": "maska29.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e42829f0ae66120eb1b4c",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Zədəli saçlar üçün bərpaedici kondisioner",
        "descr": "Arı südü, zeytun və limnanthes yağı tərkibli kondisioner zədəli saçlar üçün idealdır. Saçları maksimum qidalandırır, saç tellərini yenidən canlandırır, saçların dolğunluğunu və parlaqlığını bərpa edir. İstifadə qaydası: nəm saça çəkin, masaj edin, 2-5 dəqiqə gözlədikdən sonra yuyun.",
        "code": "Purify Reale Conditioner",
        "madein": "İtaliya",
        "price": 24,
        "size": "1000ml.",
        "photo": "maska31.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e4d879f0ae66120eb1b4d",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Həcmverici kondisioner",
        "descr": "Tərkibi limnanthes yağı ilə zənginləşdirilmiş kondisioner, saç soğanaqlarına nüfuz edir və onları kökdən qaldırır. Saçlar daha gözəl, sıx və həcmli görünür. İstifadə qaydası: şampunla yuduqdan sonra,kondisioneri saç boyu saçınıza çəkin, 2 dəqiqə masaj edin  və yuyun.",
        "code": "Purify Volume Conditioner",
        "madein": "İtaliya",
        "price": 12,
        "size": "250ml.",
        "photo": "maska34.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e4d9b9f0ae66120eb1b4e",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Həcmverici kondisioner",
        "descr": "Tərkibi limnanthes yağı ilə zənginləşdirilmiş kondisioner, saç soğanaqlarına nüfuz edir və onları kökdən qaldırır. Saçlar daha gözəl, sıx və həcmli görünür. İstifadə qaydası: şampunla yuduqdan sonra,kondisioneri saç boyu çəkin, 2 dəqiqə masaj edin  və yuyun.",
        "code": "Purify Volume Conditioner",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "1000ml.",
        "photo": "maska35.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f56c29f0ae66120eb1b68",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Yeniləyici krem",
        "descr": "Keratin tərkibli kristal-krem saçda qoruyucu təbəqə əmələ gətirərək, saçı bərpa edir və saçqıran əmələ gəlməsinin qarşısını alır. Saçlar parlaq görünüş alır. İstifadı qaydası: nəm və ya quru saçlara vurulur,istənilən forma verilir.",
        "code": "Kerayonic Restructuring Crystal Cream",
        "madein": "İtaliya",
        "price": 10,
        "size": "150ml.",
        "photo": "sacaqulluqvasiteleri25.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f5cad9f0ae66120eb1b6a",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Müalicəvi keratin kondisioneri",
        "descr": "Keratin kondisioneri saçları intensiv bərpa edir. Amin turşuları və bambuk ekstraktı ilə zəngindir.Saçları nəmləndirir və onlara parlaqlıq verir. İstifadə qaydası: yuyulmuş nəm  saça cəkilir ,3-5 dəqiqədən sonra  yuyulur.",
        "code": "Therapy repair cheratin conditioner",
        "madein": "İtaliya",
        "price": 14.5,
        "size": "150ml.",
        "photo": "sacaqulluqvasiteleri27.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f616c9f0ae66120eb1b6d",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Balzam",
        "descr": "Haçalanmış saç uclarını bərpa edir , qidalandırır və saçqıranların yaranmasının qarşısını alır.Istifadə qaydası: yuyulmuş və dəsmal ilə qurudulmuş saçlara saç boyunca tətbiq edilir,bir neçə dəqiqə saxlanılır və yuyulur.",
        "code": "Freelimix Split-ends balm",
        "madein": "İtaliya",
        "price": 11,
        "size": "1000ml.",
        "photo": "balm01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f62859f0ae66120eb1b70",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Enerji balzamı",
        "descr": "Balzam saçları lazım olan protein və vitaminlərlə təmin edir və sağlam görünüşünü geri qaytarır. Tərkibində olan xüsusi komponentlər saçları yumşaldır və asan daranmasına səbəb olur. Istifadə qaydası: yuyulmuş və dəsmal ilə qurudulmuş saçlara saç boyunca tətbiq edilir, bir neçə dəqiqə saxlanılır və yuyulur.",
        "code": "Energizing balm",
        "madein": "İtaliya",
        "price": 11,
        "size": "1000ml.",
        "photo": "balm02.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f62f39f0ae66120eb1b71",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Həcmverici və formaverici balzam",
        "descr": "Zəif və nazik saçlar üçün ideal vasitədir.  Saçları ağırlaşdırmadan onlara həcm verir və gücləndirir. Xüsusilə quru və zədələnmiş saçlar üçün nəzərdə tutulmuşdur. Istifadə qaydası: yuyulmuş və dəsmal ilə qurudulmuş saçlara saç boyunca tətbiq edilir,bir neçə dəqiqə saxlanılır və yuyulur.",
        "code": "Volume and shape balm",
        "madein": "İtaliya",
        "price": 11,
        "size": "1000ml.",
        "photo": "balm03.jpeg",
        "__v": 0
    },
    {
        "_id": "5f68accf19fdff1a747e8bdb",
        "brand": "FreeLimix",
        "category": 73,
        "subcat": "Dolğu kondisioner",
        "descr": "Botutech Hair concept sprey kondisioner saç qalınlığını tez müddətdə artırır, ağırlaşdırmadan onlara canlılıq və ipək görünüşü verir. İstifadə qaydası: Bir neçə damcı dəsmalla qurulanmış saçlara tətbiq edin, zərif masaj hərəkətləri ilə saça hopdurun, 1-2 dəqiqə saçda saxlayın, ilıq su ilə nəmləndirərək darayın və yaxalayın.",
        "code": "Botutech Filler Conditoiner",
        "madein": "Italiya",
        "price": 19.8,
        "size": "200ml",
        "photo": "botutech01.jpeg",
        "__v": 0
    }
],
      loaded: false
    };
  }


  renderElements = () => {
    var elements = [];

      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true} pname={this.state.products[a].brand}
          style={1} prodtype={this.state.products[a].subcat}
          descr={this.state.products[a].code + " " + this.state.products[a].size}
          price={this.state.products[a].price}/>);
      }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Balzamlar və Konidisionerlər'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
