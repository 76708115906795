import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Processors extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f515436c1baa704681d9280",
        "brand": "Ceriotti",
        "category": 55,
        "subcat": "Klimazon",
        "code": "FX 3500",
        "madein": "Italiya",
        "photo": "klimazon01.jpg",
        "power": "220-240V / 900W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51544cc1baa704681d9281",
        "brand": "Ceriotti",
        "category": 55,
        "subcat": "Klimazon",
        "code": "FX 4000",
        "madein": "Italiya",
        "photo": "klimazon02.jpg",
        "power": "220-240V / 1400W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f515461c1baa704681d9282",
        "brand": "Ceriotti",
        "category": 55,
        "subcat": "Klimazon",
        "code": "C 3000",
        "madein": "Italiya",
        "photo": "klimazon03.jpg",
        "power": "220-240V / 1090W / 50Hz",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Klimazonlar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
