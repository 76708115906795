import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class WomanChairs extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f512c05a693e7417c40af0a",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Mimi",
        "madein": "Italiya",
        "photo": "qoltuq01.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c23a693e7417c40af0b",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Tulipa",
        "madein": "Italiya",
        "photo": "qoltuq02.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c69a693e7417c40af0c",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Musette",
        "madein": "Italiya",
        "photo": "qoltuq03.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c78a693e7417c40af0d",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Angelina",
        "madein": "Italiya",
        "photo": "qoltuq04.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c96a693e7417c40af0e",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Lioness Make Up",
        "madein": "Italiya",
        "photo": "qoltuq05.jpg",
        "__v": 0
    },
    {
        "_id": "5f512cb6a693e7417c40af0f",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Eco Fun",
        "madein": "Italiya",
        "photo": "qoltuq06.jpg",
        "__v": 0
    },
    {
        "_id": "5f512ccea693e7417c40af10",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Pompadour",
        "madein": "Italiya",
        "photo": "qoltuq07.jpg",
        "__v": 0
    },
    {
        "_id": "5f512ce7a693e7417c40af11",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Angelina Rec.",
        "madein": "Italiya",
        "photo": "qoltuq08.jpg",
        "__v": 0
    },
    {
        "_id": "5f512d60a693e7417c40af12",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Giorgia",
        "madein": "Italiya",
        "photo": "qoltuq09.png",
        "__v": 0
    },
    {
        "_id": "5f512da6a693e7417c40af13",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Ginevra",
        "madein": "Italiya",
        "photo": "qoltuq10.jpg",
        "__v": 0
    },
    {
        "_id": "5f512ddfa693e7417c40af14",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Class",
        "madein": "Italiya",
        "photo": "qoltuq11.png",
        "__v": 0
    },
    {
        "_id": "5f512e0ea693e7417c40af15",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Grace",
        "madein": "Italiya",
        "photo": "qoltuq12.jpeg",
        "__v": 0
    },
    {
        "_id": "5f512e6da693e7417c40af16",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Sabrina",
        "madein": "Italiya",
        "photo": "qoltuq13.png",
        "__v": 0
    },
    {
        "_id": "5f512ea0a693e7417c40af17",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Flex",
        "madein": "Italiya",
        "photo": "qoltuq14.png",
        "__v": 0
    },
    {
        "_id": "5f512ec4a693e7417c40af18",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Cloud",
        "madein": "Italiya",
        "photo": "qoltuq15.jpg",
        "__v": 0
    },
    {
        "_id": "5f512ed8a693e7417c40af19",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Carol",
        "madein": "Italiya",
        "photo": "qoltuq16.jpg",
        "__v": 0
    },
    {
        "_id": "5f513016a693e7417c40af1b",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Amara",
        "madein": "Türkiyə",
        "photo": "qoltuq18.jpg",
        "__v": 0
    },
    {
        "_id": "5f513068a693e7417c40af1c",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Avanti",
        "madein": "Türkiyə",
        "photo": "qoltuq19.jpg",
        "__v": 0
    },
    {
        "_id": "5f51309ea693e7417c40af1d",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Diamond",
        "madein": "Türkiyə",
        "photo": "qoltuq20.jpg",
        "__v": 0
    },
    {
        "_id": "5f5130d3a693e7417c40af1e",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Cute",
        "madein": "Türkiyə",
        "photo": "qoltuq21.jpg",
        "__v": 0
    },
    {
        "_id": "5f513125a693e7417c40af1f",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Evo Black",
        "madein": "Türkiyə",
        "photo": "qoltuq22.jpg",
        "__v": 0
    },
    {
        "_id": "5f51317da693e7417c40af20",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Flora",
        "madein": "Türkiyə",
        "photo": "qoltuq23.jpg",
        "__v": 0
    },
    {
        "_id": "5f5131b0a693e7417c40af21",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Halley",
        "madein": "Türkiyə",
        "photo": "qoltuq24.jpg",
        "__v": 0
    },
    {
        "_id": "5f513283a693e7417c40af22",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Quenn",
        "madein": "Türkiyə",
        "photo": "qoltuq25.jpg",
        "__v": 0
    },
    {
        "_id": "5f5132caa693e7417c40af23",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Elite Black",
        "madein": "Türkiyə",
        "photo": "qoltuq26.jpg",
        "__v": 0
    },
    {
        "_id": "5f5137b7a693e7417c40af24",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Classico",
        "madein": "Türkiyə",
        "photo": "qoltuq27.jpg",
        "__v": 0
    },
    {
        "_id": "5f513812a693e7417c40af25",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Nova",
        "madein": "Türkiyə",
        "photo": "qoltuq28.jpg",
        "__v": 0
    },
    {
        "_id": "5f513854a693e7417c40af26",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Slim",
        "madein": "Türkiyə",
        "photo": "qoltuq29.jpg",
        "__v": 0
    },
    {
        "_id": "5f51386ca693e7417c40af27",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Uniq",
        "madein": "Türkiyə",
        "photo": "qoltuq30.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Qadın Qoltuqları'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
