import React, { Component } from "react";
import Slider from "react-slick";
import Img1 from './images/pis2.jpg'
import Img4 from './images/slideshow2.jpg'
import Img6 from './images/slideshow5.jpg'
import Img7 from './images/slideshow6.jpg'
import Img9 from './images/slideshow8.jpg'
import './Slideshow.css'

export default class Slideshow extends Component{
  render() {
        return (
          <div style={{width: '100%'}} className="ImgContainer">
            <Slider style={{width: '100%', backgroundColor: 'white'}} dots={true} infinite={true} arrows={true} autoplay={true} speed={3000}>
              <div>
                <img className="slideImag" src={Img4}  alt="Aksa Cosmetics"/>
              </div>
              <div>
                <img className="slideImag" src={Img6}  alt="Aksa Cosmetics"/>
              </div>
              <div>
                <img className="slideImag" src={Img1}  alt="Aksa Cosmetics"/>
              </div>
              <div>
                <img className="slideImag" src={Img7}  alt="Aksa Cosmetics"/>
              </div>
              <div>
                <img className="slideImag" src={Img9}  alt="Aksa Cosmetics"/>
              </div>
            </Slider>
          </div>
        );
  }
}
