import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class PaintTrollers extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f4ccf6195919438c44b333a",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Easy",
        "madein": "Italiya",
        "price": 400,
        "photo": "troller01.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccf7a95919438c44b333b",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Be One",
        "madein": "Italiya",
        "price": 390,
        "photo": "troller02.jpeg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccf8c95919438c44b333c",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Jazz",
        "madein": "Italiya",
        "price": 300,
        "photo": "troller03.png",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccfb595919438c44b333d",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Ciak",
        "madein": "Italiya",
        "price": 480,
        "photo": "troller04.png",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccfde95919438c44b333e",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Ciak Duo",
        "madein": "Italiya",
        "price": 580,
        "photo": "troller05.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccffa95919438c44b333f",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Oscar",
        "madein": "Italiya",
        "price": 360,
        "photo": "troller06.png",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4cd02295919438c44b3340",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Surf",
        "madein": "Italiya",
        "price": 360,
        "photo": "troller07.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4cd04295919438c44b3341",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Taxi",
        "madein": "Italiya",
        "price": 520,
        "photo": "troller08.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4cd14795919438c44b3343",
        "brand": " ",
        "category": 61,
        "subcat": "Boya Trolleri",
        "code": "Service Plus",
        "madein": "Italiya",
        "price": 280,
        "photo": "troller10.jpg",
        "material": "Plastik",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Boya Arabaları'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
