import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import ThirdHeader from './ThirdHeader'
import Footer from './Footer'

export default class ManicPedicTools extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f4dff2995919438c44b339d",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "421",
        "madein": "Italiya",
        "price": 13,
        "size": "5mm",
        "photo": "mpa01.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dff5795919438c44b339e",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "421",
        "madein": "Italiya",
        "price": 13,
        "size": "3mm",
        "photo": "mpa02.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dff9695919438c44b339f",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "421",
        "madein": "Italiya",
        "price": 13,
        "size": "6mm",
        "photo": "mpa03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dffa295919438c44b33a0",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "421",
        "madein": "Italiya",
        "price": 13,
        "size": "14mm",
        "photo": "mpa04.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dffcc95919438c44b33a1",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "435",
        "madein": "Italiya",
        "price": 6.7,
        "photo": "mpa05.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dffe695919438c44b33a2",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "0222/7",
        "madein": "Italiya",
        "price": 3.6,
        "photo": "mpa06.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dfff895919438c44b33a3",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "301",
        "madein": "Italiya",
        "price": 2,
        "photo": "mpa07.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e000c95919438c44b33a4",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "303",
        "madein": "Italiya",
        "price": 2,
        "photo": "mpa08.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e002895919438c44b33a5",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "310",
        "madein": "Italiya",
        "price": 2.2,
        "photo": "mpa09.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e003695919438c44b33a6",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "311",
        "madein": "Italiya",
        "price": 2.5,
        "photo": "mpa10.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e006a95919438c44b33a7",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "363/7.5",
        "madein": "Italiya",
        "price": 6.4,
        "photo": "mpa11.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e00ae95919438c44b33a8",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0615",
        "madein": "Italiya",
        "price": 59,
        "size": "3mm",
        "photo": "mpa12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e00dc95919438c44b33a9",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "401",
        "madein": "Italiya",
        "price": 4.4,
        "photo": "mpa13.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e011995919438c44b33aa",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "411",
        "madein": "Italiya",
        "price": 5,
        "photo": "mpa14.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e014195919438c44b33ab",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "410",
        "madein": "Italiya",
        "price": 5.5,
        "photo": "mpa15.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e01a095919438c44b33ac",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0603",
        "madein": "Italiya",
        "price": 24.4,
        "size": "12mm",
        "photo": "mpa16.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e01d395919438c44b33ad",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0613",
        "madein": "Italiya",
        "price": 54.4,
        "size": "3mm",
        "photo": "mpa17.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e01e995919438c44b33ae",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0686",
        "madein": "Italiya",
        "price": 17.8,
        "size": "5mm",
        "photo": "mpa18.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e020a95919438c44b33af",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "T241",
        "madein": "Italiya",
        "price": 7.6,
        "photo": "mpa19.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e023b95919438c44b33b0",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "T247",
        "madein": "Italiya",
        "price": 8.1,
        "photo": "mpa20.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e025695919438c44b33b1",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pedikür Aləti",
        "code": "451",
        "madein": "Italiya",
        "price": 5.4,
        "photo": "mpa21.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e026a95919438c44b33b2",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pedikür Aləti",
        "code": "452",
        "madein": "Italiya",
        "price": 10.8,
        "photo": "mpa22.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e028295919438c44b33b3",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pedikür Aləti",
        "code": "453",
        "madein": "Italiya",
        "price": 3.6,
        "photo": "mpa23.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e029395919438c44b33b4",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Dabandaşı",
        "code": "456",
        "madein": "Italiya",
        "price": 5.1,
        "photo": "mpa24.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e031495919438c44b33b5",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Dabandaşı",
        "code": "456",
        "madein": "Italiya",
        "price": 3,
        "photo": "mpa25.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e034395919438c44b33b6",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0680",
        "madein": "Italiya",
        "price": 26.1,
        "size": "10mm",
        "photo": "mpa26.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e036495919438c44b33b7",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Maqqaş",
        "code": "B140/114",
        "madein": "Italiya",
        "price": 5.2,
        "photo": "mpa27.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true} pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire" style={{backgroundColor: '#a19c9c'}}>
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Manikür və Pedikür alətləri'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
