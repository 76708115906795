import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Mens extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f60e78c29d32f55081c0baf",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Apex Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 9.5,
        "size": "150ml",
        "photo": "baximseriyasi01.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e7ab29d32f55081c0bb0",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Dynamic Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 10.8,
        "size": "150ml",
        "photo": "baximseriyasi02.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e80e29d32f55081c0bb1",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Gravity Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 11.4,
        "size": "150ml",
        "photo": "baximseriyasi03.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e82e29d32f55081c0bb2",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Matte Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 10.9,
        "size": "150ml",
        "photo": "baximseriyasi04.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e84e29d32f55081c0bb3",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç geli",
        "code": "Mnemonic Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 7.7,
        "size": "250ml",
        "photo": "baximseriyasi05.png",
        "__v": 0
    },
    {
        "_id": "5f60e86929d32f55081c0bb4",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Resist Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 8.2,
        "size": "150ml",
        "photo": "baximseriyasi06.png",
        "__v": 0
    },
    {
        "_id": "5f60e89629d32f55081c0bb5",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Losyon",
        "code": "Black Fig Eau De Cologne",
        "madein": "Türkiyə",
        "price": 8.9,
        "size": "370ml",
        "photo": "baximseriyasi07.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e8a629d32f55081c0bb6",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Losyon",
        "code": "Fresh Lemon Eau De Cologne",
        "madein": "Türkiyə",
        "price": 8.9,
        "size": "370ml",
        "photo": "baximseriyasi08.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e8c929d32f55081c0bb7",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Krem Losyon",
        "code": "Blue Ice After Shave Cream Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi09.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e8ee29d32f55081c0bb8",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Krem Losyon",
        "code": "Kick Start After Shave Cream Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi10.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e90929d32f55081c0bb9",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Krem Losyon",
        "code": "Shine Out After Shave Cream Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi11.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e93029d32f55081c0bba",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Losyon",
        "code": "Premium After Shave Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi12.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e93e29d32f55081c0bbb",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Losyon",
        "code": "Platinum After Shave Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi13.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e95729d32f55081c0bbc",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç üçün Losyon",
        "code": "Cocomama After Shave Cologne",
        "madein": "Türkiyə",
        "price": 13,
        "size": "260ml",
        "photo": "baximseriyasi14.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e97a29d32f55081c0bbd",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Şampun",
        "code": "Extension Hair Shampoo",
        "madein": "Türkiyə",
        "price": 18.6,
        "size": "370ml",
        "photo": "baximseriyasi15.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e9a629d32f55081c0bbe",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Şampun",
        "code": "Pro Active Hair Shampoo",
        "madein": "Türkiyə",
        "price": 17.5,
        "size": "370ml",
        "photo": "baximseriyasi16.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e9da29d32f55081c0bbf",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Şampun",
        "code": "Thick & Strong Hair Shampoo",
        "madein": "Türkiyə",
        "price": 7,
        "size": "260ml",
        "photo": "baximseriyasi17.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e9f829d32f55081c0bc0",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Üz kremi",
        "code": "Lucky Blue Face Cream",
        "madein": "Türkiyə",
        "price": 13,
        "size": "275ml",
        "photo": "baximseriyasi18.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ea1a29d32f55081c0bc1",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Üz üçün gil maska",
        "code": "Mud Face Clay Mask",
        "madein": "Türkiyə",
        "price": 16.4,
        "size": "250ml",
        "photo": "baximseriyasi19.jpg",
        "__v": 0
    },
    {
        "_id": "5f60eb0c29d32f55081c0bc2",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saqqal yağı",
        "code": "Mustache Hipster Beard",
        "madein": "Türkiyə",
        "price": 13.5,
        "size": "75ml",
        "photo": "baximseriyasi20.jpg",
        "__v": 0
    },
    {
        "_id": "5f60eb2329d32f55081c0bc3",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saqqal şampunu",
        "code": "Mustache Hipster Beard Shampoo",
        "madein": "Türkiyə",
        "price": 11.3,
        "size": "230ml",
        "photo": "baximseriyasi21.jpg",
        "__v": 0
    },
    {
        "_id": "5f60eb4029d32f55081c0bc4",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saqqal kondisioneri",
        "code": "Mustache Hipster Conditioner",
        "madein": "Türkiyə",
        "price": 11.8,
        "size": "125ml",
        "photo": "baximseriyasi22.jpg",
        "__v": 0
    },
    {
        "_id": "5f60eb9829d32f55081c0bc5",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təmizləyici üz losyonu",
        "code": "Relief Face Tonic",
        "madein": "Türkiyə",
        "price": 11.5,
        "size": "260ml",
        "photo": "baximseriyasi24.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ec0d29d32f55081c0bc6",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təmizləyici üz losyonu",
        "code": "Təraş üçün gel",
        "madein": "Türkiyə",
        "price": 14,
        "size": "1000ml",
        "photo": "baximseriyasi25.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ecaa29d32f55081c0bc7",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Duş geli",
        "code": "Shower Body Gel",
        "madein": "Türkiyə",
        "price": 10.5,
        "size": "370ml",
        "photo": "baximseriyasi26.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ecc229d32f55081c0bc8",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Üz skrabı",
        "code": "Showy Face Scrub",
        "madein": "Türkiyə",
        "price": 16.3,
        "size": "250ml",
        "photo": "baximseriyasi27.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ed5c29d32f55081c0bc9",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saqqal yağı(Təraşdan əvvəl)",
        "code": "Mustache Hipster Pre Shave Oil",
        "madein": "Türkiyə",
        "price": 18.5,
        "size": "75ml",
        "photo": "baximseriyasi23.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }

  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          style={1}
          prodtype={this.state.products[a].subcat}
          descr={this.state.products[a].code + " " + this.state.products[a].size}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Kişilər üçün'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
