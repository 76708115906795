import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Combs extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f5d25009f0ae66120eb1b2c",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Saç tökülməsinə qarşı şampun",
        "descr": "Çay ağacı yağı, gicitkən və bibər tərkibli şampun, saç tökülməsi və profilaktikası üçün hazırlanmışdır. Xüsusi tərkibli şampun, qan dövranını sürətləndirir,saç soğanaqlarının fəaliyyətini yaxşılaşdırır və saç köklərini möhkəmləndirir. Antiseptik, dezinfeksiyaedici, sakitləşdirici və yağ balansını tənzimləmə xüsusiyyətinə malikdir. İstifadə qaydası: Yaş saça tətbiq edin. Saç və saç dərisini masaj edin, 2 dəqiqə saxlayın və yuyun. Qeyd: Ən yaxşı nəticə üçün həftədə iki dəfə olmaqla, 6-12 həftə istifadə etməyiniz məsləhət görülür.",
        "code": "K-05 Anti Hair Loss Shampoo",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "250ml",
        "photo": "shampoo01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d34029f0ae66120eb1b2f",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Yağlı saçlar üçün şampun",
        "descr": "Şampun saç dərisini dərindən təmizləyir və artıq sebumu aradan qaldırır. Çay ağacı yağı iltihab əleyhinə təsir göstərir, yağ balansını normallaşdırır, saç toxumalarını oksigenlə təmin edir və qan dövranını yaxşılaşdırır. Saçlar yenidən təbii, parlaq və sağlam görünüş alır. İstifadə qaydası:Masaj hərəkətləri ilə baş dərisinə çəkin və yuyun. Qeyd: Ən yaxşı  nəticə üçün həftədə 2 dəfə, profilaktika üçün isə həftədə 1 dəfə istifadəsi məsləhət görülür.",
        "code": "K-05 Sebum Balancing Shampoo",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "1000ml",
        "photo": "shampoo07.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d34509f0ae66120eb1b30",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Yağlı saçlar üçün şampun",
        "descr": "Şampun saç dərisini dərindən təmizləyir və artıq sebumu aradan qaldırır. Çay ağacı yağı iltihab əleyhinə təsir göstərir, yağ balansını normallaşdırır, saç toxumalarını oksigenlə təmin edir və qan dövranını yaxşılaşdırır. Saçlar yenidən təbii, parlaq və sağlam görünüş alır. İstifadə qaydası:Masaj hərəkətləri ilə baş dərisinə çəkin və yuyun. Qeyd: Ən yaxşı  nəticə üçün həftədə 2 dəfə, profilaktika üçün isə həftədə 1 dəfə istifadəsi məsləhət görülür.",
        "code": "K-05 Sebum Balancing Shampoo",
        "madein": "İtaliya",
        "price": 25,
        "size": "1000ml",
        "photo": "shampoo08.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5d35089f0ae66120eb1b31",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Kəpəyə qarşı şampun",
        "descr": "Kəpəyə qarşı müalicəvi şampun baş dərisini zərif və təsirli şəkildə təmizləyir, kəpəyin səbəb olduğu qaşınmanı,quruluğu və qıcıqlanmanı aradan qaldırmağa kömək edir. Çay ağacı yağı ilə zənginləşdirilmiş şampun antifungal, antibakterial və antiseptik xüsusiyyətlərə malikdir. Tərkibində olan Pirokton Olamin isə baş dərisinin qabıqlanmasının qarşısını alır. Saçları yumşaq, parlaq və sağlam edir. İstifadə qaydası: Yaş saça tətbiq edin. Saç və saç dərisini masaj edin, 2 dəqiqə saxlayın və yuyun. Qeyd: Ən yaxşı nəticə üçün həftədə ən azı 2 dəfə istifadə edin. Bu saç baxımı məhsulundan  müntəzəm istifadə həm də baş dərisi infeksiyasının qarşısını alır.",
        "code": "K-05 Dandruff Removing Shampoo",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "250ml",
        "photo": "shampoo10.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d354e9f0ae66120eb1b32",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Kəpəyə qarşı şampun",
        "descr": "Kəpəyə qarşı müalicəvi şampun  baş dərisini zərif və təsirli şəkildə təmizləyir, kəpəyin səbəb olduğu  qaşınmanı,quruluğu və qıcıqlanmanı aradan qaldırmağa kömək edir. Çay ağacı yağı ilə zənginləşdirilmiş şampun antifungal, antibakterial və antiseptik xüsusiyyətlərə malikdir. Tərkibində olan Piroctone Olamine isə baş dərisinin qabıqlanmasının qarşısını alır. Saçları yumşaq, parlaq və sağlam edir. İstifadə qaydası: Yaş saça tətbiq edin. Saç və saç dərisini masaj edin, 2 dəqiqə saxlayın və yuyun. Qeyd: Ən yaxşı nəticə üçün həftədə ən azı 2 dəfə istifadə edin. Bu saç baxımı məhsulundan  müntəzəm istifadəhəm də baş dərisi infeksiyasının qarşısını alır.",
        "code": "K-05 Dandruff Removing Shampoo",
        "madein": "İtaliya",
        "price": 25,
        "size": "1000ml",
        "photo": "shampoo11.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d36929f0ae66120eb1b33",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Quru saçlar üçün şampun",
        "descr": "Düyü proteinləri və badam yağı tərkibli şampun quru və zəif saçlar üçün nəzərdə tutulmuşdur. Tərkibində olan düyü proteinləri saçları qidalandırır və nəmləndirir. Badam yağı isə xüsusi yumşaqlıq və parlaqlıq bəxş edir. Saçlar daha həcmli, sağlam və parlaq görünür. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 2 dəqiqə masaj edin və yuyun. Şampundan sonra Hydra kondisionerindən istifadə etmək məsləhət görülür.",
        "code": "Purify Hydra Shampoo",
        "madein": "İtaliya",
        "price": 12,
        "size": "300ml",
        "photo": "shampoo26.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d36c79f0ae66120eb1b34",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Quru saçlar üçün şampun",
        "descr": "Düyü proteinləri və badam yağı tərkibli şampun quru və zəif saçlar üçün nəzərdə tutulmuşdur. Tərkibində olan düyü proteinləri saçları qidalandırır və nəmləndirir. Badam yağı isə xüsusi yumşaqlıq və parlaqlıq bəxş edir. Saçlar daha həcmli, sağlam və parlaq görünür. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 2 dəqiqə masaj edin və yuyun. Şampundan sonra Hydra kondisionerindən istifadə etmək məsləhət görülür.",
        "code": "Purify Hydra Shampoo",
        "madein": "İtaliya",
        "price": 18,
        "size": "1000ml",
        "photo": "shampoo27.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d37209f0ae66120eb1b35",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Zədəli saçlar üçün bərpaedici şampun",
        "descr": "Bu şampun işçi bal arılarının hazırladığı təmiz arı südü ilə zənginləşdirilmişdir. Xüsusi vitaminləri,proteinləri və mineralları özündə cəmləşdirən arı südü, saçın dərin qatlarına nüfuz edərək,  onları yüksək dərəcədə bərpa edir.Limnanthes yağı isə saçlara xüsusi yumşaqlıq, möhkəmlik və parlaqlıq bəxş edir. İstifadə qaydası: Nəm saçlara çəkin,  kopükləndirin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Reale Shampoo",
        "madein": "İtaliya",
        "price": 23,
        "size": "1000ml",
        "photo": "shampoo30.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d37709f0ae66120eb1b36",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Həcmverici şampun",
        "descr": "Şampun zəif saçlar üçün nəzərdə tutulmuşdur. Tərkibi Limnanthes  yağı ilə zənginləşdirilmiş şampun, saç soğanaqlarına nüfuz edərək , onları kökdən qaldırır və həcm verir. Beləliklə,saçlar daha gözəl, sıx və həcmli görünür. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Volume Shampoo",
        "madein": "İtaliya",
        "price": 12,
        "size": "300ml",
        "photo": "shampoo32.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d379f9f0ae66120eb1b37",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Həcmverici şampun",
        "descr": "Şampun zəif saçlar üçün nəzərdə tutulmuşdur. Tərkibi Limnanthes  yağı ilə zənginləşdirilmiş şampun, saç soğanaqlarına nüfuz edərək , onları kökdən qaldırır və həcm verir. Beləliklə,saçlar daha gözəl, sıx və həcmli görünür. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Volume Shampoo",
        "madein": "İtaliya",
        "price": 18,
        "size": "1000ml",
        "photo": "shampoo33.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d37f79f0ae66120eb1b38",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Tonlaşdırıcı şampun",
        "descr": "Blonde Elevation açıq rəngli, kimyəvi burulmuş və ağardılmış saçlar üçün hazırlanmışdır. İpək proteinləri ilə zənginləşdirilmiş tərkibi, boyalı və kimyəvi buruqlu saçlara yumşaqlıq, elastiklik və parlaqlıq bəxş edir.Ağ  və ağardılmış saçlarda xoşagəlməz sarılığı aradan qaldırır. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 1-2 dəqiqə saxlayın və yuyun.",
        "code": "Blonde Elevation Shampoo",
        "madein": "İtaliya",
        "price": 15,
        "size": "300ml",
        "photo": "shampoo34.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d38759f0ae66120eb1b39",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Boyalı saçlar üçün şampun",
        "descr": "Colorpro rəngli saçlar üçün hazırlanmışdır. Hidrolizə edilmiş ipək proteinləri və keratinlə zənginləşdirilmiş şampun,boyalı saçları bərpa edir,  saç dərisini nəmləndirir və boyanın uzun müddət qalmasını təmin edir. Aşağı PH balansı saç kutikullarını bağlayır və boyanın axmasının qarşısını alır.  Sulfatsız. Parabensiz. Slikonsuz.",
        "code": "ColorPro Shampoo",
        "madein": "İtaliya",
        "price": 17,
        "size": "300ml",
        "photo": "shampoo35.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dd1a69f0ae66120eb1b3a",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Boyalı üçün qidalandırıcı şampun",
        "descr": "Monoi de Taiti və keratin tərkibli sulfatsız şampun, əsasən, zəif, boyalı və quru saçlar üçün nəzərdə tutulmuşdur. Dərindən qidalandırır, nəmləndirir, saç tellərini bərpa edir, saçlara sağlam və parlaq görünüş verir. İstifadə Qaydası: Qatı emulsiya şəklində olduğu üçün çox su ilə köpükləndirin və yuyun.",
        "code": "Maraes Color Nourishing Shampoo",
        "madein": "İtaliya",
        "price": 17,
        "size": "300ml",
        "photo": "shampoo15.png",
        "__v": 0
    },
    {
        "_id": "5f5dd1df9f0ae66120eb1b3b",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Boyalı üçün qidalandırıcı şampun",
        "descr": "Monoi de Taiti və keratin tərkibli sulfatsız şampun, əsasən, zəif, boyalı və quru saçlar üçün nəzərdə tutulmuşdur. Dərindən qidalandırır, nəmləndirir, saç tellərini bərpa edir, saçlara sağlam və parlaq görünüş verir. İstifadə Qaydası: Qatı emulsiya şəklində olduğu üçün çox su ilə köpükləndirin və yuyun.",
        "code": "Maraes Color Nourishing Shampoo",
        "madein": "İtaliya",
        "price": 31,
        "size": "1000ml",
        "photo": "shampoo16.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dd3fe9f0ae66120eb1b3c",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Qıvrım saçlar üçün bərpaedici şampun",
        "descr": "Monoi de Taiti və Marual yağı tərkibli sulfatsız şampun, əsasən, qıvrım  və dalğalı saçlar üçün nəzərdə tutulmuşdur. Saçlarda təbii elastikliyi bərpa edir, saçlara yumşaq və parlaq görünüş verir. İstifadə Qaydası: Yaş saça tətbiq edin. Saç və saç dərisini masaj edin və yuyun",
        "code": "Curl Revitalizing Shampoo",
        "madein": "İtaliya",
        "price": 17,
        "size": "300ml",
        "photo": "shampoo18.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dd4119f0ae66120eb1b3d",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Qıvrım saçlar üçün bərpaedici şampun",
        "descr": "Monoi de Taiti və Marual yağı tərkibli sulfatsız şampun, əsasən, qıvrım  və dalğalı saçlar üçün nəzərdə tutulmuşdur. Saçlarda təbii elastikliyi bərpa edir, saçlara yumşaq və parlaq görünüş verir. İstifadə Qaydası: yaş saça tətbiq edin, masaj edin və yuyun.",
        "code": "Curl Revitalizing Shampoo",
        "madein": "İtaliya",
        "price": 31,
        "size": "1000ml",
        "photo": "shampoo19.png",
        "__v": 0
    },
    {
        "_id": "5f5ddb1f9f0ae66120eb1b3e",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Yağlı saçlar üçün şampun",
        "descr": "Ayı pəncəsi, ağcaqayın, kakao ekstratlı Dermopure şampun saçları arzu edilməyən kəpəklərdən azad edir və saçların bakterial florasını, sebum balansını bərpa edir. Saçlara yenidən yumşaq, sağlam və parlaq görünüş verir. İçindəki zəngin minerallar və oliqoelementlər və gil, toksinləri özünə çəkərək, saçı ölü hüceyrələrdən təmizləyir və saç dərisini bərpa edir. İstifadə qaydası: yaş saça tətbiq edin, masaj edin və yuyun.",
        "code": "Dermopure Shampoo",
        "madein": "İtaliya",
        "price": 20,
        "size": "500ml",
        "photo": "sacaqulluqvasiteleri01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5ddc5f9f0ae66120eb1b3f",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Tonlaşdırıcı şampun",
        "descr": "Tərkibində olan aktiv komponentlər ağardılmış və təbii ağ saçlarda olan xoşagəlməz sarılığı aradan qaldırır. Saçlara yumşaqlıq və elastiklik verir. İstifadə qaydası: Saçınıza vurun, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Anti Yellow Shampoo",
        "madein": "İtaliya",
        "price": 11,
        "size": "250ml",
        "photo": "sacaqulluqvasiteleri02.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f46f39f0ae66120eb1b5c",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Bərpaedici şampun",
        "descr": "Keratin və hialuron tərkibli şampun zəif və kimyəvi təsirə məruz qalmış saçları yeniləyir və qidalandırır. Keratin və hialuron turşularının birləşməsi saçları dərindən bərpa edir və yaşlanmaya qarşı mübarizə aparır. Birinci istifadədən sonra saçlar canlı və sağlam görünüş alır.Tərkibində əlavə boya və SLS yoxdur. TƏRKİBİ: amin turşuları,hialuron turşusu,minerallar,vitaminlər",
        "code": "Kerayonic Restructuring Shampoo",
        "madein": "İtaliya",
        "price": 14,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri10.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f482a9f0ae66120eb1b5d",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Müalicəvi şampun",
        "descr": "Keratin  şampunu saçları intensiv bərpa  edir, amin turşularının unikal formulası zədəli saçlara dərindən təsir edərək, onların təbii quruluşunu yaxşılaşdırır, yulaf proteinləri və vitamin B saç örtüyunu yeniləyir və saçların uzanmasına kömək edir. TƏRKİBİ: amin turşuları, proteinlər, vitaminlər və s.",
        "code": "Therapy Repair Shampoo",
        "madein": "İtaliya",
        "price": 15.4,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri16.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f48459f0ae66120eb1b5e",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Müalicəvi şampun",
        "descr": "Keratin  şampunu saçları intensiv bərpa  edir, amin turşularının unikal formulası zədəli saçlara dərindən təsir edərək, onların təbii quruluşunu yaxşılaşdırır, yulaf proteinləri və vitamin B saç örtüyunu yeniləyir və saçların uzanmasına kömək edir. TƏRKİBİ: amin turşuları, proteinlər, vitaminlər və s.",
        "code": "Therapy Repair Shampoo",
        "madein": "İtaliya",
        "price": 22,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri17.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f49149f0ae66120eb1b5f",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Bərpaedici şampun",
        "descr": "Keratin və hialuron tərkibli şampun zəif və kimyəvi təsirə məruz qalmış saçları yeniləyir və qidalandırır. Keratin və hialuron turşularının birləşməsi saçları dərindən bərpa edir və yaşlanmaya qarşı vasitədir.Birinci istifadədən sonra saçlar canlı və sağlam görünüş alır. Tərkibində əlavə boya və SLS yoxdur. TƏRKİBİ:amin turşuları, hialuron turşusu, minerallar, vitaminlər",
        "code": "Kerayonic Shampoo",
        "madein": "İtaliya",
        "price": 25.2,
        "size": "1000ml.",
        "photo": "sacaqulluqvasiteleri11.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f4a0d9f0ae66120eb1b60",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Boya qoruyucu şampun",
        "descr": "Tərkibindəki dəniz elastinləri boyamadan sonra saçda qalan alkalin artıqlarını təmizləyir və boyanın axmasının qarşısını alır. Alfa hidroksid turşuları hüceyrə yenilənməsini stimullaşdırır və saçda nəmlənməni artırır. Saçlar daha elastik və parlaq görünür. İstifadə qaydası: Saçdakı boyanı yuduqdan sonra şampunu vurun və masaj edin. Şampun saça 2 dəfə tətbiq edilir. İkinci dəfə istifadədən sonra 2-3 dəqiqə saxlanılır və yuyulur.",
        "code": "Colour Save Shampoo",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri19.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f4b259f0ae66120eb1b61",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Qıvrım saçlar üçün tənzimləyici şampun",
        "descr": "Qıvrım, buruq, tabe olmayan saçları nəmləndirir və onları qaydaya salır. Dəridə olan quruluğu aradan qaldıraraq saçlara yumşaqlıq və həcm verir. E vitamini isə hüceyrə yenilənməsini təmin edir. Həmçinin şampunun tərkibində olan arqan yağı buruqları ağırlaşdırmadan, onlara ipək görünüşü bəxş edir.",
        "code": "Perfect Curls Defining Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri20.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f52169f0ae66120eb1b62",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Şampun",
        "descr": "Saç spreylərini, gelləri və digər məhsul artıqlarını saç köklərindən dərindən təmizləyir. Tərkibindəki komponentlər saç düzləşdirmə və saç burmadan əvvəl ölü dərinin uzunmüddətli təmizlənməsini təmin edir.",
        "code": "Salt-plus shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri21.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f52df9f0ae66120eb1b63",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Şampun",
        "descr": "Salt-free bütün dəri və saç tiplərinə uyğun olub, əsasən, düzləşdirmə və permanentdən sonra istifadəsi nəzərədə tutulmuşdur. Həmçinin tərkibində duz olmadığı üçün rənglənmədən sonra zədəyə məruz qalmış və zəifləmiş saçlara tətbiq olunur, eyni zamanda dəniz və hovuzdan sonra saça tətbiq olunması tövsiyyə olunur.",
        "code": "Salt-free shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri22.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f53a59f0ae66120eb1b64",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Kəpəyə qarşı şampun",
        "descr": "Gicitkən və propolis tərkibli şampun saçlarda olan kəpəyi və qıcıqlanmanı aradan qaldırır. Gicitkən saç dərisini stimullaşdırır, saç köklərini qidalandıraraq, onlara parlaqlıq verir. Propolis saçda olan kəpəkləri aradan qaldırır, həmçinin saçda yenidən kəpək yaranmasının qarşısını alır.",
        "code": "Anti-dandruff shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri23.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f53e99f0ae66120eb1b65",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Kəpəyə qarşı şampun",
        "descr": "Gicitkən və propolis tərkibli şampun saçlarda olan kəpəyi və qıcıqlanmanı aradan qaldırır. Gicitkən saç dərisini stimullaşdırır, saç köklərini qidalandıraraq, onlara parlaqlıq verir. Propolis saçda olan kəpəkləri aradan qaldırır, həmçinin saçda yenidən kəpək yaranmasının qarşısını alır.",
        "code": "Anti-dandruff shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml.",
        "photo": "sacaqulluqvasiteleri03.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f546c9f0ae66120eb1b66",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Yağlı qarşı şampun",
        "descr": "B6 vitamini ilə zəngin olan şampun yağ əmələ gəlməsinin qarşısını alaraq, yağlı saç dərisini normallaşdırır. Tərkibində olan oligoelementlər saçda hüceyrə yenilənməsini asanlaşdırır, saçları dərindən nəmləndirir və qan dövranını yaxşılaşdırır. İstifadə qaydası: yaş saça tətbiq edilərək, masaj edilir və daha sonra durulanır.",
        "code": "Freelimix oily hair, scalp shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml.",
        "photo": "sacaqulluqvasiteleri13.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fadc229d32f55081c0ba6",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Şampun",
        "descr": "Vitamin,mineral duzlar və sarımsaq ekstratı ile zengindir. Xüsusi olaraq,cansız və zədəli saclar ücün nəzərdə tutulmuşdur.",
        "code": "Garlic Vita Mineral",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml.",
        "photo": "shampoo42.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fb75529d32f55081c0ba7",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Bərpaedici şampun",
        "descr": "Düyü proteinləri və E vitamini saçlara həcm və dolğun görünüş verir. Kimyəvi təsirə məruz qalmış və zəif saçlar üçün ideal vasitədir.",
        "code": "Reconstruction Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml",
        "photo": "shampoo36.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fb92a29d32f55081c0ba8",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Gündəlik Şampun",
        "descr": "Tərkibindəki yulaf vitaminləri saçları təmizləyir və fizioloji balansını pozmadan nəmləndirir. Jojoba yağı saçları möhkəmləndirir və qidalandırır. Saçlar daha parlaq, yumşaq görünüş alır. Günlük istifadə üçün idealdır.",
        "code": "Frequent Wash Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml",
        "photo": "shampoo37.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fb93e29d32f55081c0ba9",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Gündəlik Şampun",
        "descr": "Tərkibindəki yulaf vitaminləri saçları təmizləyir və fizioloji balansını pozmadan nəmləndirir. Jojoba yağı saçları möhkəmləndirir və qidalandırır. Saçlar daha parlaq, yumşaq görünüş alır. Günlük istifadə üçün idealdır.",
        "code": "Frequent Wash Shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml",
        "photo": "shampoo37.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fbe8729d32f55081c0baa",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Yeniləyici Şampun",
        "descr": "Lavanda və nanə tərkibli şampun günlük müalicə üçün ideal vasitədir. Lavandanın antiseptik komponentləri saç rəngini bərpa edir və saçı rahatladır. Nanə isə saçları canlandırır və yumşaqlıq verir.",
        "code": "Refreshing Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml",
        "photo": "shampoo38.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fbea129d32f55081c0bab",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Yeniləyici Şampun",
        "descr": "Lavanda və nanə tərkibli şampun günlük müalicə üçün ideal vasitədir. Lavandanın antiseptik komponentləri saç rəngini bərpa edir və saçı rahatladır. Nanə isə saçları canlandırır və yumşaqlıq verir.",
        "code": "Refreshing Shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml",
        "photo": "shampoo39.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fbfe729d32f55081c0bac",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Qidalandırıcı Şampun",
        "descr": "Gülümbahar və marakuya saçları qidalandırır və dərindən nəmləndirir. Gülümbaharın rahatlatıcı, qoruyucu xüsusiyyətləri saçın dərisini yeniləyir, rəngini və canlılığını bərpa edir. Marakuya ekstratı isə saçları nəmləndirir və yumşaqlıq verir.",
        "code": "Nourisihing Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml",
        "photo": "shampoo41.png",
        "__v": 0
    },
    {
        "_id": "5f5fbffd29d32f55081c0bad",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Qidalandırıcı Şampun",
        "descr": "Gülümbahar və marakuya saçları qidalandırır və dərindən nəmləndirir. Gülümbaharın rahatlatıcı, qoruyucu xüsusiyyətləri saçın dərisini yeniləyir, rəngini və canlılığını bərpa edir. Marakuya ekstratı isə saçları nəmləndirir və yumşaqlıq verir.",
        "code": "Nourisihing Shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml",
        "photo": "shampoo40.jpeg",
        "__v": 0
    },
    {
        "_id": "5f68a40819fdff1a747e8bd4",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Saç və bədən üçün qel",
        "descr": "Free Limix Sun Summer xüsusi tərkibilə günəşin bronzlaşma təsirini itirmədən dərini təmizləyir. Tərkibindəki mentol kristalları günəşin təsirinə məruz qalmış saç və bədəni təravətləndirir, təzələyir və enerji verir. Alantoin maddəsi öz antioksidant gücü ilə sərbəst radikallarla mübarizə aparır. Günəş, külək və duz nəticəsində yaranmış dəri zədələnmələrini nəmləndirir və bərpa edir.",
        "code": "Shower Gelsun",
        "madein": "Italiya",
        "price": 13,
        "size": "250ml",
        "photo": "shower1.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a81419fdff1a747e8bd6",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Saç Tökülməsinə qarşı şampun",
        "descr": "FreeLimix Grow şampun saç tökülməsinə qarşı effektiv məhsuldur. Onun zəngin elementlərdən ibarət formulu hər saç növü üçün tövsiyyə edilir və gündəlik istifadə üçün ideal vasitədir. Şampun, qan dövranını sürətləndirərək, dərinin fizioloji balansını yenidən bərpa edir və saçın təbii gücünü geri qaytarır. İstifadə qaydası: nəm saça tətbiq edin. Yaxalayın.",
        "code": "Grow Hair Loss",
        "madein": "Italiya",
        "price": 18,
        "size": "1000ml",
        "photo": "shampoo43.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a91919fdff1a747e8bd7",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Saç Tökülməsinə qarşı şampun",
        "descr": "FreeLimix Grow şampun saç tökülməsinə qarşı effektiv məhsuldur. Onun zəngin elementlərdən ibarət formulu hər saç növü üçün tövsiyyə edilir və gündəlik istifadə üçün ideal vasitədir. Şampun, qan dövranını sürətləndirərək, dərinin fizioloji balansını yenidən bərpa edir və saçın təbii gücünü geri qaytarır. İstifadə qaydası: nəm saça tətbiq edin. Yaxalayın.",
        "code": "Grow Hair Loss",
        "madein": "Italiya",
        "price": 12,
        "size": "250ml",
        "photo": "shampoo43.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a9ba19fdff1a747e8bd8",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Bio şampun",
        "descr": "Bio Struct şampunu zədələnmiş, cod və masaməli saçlar üçündür. Təbii qəhvəyi yosun və aqave nektarı ilə zəngin şampun, saç strukturunu yaxşılaşdırır, kapilyar lif dözümlülüyünü təmin edir və xarici təsirlərdən qoruyur. Saça parlaqlıq, nəmlilik verir və yeniləyir.İstifadə qaydası: dəsmalla qurulanmış saç boyu və uclara tətbiq edin. Darayıb 3-5 dəqiqə saxlayın. Yaxşı yaxalayın.",
        "code": "Bio Struct Shampoo",
        "madein": "Italiya",
        "price": 23.5,
        "size": "500ml",
        "photo": "shampoo44.jpeg",
        "__v": 0
    },
    {
        "_id": "5f68aa8e19fdff1a747e8bd9",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Dolğu şampunu",
        "descr": "Botutech şampun nazik və cansız saçlara dolğu effekti verir. Tərkibindəki hialuron turşusu, keramidlər və üzüm sapı elementləri saçları yumşaldır və həcm verir. Nazik saç tellərini ağırlaşdırmadan nəmləndirir və qoruyur. Şampundan müntəzəm istifadə nəticəsində saçlar yumşaqlıq, elastiklik və təbii parlaqlıq qazanır. Optimal nəticə üçün şampunu digər Botutech məhsulları ilə birgə istifadə etmək tövsiyyə olunur. İstifadə qaydası: məhsulu nəm saça tətbiq edin və durulayın.",
        "code": "Botutech Filler Shampoo",
        "madein": "Italiya",
        "price": 16,
        "size": "250ml",
        "photo": "shampoo45.jpeg",
        "__v": 0
    },
    {
        "_id": "5f68aad819fdff1a747e8bda",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Dolğu şampunu",
        "descr": "Botutech şampun nazik və cansız saçlara dolğu effekti verir. Tərkibindəki hialuron turşusu, keramidlər və üzüm sapı elementləri saçları yumşaldır və həcm verir. Nazik saç tellərini ağırlaşdırmadan nəmləndirir və qoruyur. Şampundan müntəzəm istifadə nəticəsində saçlar yumşaqlıq, elastiklik və təbii parlaqlıq qazanır. Optimal nəticə üçün şampunu digər Botutech məhsulları ilə birgə istifadə etmək tövsiyyə olunur. İstifadə qaydası: məhsulu nəm saça tətbiq edin və durulayın.",
        "code": "Botutech Filler Shampoo",
        "madein": "Italiya",
        "price": 25,
        "size": "1000ml",
        "photo": "shampoo46.jpeg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          style={1} prodtype={this.state.products[a].subcat}
          descr={this.state.products[a].code + " " + this.state.products[a].size}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Şampunlar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
