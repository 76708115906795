import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class BarberChairs extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f51239ca693e7417c40aefe",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Ares",
        "madein": "Türkiyə",
        "photo": "ba01.jpg",
        "__v": 0
    },
    {
        "_id": "5f51244ba693e7417c40aeff",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Boss",
        "madein": "Türkiyə",
        "photo": "ba02.jpg",
        "__v": 0
    },
    {
        "_id": "5f5124cba693e7417c40af00",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Champion",
        "madein": "Türkiyə",
        "photo": "ba03.jpg",
        "__v": 0
    },
    {
        "_id": "5f512532a693e7417c40af01",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Hercule",
        "madein": "Türkiyə",
        "photo": "ba04.JPG",
        "__v": 0
    },
    {
        "_id": "5f5125d6a693e7417c40af02",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Hero",
        "madein": "Türkiyə",
        "photo": "ba05.jpg",
        "__v": 0
    },
    {
        "_id": "5f512632a693e7417c40af03",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Leo",
        "madein": "Türkiyə",
        "photo": "ba06.jpg",
        "__v": 0
    },
    {
        "_id": "5f5126a1a693e7417c40af04",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Royal",
        "madein": "Türkiyə",
        "photo": "ba07.jpg",
        "__v": 0
    },
    {
        "_id": "5f512712a693e7417c40af05",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Cosmo",
        "madein": "Türkiyə",
        "photo": "ba08.jpg",
        "__v": 0
    },
    {
        "_id": "5f5127e6a693e7417c40af06",
        "brand": "Maletti",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Zeus Easy",
        "madein": "Italiya",
        "photo": "ba09.jpg",
        "__v": 0
    },
    {
        "_id": "5f5128daa693e7417c40af07",
        "brand": "Maletti",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Sigma",
        "madein": "Italiya",
        "photo": "ba10.jpg",
        "__v": 0
    },
    {
        "_id": "5f51293ba693e7417c40af08",
        "brand": "Ceriotti",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Royal",
        "madein": "Italiya",
        "photo": "ba11.jpg",
        "__v": 0
    },
    {
        "_id": "5f5129aca693e7417c40af09",
        "brand": "Maletti",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Zerbini 1906 Limited Edition",
        "madein": "Italiya",
        "photo": "ba12.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Bərbər Qoltuqları'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
