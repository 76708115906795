import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Slideshow from './Slideshow.js'
import Footer from './Footer'
import Slider from "react-slick";
import Salon1 from './images/salon7.jpg'
import Salon2 from './images/salon11.jpg'
import Salon3 from './images/salon9.jpg'
import Salon4 from './images/salon10.jpg'
import Salon5 from './images/salon12.jpg'
import Salon6 from './images/salon13.jpg'
import Salon7 from './images/salon14.jpg'
import Salon8 from './images/salon15.jpg'
import Salon9 from './images/salon16.jpg'
import Salon10 from './images/salon17.jpg'
import Salon11 from './images/salon18.jpg'
import Salon12 from './images/salon19.jpg'
import Salon13 from './images/salon20.jpg'
import Salon14 from './images/salon21.jpg'
import Salon15 from './images/salon22.jpg'
import Salon16 from './images/salon24.jpg'
import Salon17 from './images/salon26.jpg'
import Salon18 from './images/salon27.jpg'
import Salon19 from './images/salon28.jpg'
import Salon20 from './images/salon29.jpg'
import './Mainpage.css'

export default class Mainpage extends Component{
  render() {


    return (
      <div className="App">
        <Navbar/>
        <Lowernav/>
        <Slideshow style={{width: '100%'}}/>
        <div style={{height: '3rem'}}>
        </div>
        <div style={{width: '70%', margin: 'auto'}}>
        <h4 className="CatHeader"> Haqqımızda </h4>
        <p> Firmamız 1995ci ildən Antalya mağazası kimi Bakı şəhərində fəaliyyətə başlamışdır.
          Həmin dövrdən başlayaraq Azərbaycan Respublikası ərazisində,
          Rusiya və Gürcüstanda açılan bir çox bərbər, gözəllik salonları və SPA
          mərkəzləri üçün avadanlıq və ləvazimatların satışını təşkil etmisdir.
          2010cu ildən yeni ünvanda açılan mağazamız Aksa Cosmetics adı ilə
          yenidən təqdim edilmişdir.
          <br></br>25 illik fəaliyyəti dövründə, firmamız Türkiyənin və Avropanın aparıcı
          firmaları tərəfindən etibarlı partnyor kimi qəbul edilmiş və həmin firmaların Azərbaycan
          Respulikası ərazisində distribütorluğunu hayata keçirmişdir. Hazırda Aksa Cosmetics
          mağazasında Türkiyə Respublikasının Alpeda, İtaliyanın Maletti, Ceriotti, Kiepe, Xanitalia, Kaaral, FreeLimix, Janeke və Maestri
          firmalarının, o cümlədən Çin Xalq Respublikasının Silver Fox firmasının malları satılır.
          İstehsal olunduğu ölkələrdən asılı olmayaraq, müştərilərə təqdim olunan mallar Avropa və dünya
          standartlarına cavab verməkdədir. Əsas məqsədimiz Azərbaycan bazarında yeni açılan və
          fəaliyyət göstərən bərbər, gözəllik salonlarını və SPA mərkəzlərini keyfiyyətli
          avandanlıqlar, ləvazimatlar və gigiyenik vasitələrlə təmin etməkdir. Eyni zamanda,
          fərdi müştərilərimiz üçün geniş çeşiddə mallarımız mövcüddur. </p>
        </div>
        <div style={{height: '3rem'}}>
        </div>
        <div className="BrandList" style={{width: '100%'}}>
          <h4 className="CatHeader"> Salonlarımız </h4>
          <Slider style={{width: '100%'}} dots={false} infinite={true} arrows={true} autoplay={true} speed={5000} slidesToShow={3}>
            <div>
              <img className="SalonImage" src={Salon1} alt="Salonlarımız"/>
            </div>
            <div>
              <img className="SalonImage" src={Salon2} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon3} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon4} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon5} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon6} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon7} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon8} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon9} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon10} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon11} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon12} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon13} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon14} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon15} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon16} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon17} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon18} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon19} alt="Salonlarımız" />
            </div>
            <div>
              <img className="SalonImage" src={Salon20} alt="Salonlarımız" />
            </div>
          </Slider>
        </div>

        <Footer upperFooter={true}/>
      </div>
    );
  }
}
