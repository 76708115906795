import React, { Component } from "react";
import './ThirdHeader.css'
export default class ThirdHeader extends Component{
  render() {

    return (
      <div className="ThirdHeader">
        <div className="inner">
          <h3 className="ThirdHw">{this.props.cat}</h3>
        </div>
      </div>
    );
  }
}
