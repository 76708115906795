import React, { Component } from "react";
import Menu, { SubMenu, MenuItem} from 'rc-menu';
import 'rc-menu/assets/index.css';
import './Lowernav.css'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Lowernav extends Component{
  constructor() {
    super();

    this.state = {
      height: 0,
      width: 0,
      sizeMounted: false,
      menuOn: false
    };

    window.addEventListener("resize", this.update);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.update();
    this.setState({
      sizeMounted: true
    });
  }

  update = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  };

  handleClick() {
    this.setState(state => ({
      menuOn: !state.menuOn
    }));
  }

  render() {
    if(this.state.sizeMounted && this.state.width> 1500){
      return (
        <div className="Lowernav"  style={{backgroundColor:'#f3f5f7', fontFaimly: 'Oswald'}}>
        <Menu className="LowerMenu" mode="horizontal" overflowedIndicator=""  openAnimation="zoom" style={{color:'black',margin: 'auto', width: '80%', maxWidth: '100%'}}>
            <SubMenu style={{ fontWeight: '500', fontSize: 12.5, marginLeft: 'auto'}} title='MEBELLƏR'>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  color: 'black'}}><a style={{color: 'black'}} href='/WeomanF'>QADIN MEBELLƏRİ</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/ManF'>KİŞİ MEBELLƏRİ</a></MenuItem>
            </SubMenu>
            <SubMenu style={{ fontWeight: '500', fontSize: 12.5,  }} title="SAÇ YUMA DƏSTLƏRİ">
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/HairWashingUnit'>İTALİYA İSTEHSALI</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/HairWashingTur'>TÜRKİYƏ İSTEHSALI</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/HairWashOther'>DİGƏR</a></MenuItem>
            </SubMenu>
            <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="QOLTUQLAR">
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/WomanChairs'>QADIN QOLTUQLARI</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/BarberChairs'>BƏRBƏR QOLTUQLARI</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/Stools'>STULLAR</a></MenuItem>
            </SubMenu>
            <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="SPA">
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/CosmeticBeds'>KOSMETOLOJİ YATAQLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/MassageBeds'>MASAJ YATAQLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Pedic'>PEDİKÜR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/ManicTables'>MANİKÜR</a></MenuItem>
            </SubMenu>
            <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="ELEKTRİKLƏR">
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/Stationaries'>STASİONAR FENLƏR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Hairdryers'>HAVA FENLƏRİ</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Vaporisator'>VAPORİZATORLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/CurDryers'>ÜTÜ VƏ SAÇ BURMA ALƏTLƏRİ</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Processors'>KLİMAZONLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Sterilizator'>STERİLİZATORLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Lamps'>LAMPALAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Nail'>DIRNAQ APARATLARI</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Parafins'>PARAFİN VƏ MUM APARATALARI</a></MenuItem>
            </SubMenu>
            <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="ARABALAR">
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/PaintTrollers'>BƏRBƏR ARABALARI</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/CosmeticTroller'>KOSMETOLOJİ ARABALAR</a></MenuItem>
            </SubMenu>
            <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="SAÇA QULLUQ">
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/Shampoos'>ŞAMPUNLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Masks'>MASKALAR VƏ LOSYONLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Balsams'>BALZAMLAR VƏ KONDİSİONERLƏR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Paints'>BOYALAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Stylings'>STYLİNG VASİTƏLƏRİ</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Men'>KİŞİLƏR ÜÇÜN</a></MenuItem>
            </SubMenu>
            <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="ÜZƏ VƏ BƏDƏNƏ QULLUQ">
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/Depilation'>DEPİLYASİYA VASİTƏLƏRİ</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/MassageOil'>MASAJ YAĞLARI</a></MenuItem>
            </SubMenu>
            <SubMenu style={{fontWeight: '500', fontSize: 12.5, marginRight: 'auto' }} title="BƏRBƏR LƏVAZİMATLARI">
              <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/Scissors'>QAYÇILAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Clippers'>SAÇ VƏ ÜZ QIRXAN MAŞINLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Combs'>DARAQLAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Brushes'>FIRÇALAR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Apron'>ÖNLÜKLƏR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Mirror'>GÜZGÜLƏR</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/ManicPedicTools'>MANİKÜR VƏ PEDİKÜR ALƏTLƏRİ</a></MenuItem>
              <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Others'>DİGƏR</a></MenuItem>
            </SubMenu>


          </Menu>
        </div>
        );
      }
      else{
        return (
          <div className="Lowernav"  style={{backgroundColor:'white', fontFaimly: 'Oswald', width: '100%'}}>
            <button  className="barButton" onClick={this.handleClick}>
              <FontAwesomeIcon icon={faBars} style={{color: 'black', marginLeft: '2%'}}/>
            </button>
            {this.state.menuOn &&
              <Menu className="LowerMenu" mode="vertical-right" overflowedIndicator="Bla"  openAnimation="zoom" style={{color:'black',marginBottom: '1%', marginLeft: '1%', marginTop: '1%', width: '90%', maxWidth: '100%'}}>
              <SubMenu style={{ fontWeight: '500', fontSize: 12.5, marginLeft: 'auto'}} title='MEBELLƏR'>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  color: 'black'}}><a style={{color: 'black'}} href='/WeomanF'>QADIN MEBELLƏRİ</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/ManF'>KİŞİ MEBELLƏRİ</a></MenuItem>
              </SubMenu>
              <SubMenu style={{ fontWeight: '500', fontSize: 12.5,  }} title="SAÇ YUMA DƏSTLƏRİ">
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/HairWashingUnit'>İTALİYA İSTEHSALI</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/HairWashingTur'>TÜRKİYƏ İSTEHSALI</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/HairWashOther'>DİGƏR</a></MenuItem>
              </SubMenu>
              <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="QOLTUQLAR">
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/WomanChairs'>QADIN QOLTUQLARI</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/BarberChairs'>BƏRBƏR QOLTUQLARI</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  color: 'black'}}><a style={{color: 'black'}} href='/Stools'>STULLAR</a></MenuItem>
              </SubMenu>
              <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="SPA">
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/CosmeticBeds'>KOSMETOLOJİ YATAQLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/MassageBeds'>MASAJ YATAQLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Pedic'>PEDİKÜR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/ManicTables'>MANİKÜR</a></MenuItem>
              </SubMenu>
              <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="ELEKTRİKLƏR">
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/Stationaries'>STASİONAR FENLƏR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Hairdryers'>HAVA FENLƏRİ</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Vaporisator'>VAPORİZATORLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/CurDryers'>ÜTÜ VƏ SAÇ BURMA ALƏTLƏRİ</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Processors'>KLİMAZONLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Sterilizator'>STERİLİZATORLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Lamps'>LAMPALAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Nail'>DIRNAQ APARATLARI</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Parafins'>PARAFİN VƏ MUM APARATALARI</a></MenuItem>
              </SubMenu>
              <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="ARABALAR">
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/PaintTrollers'>BƏRBƏR ARABALARI</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/CosmeticTroller'>KOSMETOLOJİ ARABALAR</a></MenuItem>
              </SubMenu>
              <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="SAÇA QULLUQ">
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/Shampoos'>ŞAMPUNLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Masks'>MASKALAR VƏ LOSYONLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Balsams'>BALZAMLAR VƏ KONDİSİONERLƏR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Paints'>BOYALAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Stylings'>STYLİNG VASİTƏLƏRİ</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Men'>KİŞİLƏR ÜÇÜN</a></MenuItem>
              </SubMenu>
              <SubMenu style={{fontWeight: '500', fontSize: 12.5,  }} title="ÜZƏ VƏ BƏDƏNƏ QULLUQ">
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/Depilation'>DEPİLYASİYA VASİTƏLƏRİ</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/MassageOil'>MASAJ YAĞLARI</a></MenuItem>
              </SubMenu>
              <SubMenu style={{fontWeight: '500', fontSize: 12.5, marginRight: 'auto' }} title="BƏRBƏR LƏVAZİMATLARI">
                <MenuItem style={{fontWeight: '500', fontSize: 12.5,  }}><a style={{color: 'black'}} href='/Scissors'>QAYÇILAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Clippers'>SAÇ VƏ ÜZ QIRXAN MAŞINLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Combs'>DARAQLAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Brushes'>FIRÇALAR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Apron'>ÖNLÜKLƏR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Mirror'>GÜZGÜLƏR</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/ManicPedicTools'>MANİKÜR VƏ PEDİKÜR ALƏTLƏRİ</a></MenuItem>
                <MenuItem style={{fontWeight: '500', fontSize: 12.5, marginTop: '-5%',  }}><a style={{color: 'black'}} href='/Others'>DİGƏR</a></MenuItem>
              </SubMenu>


            </Menu>
            }
          </div>
          );
      }

  }
}
