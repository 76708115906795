import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class MassageBeds extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f58db2521ef2c36dc83b428",
        "brand": "Silver Fox",
        "category": 44,
        "subcat": "Masaj Yatağı",
        "code": "60",
        "madein": "Çin",
        "photo": "yataq01.jpg",
        "__v": 0
    },
    {
        "_id": "5f58db3a21ef2c36dc83b429",
        "brand": "Silver Fox",
        "category": 44,
        "subcat": "Portativ Masaj Yatağı",
        "code": "09B",
        "madein": "Çin",
        "photo": "yataq02.jpg",
        "__v": 0
    },
    {
        "_id": "5f58db4f21ef2c36dc83b42a",
        "brand": "Silver Fox",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "10A",
        "madein": "Çin",
        "photo": "yataq03.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dbb121ef2c36dc83b42b",
        "brand": "Silver Fox",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "47T",
        "madein": "Çin",
        "photo": "yataq04.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dc2521ef2c36dc83b42c",
        "brand": " ",
        "category": 44,
        "subcat": "Portativ Masaj Yatağı",
        "code": "Daisy",
        "madein": "Italiya",
        "photo": "yataq10.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dc3621ef2c36dc83b42d",
        "brand": " ",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "Amalfi",
        "madein": "Italiya",
        "photo": "yataq11.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dc4821ef2c36dc83b42e",
        "brand": " ",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "Harmony",
        "madein": "Italiya",
        "photo": "yataq12.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dc6421ef2c36dc83b42f",
        "brand": " ",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "King Round",
        "madein": "Italiya",
        "photo": "yataq13.jpeg",
        "__v": 0
    }
],
      loaded: true
    };
  }

  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo} loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Masaj Yataqları'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
