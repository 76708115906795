import React, { Component } from "react";
import './ProductCard.css'
import ModalImage from "react-modal-image";

export default class ProductCard extends Component{
  render() {

    return (
      <div className="prodCard">
      <ModalImage
        className='ProdImage'
        small={this.props.image}
        large={this.props.image}
        hideDownload={true}
        hideZoom={true}
        alt={this.props.name+ " " + this.props.code}
      />
        <div className="prodInfo">
          { (!this.props.size && this.props.category!=21 && this.props.category!=22 && this.props.category!=31 && this.props.category!=32 && this.props.category!=33) &&
            <h3 className='ProdName'> {this.props.name+ " " + this.props.code} </h3>
          }
          { (this.props.size && this.props.category!=43 && this.props.category!=62) &&
            <h3 className='ProdName'> {this.props.name+ " " + this.props.code+ " " + this.props.size} </h3>
          }
          { (this.props.category==43 || this.props.category==62) &&
            <h3 className='ProdName'> {this.props.name+ " " + this.props.code} </h3>
          }
          { (this.props.category==21 || this.props.category==22 || this.props.category==31 || this.props.category==32 || this.props.category==33) &&
            <h3 className='ProdName'> {this.props.subcat+ " " + this.props.code} </h3>
          }
          { (this.props.category!=21 && this.props.category!=22 && this.props.category!=31 && this.props.category!=32 && this.props.category!=33) &&
            <p className='prodDescr'> {this.props.subcat}</p>
          }

          <p className='prodDescr'> Istehsalçı ölkə: {this.props.madein}</p>
          { this.props.power &&
            <p className='prodDescr'> Güc: {this.props.power}</p>
          }
          { this.props.color &&
            <p className='prodDescr'> Rəngi: {this.props.color}</p>
          }
          { this.props.heat &&
            <p className='prodDescr'> Dərəcə: {this.props.heat}°C</p>
          }
          { this.props.material &&
            <p className='prodDescr'> Material: {this.props.material}</p>
          }
          { this.props.size &&
            <p className='prodDescr'> Ölçü: {this.props.size}</p>
          }
          { this.props.descr &&
            <p className='prodDescr'> {this.props.descr}</p>
          }
          { this.props.price &&
            <p className='buyDescr' style={{whiteSpace: "pre-line"}}> {this.props.price}₼ </p>
          }
          { !this.props.price &&
            <p className='buyDescr'> Təklif al </p>
          }

        </div>

      </div>
    );
  }
}
