import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Combs extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f4ce9be95919438c44b3375",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0809",
        "madein": "Italiya",
        "price": 6.3,
        "photo": "daraq50.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce9de95919438c44b3376",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0810",
        "madein": "Italiya",
        "price": 10.4,
        "photo": "daraq51.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceb1195919438c44b3377",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0805",
        "madein": "Italiya",
        "price": 6.3,
        "photo": "daraq52.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceb8b95919438c44b3378",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0825",
        "madein": "Italiya",
        "price": 6.3,
        "photo": "daraq53.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceba195919438c44b3379",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0828",
        "madein": "Italiya",
        "price": 7.7,
        "photo": "daraq54.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cebc595919438c44b337a",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0804",
        "madein": "Italiya",
        "price": 6.3,
        "photo": "daraq55.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ced6295919438c44b3380",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "511",
        "madein": "Italiya",
        "price": 7,
        "photo": "daraq61.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cedc295919438c44b3381",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "517",
        "madein": "Italiya",
        "price": 7.3,
        "photo": "daraq62.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cedde95919438c44b3382",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "519",
        "madein": "Italiya",
        "price": 7,
        "photo": "daraq63.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cedff95919438c44b3383",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "541",
        "madein": "Italiya",
        "price": 4.9,
        "photo": "daraq64.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cee1f95919438c44b3384",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "543",
        "madein": "Italiya",
        "price": 4.9,
        "photo": "daraq65.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cee2d95919438c44b3385",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "545",
        "madein": "Italiya",
        "price": 5.3,
        "photo": "daraq66.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cee4295919438c44b3386",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55800",
        "madein": "Italiya",
        "price": 16.3,
        "photo": "daraq67.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceec495919438c44b3387",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55803",
        "madein": "Italiya",
        "price": 15.9,
        "photo": "daraq68.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceee295919438c44b3388",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55804",
        "madein": "Italiya",
        "price": 13.3,
        "photo": "daraq69.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cef0c95919438c44b3389",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55813",
        "madein": "Italiya",
        "price": 11.1,
        "photo": "daraq70.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cef1b95919438c44b338a",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55814",
        "madein": "Italiya",
        "price": 14.6,
        "photo": "daraq71.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cef3595919438c44b338b",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55819",
        "madein": "Italiya",
        "price": 14.1,
        "photo": "daraq72.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cef8f95919438c44b338c",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55820",
        "madein": "Italiya",
        "price": 13.3,
        "photo": "daraq73.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cefa295919438c44b338d",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55825",
        "madein": "Italiya",
        "price": 18.8,
        "photo": "daraq74.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cefb395919438c44b338e",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55862",
        "madein": "Italiya",
        "price": 14.1,
        "photo": "daraq75.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cefc395919438c44b338f",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55871",
        "madein": "Italiya",
        "price": 16.9,
        "photo": "daraq76.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf0d495919438c44b3393",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "80825V MAC",
        "madein": "Italiya",
        "price": 25.3,
        "photo": "daraq80.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf36d95919438c44b339c",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "LG 360",
        "madein": "Italiya",
        "price": 29.6,
        "photo": "daraq89.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }

  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Daraqlar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
