export const products = [
    {
        "_id": "5f4964e4de4d2a0ed85461d0",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "descr": "Italiya istehsalı saç kəsim qayçısı",
        "code": "266",
        "madein": "Italiya",
        "price": 102,
        "color": "Qızılı(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci01.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4969b8b0fb9e0ae03ade27",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı filirovka",
        "code": "2259",
        "madein": "Italiya",
        "price": 86.9,
        "color": "Gümüşü(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci02.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4a07a0202e45028c59f5ef",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "244",
        "madein": "Italiya",
        "price": 71.8,
        "color": "Gümüşü(Mat)",
        "size": "5.0\"",
        "photo": "qayci03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a07b1202e45028c59f5f0",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "244",
        "madein": "Italiya",
        "price": 75,
        "color": "Gümüşü(Mat)",
        "size": "5.5\"",
        "photo": "qayci03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a07ce202e45028c59f5f1",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "244",
        "madein": "Italiya",
        "price": 78.4,
        "color": "Gümüşü(Mat)",
        "size": "6.0\"",
        "photo": "qayci03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a08fd202e45028c59f5f2",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı filirovka",
        "code": "221",
        "madein": "Italiya",
        "price": 218.7,
        "color": "Gümüşü(Parlaq)",
        "size": "6.0\"",
        "photo": "qayci04.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a09f3202e45028c59f5f3",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "217",
        "madein": "Italiya",
        "price": 450,
        "color": "Qara(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci05.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a0aef202e45028c59f5f4",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "255",
        "madein": "Italiya",
        "price": 109.4,
        "color": "Gümüşü(Parlaq)",
        "size": "5.0\"",
        "photo": "qayci06.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4a0e97202e45028c59f5f5",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2438",
        "madein": "Italiya",
        "price": 87.5,
        "color": "Qırmızı/Ağ(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci07.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a0ea3202e45028c59f5f6",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "257",
        "madein": "Italiya",
        "price": 100.6,
        "color": "Gümüşü(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci08.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a0f15202e45028c59f5f7",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "260",
        "madein": "Italiya",
        "price": 106.9,
        "color": "Gümüşü(Parlaq)",
        "size": "5.0\"",
        "photo": "qayci09.png",
        "__v": 0
    },
    {
        "_id": "5f4a0f4f202e45028c59f5f8",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı filirovka",
        "code": "272",
        "madein": "Italiya",
        "price": 45.9,
        "color": "Gümüşü(Mat)",
        "size": "6.5\"",
        "photo": "qayci10.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a0fc3202e45028c59f5f9",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı filirovka",
        "code": "273",
        "madein": "Italiya",
        "price": 72.6,
        "color": "Gümüşü(Mat)",
        "size": "5.5\"",
        "photo": "qayci11.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1009202e45028c59f5fa",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "278",
        "madein": "Italiya",
        "price": 43,
        "color": "Gümüşü(Mat)",
        "size": "5.5\"",
        "photo": "qayci12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a101e202e45028c59f5fb",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "278",
        "madein": "Italiya",
        "price": 44.5,
        "color": "Gümüşü(Mat)",
        "size": "6.0\"",
        "photo": "qayci12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1029202e45028c59f5fc",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "278",
        "madein": "Italiya",
        "price": 45.5,
        "color": "Gümüşü(Mat)",
        "size": "6.5\"",
        "photo": "qayci12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1084202e45028c59f5fd",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2115",
        "madein": "Italiya",
        "price": 19.8,
        "color": "Tutacaq Hissəsi Göy Plastik / Metal Hissəsi Gümüşü(Mat)",
        "size": "5.5\"",
        "photo": "qayci13.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1169202e45028c59f5fe",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2115",
        "madein": "Italiya",
        "price": 19.8,
        "color": "Tutacaq Hissəsi Göy Plastik / Metal Hissəsi Gümüşü(Mat)",
        "size": "6.0\"",
        "photo": "qayci13.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a119d202e45028c59f5ff",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2127",
        "madein": "Italiya",
        "price": 31.8,
        "color": "Gümüşü(Mat)",
        "size": "4.5\"",
        "photo": "qayci14.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a11ce202e45028c59f600",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2127",
        "madein": "Italiya",
        "price": 33.4,
        "color": "Gümüşü(Mat)",
        "size": "5.0\"",
        "photo": "qayci14.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a11dc202e45028c59f601",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2127",
        "madein": "Italiya",
        "price": 35.7,
        "color": "Gümüşü(Mat)",
        "size": "6.5\"",
        "photo": "qayci14.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1227202e45028c59f602",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "282",
        "madein": "Italiya",
        "price": 161.4,
        "color": "Gümüşü(Parlaq)",
        "size": "5.5\"",
        "photo": "qayci15.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a125a202e45028c59f603",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2260",
        "madein": "Italiya",
        "price": 64.8,
        "color": "Gümüşü(Parlaq)",
        "size": "5.0\"",
        "photo": "qayci16.jpg",
        "__v": 0
    },
    {
        "_id": "5f4a1267202e45028c59f604",
        "brand": "Kiepe",
        "category": 91,
        "subcat": "Saç qayçısı",
        "code": "2260",
        "madein": "Italiya",
        "price": 69.4,
        "color": "Gümüşü(Parlaq)",
        "size": "6.0\"",
        "photo": "qayci16.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ccf6195919438c44b333a",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Easy",
        "madein": "Italiya",
        "price": 400,
        "photo": "troller01.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccf7a95919438c44b333b",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Be One",
        "madein": "Italiya",
        "price": 390,
        "photo": "troller02.jpeg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccf8c95919438c44b333c",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Jazz",
        "madein": "Italiya",
        "price": 300,
        "photo": "troller03.png",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccfb595919438c44b333d",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Ciak",
        "madein": "Italiya",
        "price": 480,
        "photo": "troller04.png",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccfde95919438c44b333e",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Ciak Duo",
        "madein": "Italiya",
        "price": 580,
        "photo": "troller05.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4ccffa95919438c44b333f",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Oscar",
        "madein": "Italiya",
        "price": 360,
        "photo": "troller06.png",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4cd02295919438c44b3340",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Surf",
        "madein": "Italiya",
        "price": 360,
        "photo": "troller07.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4cd04295919438c44b3341",
        "brand": " ",
        "category": 61,
        "subcat": "Bərbər Trolleri",
        "code": "Taxi",
        "madein": "Italiya",
        "price": 520,
        "photo": "troller08.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4cd14795919438c44b3343",
        "brand": " ",
        "category": 61,
        "subcat": "Boya Trolleri",
        "code": "Service Plus",
        "madein": "Italiya",
        "price": 280,
        "photo": "troller10.jpg",
        "material": "Plastik",
        "__v": 0
    },
    {
        "_id": "5f4cdba895919438c44b3344",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1401",
        "madein": "Italiya",
        "price": 14.2,
        "photo": "daraq01.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cdbbf95919438c44b3345",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1402",
        "madein": "Italiya",
        "price": 14.2,
        "photo": "daraq02.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cdbca95919438c44b3346",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1403",
        "madein": "Italiya",
        "price": 17.6,
        "photo": "daraq03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cde3495919438c44b3347",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1404",
        "madein": "Italiya",
        "price": 17.6,
        "photo": "daraq04.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cde6595919438c44b3348",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1406",
        "madein": "Italiya",
        "price": 10.3,
        "photo": "daraq05.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cde7995919438c44b3349",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1407",
        "madein": "Italiya",
        "price": 10.3,
        "photo": "daraq06.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cde9495919438c44b334a",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1408",
        "madein": "Italiya",
        "price": 13.6,
        "photo": "daraq07.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cdee595919438c44b334b",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14101",
        "madein": "Italiya",
        "price": 25.1,
        "photo": "daraq08.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce06195919438c44b334c",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1413",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq09.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce06e95919438c44b334d",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1414",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq10.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce08695919438c44b334e",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1415",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq11.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce0c595919438c44b334f",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1430",
        "madein": "Italiya",
        "price": 26.3,
        "photo": "daraq12.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce0ef95919438c44b3350",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14301",
        "madein": "Italiya",
        "price": 29.4,
        "photo": "daraq13.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce10795919438c44b3351",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14302",
        "madein": "Italiya",
        "price": 36.9,
        "photo": "daraq14.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce14295919438c44b3352",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14478",
        "madein": "Italiya",
        "price": 33.9,
        "photo": "daraq15.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce17795919438c44b3353",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14488",
        "madein": "Italiya",
        "price": 38.1,
        "photo": "daraq16.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce19295919438c44b3354",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14498",
        "madein": "Italiya",
        "price": 42.4,
        "photo": "daraq17.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce1a895919438c44b3355",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1405",
        "madein": "Italiya",
        "price": 25.9,
        "photo": "daraq18.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce1c695919438c44b3356",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1411",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq19.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce1d795919438c44b3357",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1412",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq20.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce1f095919438c44b3358",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2901",
        "madein": "Italiya",
        "price": 13.4,
        "photo": "daraq21.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce21795919438c44b3359",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2902",
        "madein": "Italiya",
        "price": 13.4,
        "photo": "daraq22.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce23795919438c44b335a",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2903",
        "madein": "Italiya",
        "price": 16,
        "photo": "daraq23.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce27b95919438c44b335b",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2904",
        "madein": "Italiya",
        "price": 16,
        "photo": "daraq24.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce29195919438c44b335c",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2905",
        "madein": "Italiya",
        "price": 22.2,
        "photo": "daraq25.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce2af95919438c44b335d",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2906",
        "madein": "Italiya",
        "price": 28.1,
        "photo": "daraq26.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce2da95919438c44b335e",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2907",
        "madein": "Italiya",
        "price": 35.1,
        "photo": "daraq27.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce2f495919438c44b335f",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2014",
        "madein": "Italiya",
        "price": 9.4,
        "photo": "daraq28.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce30c95919438c44b3360",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2015",
        "madein": "Italiya",
        "price": 9.4,
        "photo": "daraq29.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce32995919438c44b3361",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2201",
        "madein": "Italiya",
        "price": 19.7,
        "photo": "daraq30.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce35b95919438c44b3362",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2202",
        "madein": "Italiya",
        "price": 20.3,
        "photo": "daraq31.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce37f95919438c44b3363",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2203",
        "madein": "Italiya",
        "price": 21.2,
        "photo": "daraq32.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce3c295919438c44b3364",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2204",
        "madein": "Italiya",
        "price": 26,
        "photo": "daraq33.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce3db95919438c44b3365",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2205",
        "madein": "Italiya",
        "price": 27.8,
        "photo": "daraq34.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce41095919438c44b3366",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "3203",
        "madein": "Italiya",
        "price": 16.6,
        "photo": "daraq35.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce42995919438c44b3367",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "3205",
        "madein": "Italiya",
        "price": 21.6,
        "photo": "daraq36.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce45695919438c44b3368",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "3206",
        "madein": "Italiya",
        "price": 28,
        "photo": "daraq37.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce46d95919438c44b3369",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "3492",
        "madein": "Italiya",
        "price": 28.7,
        "photo": "daraq38.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce47e95919438c44b336a",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Qaynaqlı Saçlar üçün Fırça Daraq",
        "code": "3420",
        "madein": "Italiya",
        "price": 32.8,
        "photo": "daraq39.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce4aa95919438c44b336b",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "3445",
        "madein": "Italiya",
        "price": 24.4,
        "photo": "daraq40.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce57c95919438c44b336c",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "2555",
        "madein": "Italiya",
        "price": 37.5,
        "photo": "daraq41.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce59495919438c44b336d",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "2556",
        "madein": "Italiya",
        "price": 39.6,
        "photo": "daraq42.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5ab95919438c44b336e",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "2557",
        "madein": "Italiya",
        "price": 40.1,
        "photo": "daraq43.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5ba95919438c44b336f",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "2558",
        "madein": "Italiya",
        "price": 42,
        "photo": "daraq44.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5d395919438c44b3370",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2601",
        "madein": "Italiya",
        "price": 21,
        "photo": "daraq45.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5e895919438c44b3371",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2602",
        "madein": "Italiya",
        "price": 22.2,
        "photo": "daraq46.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5fc95919438c44b3372",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2603",
        "madein": "Italiya",
        "price": 23.4,
        "photo": "daraq47.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce62395919438c44b3373",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2604",
        "madein": "Italiya",
        "price": 24.4,
        "photo": "daraq48.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce63395919438c44b3374",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2605",
        "madein": "Italiya",
        "price": 28,
        "photo": "daraq49.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce9be95919438c44b3375",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0809",
        "madein": "Italiya",
        "price": 6.3,
        "photo": "daraq50.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce9de95919438c44b3376",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0810",
        "madein": "Italiya",
        "price": 10.4,
        "photo": "daraq51.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceb1195919438c44b3377",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0805",
        "madein": "Italiya",
        "price": 6.3,
        "photo": "daraq52.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceb8b95919438c44b3378",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0825",
        "madein": "Italiya",
        "price": 6.3,
        "photo": "daraq53.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceba195919438c44b3379",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0828",
        "madein": "Italiya",
        "price": 7.7,
        "photo": "daraq54.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cebc595919438c44b337a",
        "brand": "Maestri",
        "category": 93,
        "subcat": "Daraq",
        "code": "0804",
        "madein": "Italiya",
        "price": 6.3,
        "photo": "daraq55.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cec2c95919438c44b337b",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5720",
        "madein": "Italiya",
        "price": 15.6,
        "photo": "daraq56.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cec9f95919438c44b337c",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5725",
        "madein": "Italiya",
        "price": 15.8,
        "photo": "daraq57.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ced0895919438c44b337d",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5733",
        "madein": "Italiya",
        "price": 16.7,
        "photo": "daraq58.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ced2095919438c44b337e",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5743",
        "madein": "Italiya",
        "price": 19,
        "photo": "daraq59.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ced4695919438c44b337f",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5545",
        "madein": "Italiya",
        "price": 16.3,
        "photo": "daraq60.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ced6295919438c44b3380",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "511",
        "madein": "Italiya",
        "price": 7,
        "photo": "daraq61.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cedc295919438c44b3381",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "517",
        "madein": "Italiya",
        "price": 7.3,
        "photo": "daraq62.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cedde95919438c44b3382",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "519",
        "madein": "Italiya",
        "price": 7,
        "photo": "daraq63.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cedff95919438c44b3383",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "541",
        "madein": "Italiya",
        "price": 4.9,
        "photo": "daraq64.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cee1f95919438c44b3384",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "543",
        "madein": "Italiya",
        "price": 4.9,
        "photo": "daraq65.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cee2d95919438c44b3385",
        "brand": "Kiepe",
        "category": 93,
        "subcat": "Daraq",
        "code": "545",
        "madein": "Italiya",
        "price": 5.3,
        "photo": "daraq66.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cee4295919438c44b3386",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55800",
        "madein": "Italiya",
        "price": 16.3,
        "photo": "daraq67.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceec495919438c44b3387",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55803",
        "madein": "Italiya",
        "price": 15.9,
        "photo": "daraq68.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ceee295919438c44b3388",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55804",
        "madein": "Italiya",
        "price": 13.3,
        "photo": "daraq69.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cef0c95919438c44b3389",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55813",
        "madein": "Italiya",
        "price": 11.1,
        "photo": "daraq70.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cef1b95919438c44b338a",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55814",
        "madein": "Italiya",
        "price": 14.6,
        "photo": "daraq71.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cef3595919438c44b338b",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55819",
        "madein": "Italiya",
        "price": 14.1,
        "photo": "daraq72.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cef8f95919438c44b338c",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55820",
        "madein": "Italiya",
        "price": 13.3,
        "photo": "daraq73.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cefa295919438c44b338d",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55825",
        "madein": "Italiya",
        "price": 18.8,
        "photo": "daraq74.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cefb395919438c44b338e",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55862",
        "madein": "Italiya",
        "price": 14.1,
        "photo": "daraq75.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cefc395919438c44b338f",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "55871",
        "madein": "Italiya",
        "price": 16.9,
        "photo": "daraq76.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cefe395919438c44b3390",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "55SP26",
        "madein": "Italiya",
        "price": 42.2,
        "photo": "daraq77.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf07195919438c44b3391",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "55SP28",
        "madein": "Italiya",
        "price": 41.4,
        "photo": "daraq78.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf09695919438c44b3392",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "55SP29",
        "madein": "Italiya",
        "price": 48.5,
        "photo": "daraq79.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf0d495919438c44b3393",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "80825V MAC",
        "madein": "Italiya",
        "price": 25.3,
        "photo": "daraq80.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf10995919438c44b3394",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "80SP302.13",
        "madein": "Italiya",
        "price": 35.6,
        "photo": "daraq81.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf21795919438c44b3395",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "80SP302.14",
        "madein": "Italiya",
        "price": 38.1,
        "photo": "daraq82.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf23995919438c44b3396",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "SP111",
        "madein": "Italiya",
        "price": 14.6,
        "photo": "daraq83.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf25795919438c44b3397",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "SP114",
        "madein": "Italiya",
        "price": 23.1,
        "photo": "daraq84.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf28395919438c44b3398",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "AUSP232T1",
        "madein": "Italiya",
        "price": 52.3,
        "photo": "daraq85.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf31595919438c44b3399",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "71SP227.ASS",
        "madein": "Italiya",
        "price": 30.9,
        "photo": "daraq86.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf32f95919438c44b339a",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "SP105 NER",
        "madein": "Italiya",
        "price": 22.3,
        "photo": "daraq87.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf34e95919438c44b339b",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "SP08GV MAC",
        "madein": "Italiya",
        "price": 60.4,
        "photo": "daraq88.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf36d95919438c44b339c",
        "brand": "Janeke",
        "category": 93,
        "subcat": "Daraq",
        "code": "LG 360",
        "madein": "Italiya",
        "price": 29.6,
        "photo": "daraq89.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dff2995919438c44b339d",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "421",
        "madein": "Italiya",
        "price": 13,
        "size": "5mm",
        "photo": "mpa01.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dff5795919438c44b339e",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "421",
        "madein": "Italiya",
        "price": 13,
        "size": "3mm",
        "photo": "mpa02.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dff9695919438c44b339f",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "421",
        "madein": "Italiya",
        "price": 13,
        "size": "6mm",
        "photo": "mpa03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dffa295919438c44b33a0",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "421",
        "madein": "Italiya",
        "price": 13,
        "size": "14mm",
        "photo": "mpa04.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dffcc95919438c44b33a1",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Kutikul Aləti",
        "code": "435",
        "madein": "Italiya",
        "price": 6.7,
        "photo": "mpa05.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dffe695919438c44b33a2",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "0222/7",
        "madein": "Italiya",
        "price": 3.6,
        "photo": "mpa06.jpg",
        "__v": 0
    },
    {
        "_id": "5f4dfff895919438c44b33a3",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "301",
        "madein": "Italiya",
        "price": 2,
        "photo": "mpa07.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e000c95919438c44b33a4",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "303",
        "madein": "Italiya",
        "price": 2,
        "photo": "mpa08.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e002895919438c44b33a5",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "310",
        "madein": "Italiya",
        "price": 2.2,
        "photo": "mpa09.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e003695919438c44b33a6",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "311",
        "madein": "Italiya",
        "price": 2.5,
        "photo": "mpa10.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e006a95919438c44b33a7",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pilka",
        "code": "363/7.5",
        "madein": "Italiya",
        "price": 6.4,
        "photo": "mpa11.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e00ae95919438c44b33a8",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0615",
        "madein": "Italiya",
        "price": 59,
        "size": "3mm",
        "photo": "mpa12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e00dc95919438c44b33a9",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "401",
        "madein": "Italiya",
        "price": 4.4,
        "photo": "mpa13.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e011995919438c44b33aa",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "411",
        "madein": "Italiya",
        "price": 5,
        "photo": "mpa14.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e014195919438c44b33ab",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "410",
        "madein": "Italiya",
        "price": 5.5,
        "photo": "mpa15.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e01a095919438c44b33ac",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0603",
        "madein": "Italiya",
        "price": 24.4,
        "size": "12mm",
        "photo": "mpa16.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e01d395919438c44b33ad",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0613",
        "madein": "Italiya",
        "price": 54.4,
        "size": "3mm",
        "photo": "mpa17.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e01e995919438c44b33ae",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0686",
        "madein": "Italiya",
        "price": 17.8,
        "size": "5mm",
        "photo": "mpa18.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e020a95919438c44b33af",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "T241",
        "madein": "Italiya",
        "price": 7.6,
        "photo": "mpa19.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e023b95919438c44b33b0",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "T247",
        "madein": "Italiya",
        "price": 8.1,
        "photo": "mpa20.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e025695919438c44b33b1",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pedikür Aləti",
        "code": "451",
        "madein": "Italiya",
        "price": 5.4,
        "photo": "mpa21.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e026a95919438c44b33b2",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pedikür Aləti",
        "code": "452",
        "madein": "Italiya",
        "price": 10.8,
        "photo": "mpa22.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e028295919438c44b33b3",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Pedikür Aləti",
        "code": "453",
        "madein": "Italiya",
        "price": 3.6,
        "photo": "mpa23.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e029395919438c44b33b4",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Dabandaşı",
        "code": "456",
        "madein": "Italiya",
        "price": 5.1,
        "photo": "mpa24.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e031495919438c44b33b5",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Dabandaşı",
        "code": "456",
        "madein": "Italiya",
        "price": 3,
        "photo": "mpa25.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e034395919438c44b33b6",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Manikür Aləti",
        "code": "0680",
        "madein": "Italiya",
        "price": 26.1,
        "size": "10mm",
        "photo": "mpa26.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e036495919438c44b33b7",
        "brand": "Kiepe",
        "category": 97,
        "subcat": "Maqqaş",
        "code": "B140/114",
        "madein": "Italiya",
        "price": 5.2,
        "photo": "mpa27.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e081d95919438c44b33b8",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Uniq",
        "madein": "Türkiyə",
        "photo": "hwu01.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e082d95919438c44b33b9",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Nitro",
        "madein": "Türkiyə",
        "photo": "hwu02.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e083d95919438c44b33ba",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "YS 001",
        "madein": "Türkiyə",
        "photo": "hwu03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e085995919438c44b33bb",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "YS 001 Elektrikli",
        "madein": "Türkiyə",
        "photo": "hwu03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e086a95919438c44b33bc",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Relax",
        "madein": "Türkiyə",
        "photo": "hwu04.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e087d95919438c44b33bd",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Basic",
        "madein": "Türkiyə",
        "photo": "hwu05.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e08a895919438c44b33be",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Rest",
        "madein": "Türkiyə",
        "photo": "hwu06.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e08c895919438c44b33bf",
        "brand": "Ceriotti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Class",
        "madein": "İtaliya",
        "photo": "hwu07.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4e091495919438c44b33c0",
        "brand": "Ceriotti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Relax Elektrikli",
        "madein": "İtaliya",
        "photo": "hwu08.png",
        "__v": 0
    },
    {
        "_id": "5f4e095d95919438c44b33c1",
        "brand": "Ceriotti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Manta",
        "madein": "İtaliya",
        "photo": "hwu09.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e098395919438c44b33c2",
        "brand": "Maletti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Comfort Elektrikli",
        "madein": "İtaliya",
        "photo": "hwu10.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e09ad95919438c44b33c3",
        "brand": "Maletti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Eco Fun",
        "madein": "İtaliya",
        "photo": "hwu11.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e09cd95919438c44b33c4",
        "brand": "Maletti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Lazarro",
        "madein": "İtaliya",
        "photo": "hwu12.jpg",
        "__v": 0
    },
    {
        "_id": "5f4e09dd95919438c44b33c5",
        "brand": "Maletti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Lift Elektrikli",
        "madein": "İtaliya",
        "photo": "hwu13.jpg",
        "__v": 0
    },
    {
        "_id": "5f51239ca693e7417c40aefe",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Ares",
        "madein": "Türkiyə",
        "photo": "ba01.jpg",
        "__v": 0
    },
    {
        "_id": "5f51244ba693e7417c40aeff",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Boss",
        "madein": "Türkiyə",
        "photo": "ba02.jpg",
        "__v": 0
    },
    {
        "_id": "5f5124cba693e7417c40af00",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Champion",
        "madein": "Türkiyə",
        "photo": "ba03.jpg",
        "__v": 0
    },
    {
        "_id": "5f512532a693e7417c40af01",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Hercule",
        "madein": "Türkiyə",
        "photo": "ba04.JPG",
        "__v": 0
    },
    {
        "_id": "5f5125d6a693e7417c40af02",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Hero",
        "madein": "Türkiyə",
        "photo": "ba05.jpg",
        "__v": 0
    },
    {
        "_id": "5f512632a693e7417c40af03",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Leo",
        "madein": "Türkiyə",
        "photo": "ba06.jpg",
        "__v": 0
    },
    {
        "_id": "5f5126a1a693e7417c40af04",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Royal",
        "madein": "Türkiyə",
        "photo": "ba07.jpg",
        "__v": 0
    },
    {
        "_id": "5f512712a693e7417c40af05",
        "brand": "Alpeda",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Cosmo",
        "madein": "Türkiyə",
        "photo": "ba08.jpg",
        "__v": 0
    },
    {
        "_id": "5f5127e6a693e7417c40af06",
        "brand": "Maletti",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Zeus Easy",
        "madein": "Italiya",
        "photo": "ba09.jpg",
        "__v": 0
    },
    {
        "_id": "5f5128daa693e7417c40af07",
        "brand": "Maletti",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Sigma",
        "madein": "Italiya",
        "photo": "ba10.jpg",
        "__v": 0
    },
    {
        "_id": "5f51293ba693e7417c40af08",
        "brand": "Ceriotti",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Royal",
        "madein": "Italiya",
        "photo": "ba11.jpg",
        "__v": 0
    },
    {
        "_id": "5f5129aca693e7417c40af09",
        "brand": "Maletti",
        "category": 32,
        "subcat": "Bərbər Qoltuğu",
        "code": "Zerbini 1906 Limited Edition",
        "madein": "Italiya",
        "photo": "ba12.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c05a693e7417c40af0a",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Mimi",
        "madein": "Italiya",
        "photo": "qoltuq01.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c23a693e7417c40af0b",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Tulipa",
        "madein": "Italiya",
        "photo": "qoltuq02.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c69a693e7417c40af0c",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Musette",
        "madein": "Italiya",
        "photo": "qoltuq03.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c78a693e7417c40af0d",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Angelina",
        "madein": "Italiya",
        "photo": "qoltuq04.jpg",
        "__v": 0
    },
    {
        "_id": "5f512c96a693e7417c40af0e",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Lioness Make Up",
        "madein": "Italiya",
        "photo": "qoltuq05.jpg",
        "__v": 0
    },
    {
        "_id": "5f512cb6a693e7417c40af0f",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Eco Fun",
        "madein": "Italiya",
        "photo": "qoltuq06.jpg",
        "__v": 0
    },
    {
        "_id": "5f512ccea693e7417c40af10",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Pompadour",
        "madein": "Italiya",
        "photo": "qoltuq07.jpg",
        "__v": 0
    },
    {
        "_id": "5f512ce7a693e7417c40af11",
        "brand": "Maletti",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Angelina Rec.",
        "madein": "Italiya",
        "photo": "qoltuq08.jpg",
        "__v": 0
    },
    {
        "_id": "5f512d60a693e7417c40af12",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Giorgia",
        "madein": "Italiya",
        "photo": "qoltuq09.png",
        "__v": 0
    },
    {
        "_id": "5f512da6a693e7417c40af13",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Ginevra",
        "madein": "Italiya",
        "photo": "qoltuq10.jpg",
        "__v": 0
    },
    {
        "_id": "5f512ddfa693e7417c40af14",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Class",
        "madein": "Italiya",
        "photo": "qoltuq11.png",
        "__v": 0
    },
    {
        "_id": "5f512e0ea693e7417c40af15",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Grace",
        "madein": "Italiya",
        "photo": "qoltuq12.jpeg",
        "__v": 0
    },
    {
        "_id": "5f512e6da693e7417c40af16",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Sabrina",
        "madein": "Italiya",
        "photo": "qoltuq13.png",
        "__v": 0
    },
    {
        "_id": "5f512ea0a693e7417c40af17",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Flex",
        "madein": "Italiya",
        "photo": "qoltuq14.png",
        "__v": 0
    },
    {
        "_id": "5f512ec4a693e7417c40af18",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Cloud",
        "madein": "Italiya",
        "photo": "qoltuq15.jpg",
        "__v": 0
    },
    {
        "_id": "5f512ed8a693e7417c40af19",
        "brand": "Ceriotti",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Carol",
        "madein": "Italiya",
        "photo": "qoltuq16.jpg",
        "__v": 0
    },
    {
        "_id": "5f513016a693e7417c40af1b",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Amara",
        "madein": "Türkiyə",
        "photo": "qoltuq18.jpg",
        "__v": 0
    },
    {
        "_id": "5f513068a693e7417c40af1c",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Avanti",
        "madein": "Türkiyə",
        "photo": "qoltuq19.jpg",
        "__v": 0
    },
    {
        "_id": "5f51309ea693e7417c40af1d",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Diamond",
        "madein": "Türkiyə",
        "photo": "qoltuq20.jpg",
        "__v": 0
    },
    {
        "_id": "5f5130d3a693e7417c40af1e",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Cute",
        "madein": "Türkiyə",
        "photo": "qoltuq21.jpg",
        "__v": 0
    },
    {
        "_id": "5f513125a693e7417c40af1f",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Evo Black",
        "madein": "Türkiyə",
        "photo": "qoltuq22.jpg",
        "__v": 0
    },
    {
        "_id": "5f51317da693e7417c40af20",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Flora",
        "madein": "Türkiyə",
        "photo": "qoltuq23.jpg",
        "__v": 0
    },
    {
        "_id": "5f5131b0a693e7417c40af21",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Halley",
        "madein": "Türkiyə",
        "photo": "qoltuq24.jpg",
        "__v": 0
    },
    {
        "_id": "5f513283a693e7417c40af22",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Quenn",
        "madein": "Türkiyə",
        "photo": "qoltuq25.jpg",
        "__v": 0
    },
    {
        "_id": "5f5132caa693e7417c40af23",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Qadın Qoltuğu",
        "code": "Elite Black",
        "madein": "Türkiyə",
        "photo": "qoltuq26.jpg",
        "__v": 0
    },
    {
        "_id": "5f5137b7a693e7417c40af24",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Classico",
        "madein": "Türkiyə",
        "photo": "qoltuq27.jpg",
        "__v": 0
    },
    {
        "_id": "5f513812a693e7417c40af25",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Nova",
        "madein": "Türkiyə",
        "photo": "qoltuq28.jpg",
        "__v": 0
    },
    {
        "_id": "5f513854a693e7417c40af26",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Slim",
        "madein": "Türkiyə",
        "photo": "qoltuq29.jpg",
        "__v": 0
    },
    {
        "_id": "5f51386ca693e7417c40af27",
        "brand": "Alpeda",
        "category": 31,
        "subcat": "Vizaj Qoltuğu",
        "code": "Uniq",
        "madein": "Türkiyə",
        "photo": "qoltuq30.jpg",
        "__v": 0
    },
    {
        "_id": "5f513968a693e7417c40af28",
        "brand": "Ceiotti",
        "category": 33,
        "subcat": "Taburet",
        "code": "Jerry",
        "madein": "Italiya",
        "photo": "stul01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5139a5a693e7417c40af2a",
        "brand": "Ceiotti",
        "category": 33,
        "subcat": "Taburet",
        "code": "Tao",
        "madein": "Italiya",
        "photo": "stul03.jpg",
        "__v": 0
    },
    {
        "_id": "5f513a66a693e7417c40af2b",
        "brand": "Alpeda",
        "category": 33,
        "subcat": "Uşaq Qoltuğu",
        "code": "Car Seat",
        "madein": "Türkiyə",
        "photo": "stul04.jpg",
        "__v": 0
    },
    {
        "_id": "5f513a73a693e7417c40af2c",
        "brand": "Alpeda",
        "category": 33,
        "subcat": "Uşaq Qoltuğu",
        "code": "Cat",
        "madein": "Türkiyə",
        "photo": "stul05.jpg",
        "__v": 0
    },
    {
        "_id": "5f513a7ba693e7417c40af2d",
        "brand": "Alpeda",
        "category": 33,
        "subcat": "Uşaq Qoltuğu",
        "code": "Cooper",
        "madein": "Türkiyə",
        "photo": "stul06.jpg",
        "__v": 0
    },
    {
        "_id": "5f513bf3a693e7417c40af2e",
        "brand": "Alpeda",
        "category": 22,
        "subcat": "Saç Yuma Dəsti",
        "code": "Maya",
        "madein": "Türkiyə",
        "photo": "hwu15.JPG",
        "__v": 0
    },
    {
        "_id": "5f513d94a693e7417c40af31",
        "brand": "Ceriotti",
        "category": 21,
        "subcat": "Saç Yuma Dəsti",
        "code": "Oxford",
        "madein": "Italiya",
        "photo": "hwu18.jpg",
        "__v": 0
    },
    {
        "_id": "5f513f6bc1baa704681d926c",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Ultrabənövşəyi Sterilizator",
        "code": "New Germix",
        "madein": "Italiya",
        "price": 257,
        "photo": "sterilizator01.jpg",
        "power": "220-240V / 15W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f51400bc1baa704681d926d",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Ultrabənövşəyi Sterilizator",
        "code": "GX-4",
        "madein": "Italiya",
        "price": 270,
        "photo": "sterilizator02.jpg",
        "power": "220-240V / 15W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51402bc1baa704681d926e",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Ultrabənövşəyi Sterilizator",
        "code": "Stelth",
        "madein": "Italiya",
        "price": 350,
        "photo": "sterilizator03.jpg",
        "power": "220-240V / W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51407dc1baa704681d926f",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Sterilizator",
        "code": "GX-7",
        "madein": "Italiya",
        "price": 230,
        "photo": "sterilizator04.jpg",
        "power": "220-240V / 75W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f514091c1baa704681d9270",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Sterilizator",
        "code": "Microstop",
        "madein": "Italiya",
        "price": 250,
        "photo": "sterilizator05.jpg",
        "power": "220-240V / 75W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f5140bac1baa704681d9271",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Sterilizator",
        "code": "Sanity Security",
        "madein": "Italiya",
        "price": 650,
        "photo": "sterilizator06.jpg",
        "power": "220-240V / 400W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f5140d4c1baa704681d9272",
        "brand": "Ceriotti",
        "category": 56,
        "subcat": "Ultrabənövşəyi Sterilizator",
        "code": "New Germix 2",
        "madein": "Italiya",
        "price": 330,
        "photo": "sterilizator07.jpg",
        "power": "220-240V / 15W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f514280c1baa704681d9273",
        "brand": "Ceriotti",
        "category": 53,
        "subcat": "Vaporizator",
        "code": "Geyser",
        "madein": "Italiya",
        "photo": "vaporizator01.jpg",
        "power": "220-240V / 700W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51437ec1baa704681d9274",
        "brand": "Ceriotti",
        "category": 53,
        "subcat": "Vaporizator",
        "code": "X-Steam 02 Digital",
        "madein": "Italiya",
        "photo": "vaporizator02.jpg",
        "power": "220-240V / 1000W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f5144e4c1baa704681d9275",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Line Up",
        "madein": "Italiya",
        "price": 135,
        "photo": "fen01.jpg",
        "power": "220-240V / 2100-2500W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51453ec1baa704681d9276",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Up Ionic",
        "madein": "Italiya",
        "price": 185,
        "photo": "fen02.jpg",
        "power": "220-240V / 2150-2500W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f514579c1baa704681d9277",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "DJ 3600",
        "madein": "Italiya",
        "price": 126,
        "photo": "fen03.jpg",
        "power": "220-240V / 1700-2000W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f5145abc1baa704681d9278",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "DJ 4200",
        "madein": "Italiya",
        "price": 155,
        "photo": "fen04.jpeg",
        "power": "220-240V / 2150-2500W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f5145e8c1baa704681d9279",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Marco",
        "madein": "Italiya",
        "price": 108,
        "photo": "fen05.jpg",
        "power": "220-240V / 1750-2050W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51460ec1baa704681d927a",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Zero",
        "madein": "Italiya",
        "price": 126,
        "photo": "fen06.jpg",
        "power": "220-240V / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51465cc1baa704681d927b",
        "brand": "Ceriotti",
        "category": 52,
        "subcat": "Hava Feni",
        "code": "Bi",
        "madein": "Italiya",
        "price": 234,
        "photo": "fen07.jpg",
        "power": "220-240V / 1900-2200W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f514734c1baa704681d927c",
        "brand": "Ceriotti",
        "category": 51,
        "subcat": "Stasionar Fen",
        "code": "Apollo 3000",
        "madein": "Italiya",
        "price": 490,
        "photo": "fen08.jpg",
        "power": "220-240V / 1000W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f514799c1baa704681d927d",
        "brand": "Ceriotti",
        "category": 51,
        "subcat": "Stasionar Fen",
        "code": "Gong Ionic 1",
        "madein": "Italiya",
        "price": 760,
        "photo": "fen09.jpg",
        "power": "220-240V / 1400W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f5147b9c1baa704681d927e",
        "brand": "Ceriotti",
        "category": 51,
        "subcat": "Stasionar Fen",
        "code": "Egg",
        "madein": "Italiya",
        "price": 670,
        "photo": "fen10.jpg",
        "power": "220-240V / 1000W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f51480fc1baa704681d927f",
        "brand": "Ceriotti",
        "category": 51,
        "subcat": "Stasionar Fen",
        "code": "Glob 3000",
        "madein": "Italiya",
        "price": 610,
        "photo": "fen11.jpg",
        "power": "220-240V / 1000W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f515436c1baa704681d9280",
        "brand": "Ceriotti",
        "category": 55,
        "subcat": "Klimazon",
        "code": "FX 3500",
        "madein": "Italiya",
        "photo": "klimazon01.jpg",
        "power": "220-240V / 900W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f51544cc1baa704681d9281",
        "brand": "Ceriotti",
        "category": 55,
        "subcat": "Klimazon",
        "code": "FX 4000",
        "madein": "Italiya",
        "photo": "klimazon02.jpg",
        "power": "220-240V / 1400W / 50-60Hz",
        "__v": 0
    },
    {
        "_id": "5f515461c1baa704681d9282",
        "brand": "Ceriotti",
        "category": 55,
        "subcat": "Klimazon",
        "code": "C 3000",
        "madein": "Italiya",
        "photo": "klimazon03.jpg",
        "power": "220-240V / 1090W / 50Hz",
        "__v": 0
    },
    {
        "_id": "5f57ecdeaf7ec209d0a79497",
        "brand": "Xanitalia",
        "category": 59,
        "subcat": "Mum Aparatı",
        "madein": "Italiya",
        "price": 40,
        "code": "1 ədədli Kartric",
        "photo": "aparat01.jpg",
        "power": "25W",
        "__v": 0
    },
    {
        "_id": "5f57ed04af7ec209d0a79498",
        "brand": "Xanitalia",
        "category": 59,
        "subcat": "Mum Aparatı",
        "madein": "Italiya",
        "price": 110,
        "code": "2 ədədli Kartric",
        "photo": "aparat02.jpg",
        "power": "50W",
        "__v": 0
    },
    {
        "_id": "5f57ed71af7ec209d0a79499",
        "brand": "Xanitalia",
        "category": 59,
        "subcat": "Mum Aparatı",
        "madein": "Italiya",
        "price": 130,
        "code": "3 ədədli Kartric ",
        "photo": "aparat03.jpg",
        "power": "75W",
        "__v": 0
    },
    {
        "_id": "5f57edb1af7ec209d0a7949a",
        "brand": "Xanitalia",
        "category": 59,
        "subcat": "Mum Aparatı",
        "code": "Elegance 800",
        "madein": "Italiya",
        "price": 130,
        "photo": "aparat04.jpg",
        "power": "100W",
        "__v": 0
    },
    {
        "_id": "5f57ee28af7ec209d0a7949b",
        "brand": "Rica",
        "category": 59,
        "subcat": "Mum Aparatı",
        "code": "1 ədədli Kartric ",
        "madein": "Italiya",
        "price": 63,
        "photo": "aparat05.jpg",
        "power": "35W",
        "__v": 0
    },
    {
        "_id": "5f57ee7baf7ec209d0a7949c",
        "brand": "Prowax",
        "category": 59,
        "subcat": "Mum Aparatı",
        "code": " ",
        "madein": "Çin",
        "price": 44,
        "photo": "aparat06.jpg",
        "power": "70W",
        "__v": 0
    },
    {
        "_id": "5f57eea1af7ec209d0a7949d",
        "brand": "Angel Beauty",
        "category": 59,
        "subcat": " ",
        "code": "Mum Aparatı",
        "madein": "Çin",
        "price": 68.4,
        "photo": "aparat07.jpg",
        "power": "105W",
        "__v": 0
    },
    {
        "_id": "5f57ef06af7ec209d0a7949e",
        "brand": "Parafin Aparatı",
        "category": 59,
        "subcat": "Parafin Aparatı ",
        "code": " ",
        "madein": "Çin",
        "price": 60,
        "photo": "aparat08.jpg",
        "power": "200W",
        "__v": 0
    },
    {
        "_id": "5f57ef5faf7ec209d0a7949f",
        "brand": "Rica",
        "category": 59,
        "subcat": "Mum Aparatı",
        "code": " ",
        "madein": "İtaliya",
        "price": 378,
        "photo": "aparat09.jpg",
        "power": "130W",
        "__v": 0
    },
    {
        "_id": "5f5890fee483252e484e33bf",
        "brand": "Silver Fox",
        "category": 98,
        "subcat": "Dəsmal Qızdıran Aparat",
        "code": " ",
        "madein": "Çin",
        "price": 580,
        "photo": "desmalqizdiranaparat01.jpg",
        "power": "480W",
        "__v": 0
    },
    {
        "_id": "5f58912ae483252e484e33c0",
        "brand": "Skin Doctor",
        "category": 98,
        "subcat": "Par Aparatı",
        "code": " ",
        "madein": "Çin",
        "price": 63,
        "photo": "paraparati01.jpg",
        "power": "50W",
        "__v": 0
    },
    {
        "_id": "5f589293e483252e484e33c1",
        "brand": " ",
        "category": 57,
        "subcat": "Stolüstü Lampa Lupa",
        "code": "MA 1013",
        "madein": "Tayvan",
        "price": 90,
        "photo": "lampalupa01.png",
        "power": "6.5W",
        "__v": 0
    },
    {
        "_id": "5f5892cbe483252e484e33c2",
        "brand": " ",
        "category": 57,
        "subcat": "Stolüstü Lampa Lupa",
        "code": "MA 1006F",
        "madein": "Tayvan",
        "price": 90,
        "photo": "lampalupa02.jpg",
        "power": "5.5W",
        "__v": 0
    },
    {
        "_id": "5f5892f8e483252e484e33c3",
        "brand": " ",
        "category": 57,
        "subcat": "Lampa Lupa",
        "code": "MA 1209",
        "madein": "Tayvan",
        "price": 150,
        "photo": "lampalupa03.jpg",
        "__v": 0
    },
    {
        "_id": "5f589397e483252e484e33c5",
        "brand": " ",
        "category": 57,
        "subcat": "Lampa Lupa",
        "code": "MA 1205",
        "madein": "Tayvan",
        "price": 135,
        "photo": "lampalupa05.jpg",
        "power": "22W",
        "__v": 0
    },
    {
        "_id": "5f5893cee483252e484e33c6",
        "brand": "Silver Fox",
        "category": 57,
        "subcat": "Lampa Lupa",
        "code": "1005",
        "madein": "Çin",
        "price": 170,
        "photo": "lampalupa06.jpg",
        "power": "6W",
        "__v": 0
    },
    {
        "_id": "5f589448e483252e484e33c7",
        "brand": " ",
        "category": 57,
        "subcat": "Eynək Lampa Lupa",
        "code": "MA-016",
        "madein": "Tayvan",
        "price": 28,
        "photo": "lampalupa07.jpg",
        "__v": 0
    },
    {
        "_id": "5f58946fe483252e484e33c8",
        "brand": "KLC",
        "category": 57,
        "subcat": "Vizaj İşığı",
        "code": " ",
        "madein": "Çin",
        "price": 350,
        "photo": "lampalupa08.png",
        "__v": 0
    },
    {
        "_id": "5f58961ae483252e484e33c9",
        "brand": "Silver Fox",
        "category": 42,
        "subcat": "Elektrikli Pedikür Spa",
        "code": " ",
        "madein": "Çin",
        "photo": "pedicspa01.jpg",
        "__v": 0
    },
    {
        "_id": "5f589657e483252e484e33ca",
        "brand": " ",
        "category": 42,
        "subcat": "Pedikür Spa",
        "code": "Avangard",
        "madein": "Türkiyə",
        "photo": "pedicspa02.png",
        "__v": 0
    },
    {
        "_id": "5f58966be483252e484e33cb",
        "brand": " ",
        "category": 42,
        "subcat": "Pedikür Spa",
        "code": "PDKR001",
        "madein": "Türkiyə",
        "photo": "pedicspa03.jpg",
        "__v": 0
    },
    {
        "_id": "5f58968de483252e484e33cc",
        "brand": " ",
        "category": 42,
        "subcat": "Pedikür Spa",
        "code": "Soft",
        "madein": "Türkiyə",
        "photo": "pedicspa04.jpg",
        "__v": 0
    },
    {
        "_id": "5f5896a7e483252e484e33ce",
        "brand": " ",
        "category": 42,
        "subcat": "Pedikür Spa",
        "code": "Moon",
        "madein": "Türkiyə",
        "photo": "pedicspa06.png",
        "__v": 0
    },
    {
        "_id": "5f58a504b4d0b92db854f946",
        "brand": "UKI",
        "category": 54,
        "subcat": "Ütü Fen",
        "code": "Online Plus",
        "madein": "Italiya",
        "price": 145,
        "photo": "bfen01.jpg",
        "power": "50W",
        "heat": "210",
        "__v": 0
    },
    {
        "_id": "5f58a59fb4d0b92db854f947",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Ütü Fen",
        "code": "BAB2654EPE",
        "madein": "Fransa",
        "price": 135,
        "photo": "bfen02.jpg",
        "power": "33W",
        "heat": "210",
        "__v": 0
    },
    {
        "_id": "5f58a5d1b4d0b92db854f948",
        "brand": "Jaguar",
        "category": 54,
        "subcat": "Ütü Fen",
        "code": "Silk Power",
        "madein": "ABŞ",
        "price": 90,
        "photo": "bfen03.jpg",
        "power": "60W",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a61bb4d0b92db854f949",
        "brand": "Sthauer",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "Doll Brush '38 ",
        "madein": "İtaliya",
        "price": 65,
        "photo": "bfen04.jpg",
        "power": "50-55w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a647b4d0b92db854f94a",
        "brand": "Ceriotti",
        "category": 54,
        "subcat": "Ütü Fen",
        "code": "Keram",
        "madein": "İtaliya",
        "price": 90,
        "photo": "bfen05.jpg",
        "power": "60w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a678b4d0b92db854f94b",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "BAB2369TTE",
        "madein": "Fransa",
        "price": 210,
        "photo": "bfen06.jpg",
        "power": "79w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a6a4b4d0b92db854f94c",
        "brand": "UKİ",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "Conic '19 ",
        "madein": "Italiya",
        "price": 99,
        "photo": "bfen07.jpg",
        "power": "40w",
        "heat": "210",
        "__v": 0
    },
    {
        "_id": "5f58a6c7b4d0b92db854f94d",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "BAB2335TTE",
        "madein": "Fransa",
        "price": 150,
        "photo": "bfen08.jpg",
        "power": "33w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a6e4b4d0b92db854f94e",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "271 CE",
        "madein": "Fransa",
        "price": 65,
        "photo": "bfen09.jpg",
        "power": "18w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a71ab4d0b92db854f94f",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Saç Burmaq üçün Fen",
        "code": "Mira Curl",
        "madein": "Fransa",
        "price": 345,
        "photo": "bfen10.jpg",
        "power": "44w",
        "heat": "200",
        "__v": 0
    },
    {
        "_id": "5f58a779b4d0b92db854f950",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Termal Biqudi",
        "code": "BAB3021E",
        "madein": "Fransa",
        "price": 95,
        "photo": "bfen11.jpg",
        "power": "400W",
        "__v": 0
    },
    {
        "_id": "5f58a78ab4d0b92db854f951",
        "brand": "Babyliss",
        "category": 54,
        "subcat": "Termal Biqudi",
        "code": "3060E",
        "madein": "Fransa",
        "price": 150,
        "photo": "bfen12.jpg",
        "power": "400W",
        "__v": 0
    },
    {
        "_id": "5f58db2521ef2c36dc83b428",
        "brand": "Silver Fox",
        "category": 44,
        "subcat": "Masaj Yatağı",
        "code": "60",
        "madein": "Çin",
        "photo": "yataq01.jpg",
        "__v": 0
    },
    {
        "_id": "5f58db3a21ef2c36dc83b429",
        "brand": "Silver Fox",
        "category": 44,
        "subcat": "Portativ Masaj Yatağı",
        "code": "09B",
        "madein": "Çin",
        "photo": "yataq02.jpg",
        "__v": 0
    },
    {
        "_id": "5f58db4f21ef2c36dc83b42a",
        "brand": "Silver Fox",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "10A",
        "madein": "Çin",
        "photo": "yataq03.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dbb121ef2c36dc83b42b",
        "brand": "Silver Fox",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "47T",
        "madein": "Çin",
        "photo": "yataq04.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dc2521ef2c36dc83b42c",
        "brand": " ",
        "category": 44,
        "subcat": "Portativ Masaj Yatağı",
        "code": "Daisy",
        "madein": "Italiya",
        "photo": "yataq10.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dc3621ef2c36dc83b42d",
        "brand": " ",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "Amalfi",
        "madein": "Italiya",
        "photo": "yataq11.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dc4821ef2c36dc83b42e",
        "brand": " ",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "Harmony",
        "madein": "Italiya",
        "photo": "yataq12.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dc6421ef2c36dc83b42f",
        "brand": " ",
        "category": 44,
        "subcat": "Elektrikli Masaj Yatağı",
        "code": "King Round",
        "madein": "Italiya",
        "photo": "yataq13.jpeg",
        "__v": 0
    },
    {
        "_id": "5f58dd4821ef2c36dc83b430",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Kosmetoloji Yataq",
        "code": "01",
        "madein": "Çin",
        "photo": "yataq05.jpg",
        "__v": 0
    },
    {
        "_id": "5f58ddd621ef2c36dc83b431",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Kosmetoloji Yataq",
        "code": "42",
        "madein": "Çin",
        "photo": "yataq06.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dde021ef2c36dc83b432",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Kosmetoloji Yataq",
        "code": "06F",
        "madein": "Çin",
        "photo": "yataq07.jpg",
        "__v": 0
    },
    {
        "_id": "5f58de0921ef2c36dc83b433",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Elektrikli Kosmetoloji Yataq",
        "code": "14B",
        "madein": "Çin",
        "photo": "yataq08.jpg",
        "__v": 0
    },
    {
        "_id": "5f58de1321ef2c36dc83b434",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Elektrikli Kosmetoloji Yataq",
        "code": "46",
        "madein": "Çin",
        "photo": "yataq09.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d25009f0ae66120eb1b2c",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Saç tökülməsinə qarşı şampun",
        "descr": "Çay ağacı yağı, gicitkən və bibər tərkibli şampun, saç tökülməsi və profilaktikası üçün hazırlanmışdır. Xüsusi tərkibli şampun, qan dövranını sürətləndirir,saç soğanaqlarının fəaliyyətini yaxşılaşdırır və saç köklərini möhkəmləndirir. Antiseptik, dezinfeksiyaedici, sakitləşdirici və yağ balansını tənzimləmə xüsusiyyətinə malikdir. İstifadə qaydası: Yaş saça tətbiq edin. Saç və saç dərisini masaj edin, 2 dəqiqə saxlayın və yuyun. Qeyd: Ən yaxşı nəticə üçün həftədə iki dəfə olmaqla, 6-12 həftə istifadə etməyiniz məsləhət görülür.",
        "code": "K-05 Anti Hair Loss Shampoo",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "250ml",
        "photo": "shampoo01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d34029f0ae66120eb1b2f",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Yağlı saçlar üçün şampun",
        "descr": "Şampun saç dərisini dərindən təmizləyir və artıq sebumu aradan qaldırır. Çay ağacı yağı iltihab əleyhinə təsir göstərir, yağ balansını normallaşdırır, saç toxumalarını oksigenlə təmin edir və qan dövranını yaxşılaşdırır. Saçlar yenidən təbii, parlaq və sağlam görünüş alır. İstifadə qaydası:Masaj hərəkətləri ilə baş dərisinə çəkin və yuyun. Qeyd: Ən yaxşı  nəticə üçün həftədə 2 dəfə, profilaktika üçün isə həftədə 1 dəfə istifadəsi məsləhət görülür.",
        "code": "K-05 Sebum Balancing Shampoo",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "1000ml",
        "photo": "shampoo07.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d34509f0ae66120eb1b30",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Yağlı saçlar üçün şampun",
        "descr": "Şampun saç dərisini dərindən təmizləyir və artıq sebumu aradan qaldırır. Çay ağacı yağı iltihab əleyhinə təsir göstərir, yağ balansını normallaşdırır, saç toxumalarını oksigenlə təmin edir və qan dövranını yaxşılaşdırır. Saçlar yenidən təbii, parlaq və sağlam görünüş alır. İstifadə qaydası:Masaj hərəkətləri ilə baş dərisinə çəkin və yuyun. Qeyd: Ən yaxşı  nəticə üçün həftədə 2 dəfə, profilaktika üçün isə həftədə 1 dəfə istifadəsi məsləhət görülür.",
        "code": "K-05 Sebum Balancing Shampoo",
        "madein": "İtaliya",
        "price": 25,
        "size": "1000ml",
        "photo": "shampoo08.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5d35089f0ae66120eb1b31",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Kəpəyə qarşı şampun",
        "descr": "Kəpəyə qarşı müalicəvi şampun baş dərisini zərif və təsirli şəkildə təmizləyir, kəpəyin səbəb olduğu qaşınmanı,quruluğu və qıcıqlanmanı aradan qaldırmağa kömək edir. Çay ağacı yağı ilə zənginləşdirilmiş şampun antifungal, antibakterial və antiseptik xüsusiyyətlərə malikdir. Tərkibində olan Pirokton Olamin isə baş dərisinin qabıqlanmasının qarşısını alır. Saçları yumşaq, parlaq və sağlam edir. İstifadə qaydası: Yaş saça tətbiq edin. Saç və saç dərisini masaj edin, 2 dəqiqə saxlayın və yuyun. Qeyd: Ən yaxşı nəticə üçün həftədə ən azı 2 dəfə istifadə edin. Bu saç baxımı məhsulundan  müntəzəm istifadə həm də baş dərisi infeksiyasının qarşısını alır.",
        "code": "K-05 Dandruff Removing Shampoo",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "250ml",
        "photo": "shampoo10.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d354e9f0ae66120eb1b32",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Kəpəyə qarşı şampun",
        "descr": "Kəpəyə qarşı müalicəvi şampun  baş dərisini zərif və təsirli şəkildə təmizləyir, kəpəyin səbəb olduğu  qaşınmanı,quruluğu və qıcıqlanmanı aradan qaldırmağa kömək edir. Çay ağacı yağı ilə zənginləşdirilmiş şampun antifungal, antibakterial və antiseptik xüsusiyyətlərə malikdir. Tərkibində olan Piroctone Olamine isə baş dərisinin qabıqlanmasının qarşısını alır. Saçları yumşaq, parlaq və sağlam edir. İstifadə qaydası: Yaş saça tətbiq edin. Saç və saç dərisini masaj edin, 2 dəqiqə saxlayın və yuyun. Qeyd: Ən yaxşı nəticə üçün həftədə ən azı 2 dəfə istifadə edin. Bu saç baxımı məhsulundan  müntəzəm istifadəhəm də baş dərisi infeksiyasının qarşısını alır.",
        "code": "K-05 Dandruff Removing Shampoo",
        "madein": "İtaliya",
        "price": 25,
        "size": "1000ml",
        "photo": "shampoo11.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d36929f0ae66120eb1b33",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Quru saçlar üçün şampun",
        "descr": "Düyü proteinləri və badam yağı tərkibli şampun quru və zəif saçlar üçün nəzərdə tutulmuşdur. Tərkibində olan düyü proteinləri saçları qidalandırır və nəmləndirir. Badam yağı isə xüsusi yumşaqlıq və parlaqlıq bəxş edir. Saçlar daha həcmli, sağlam və parlaq görünür. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 2 dəqiqə masaj edin və yuyun. Şampundan sonra Hydra kondisionerindən istifadə etmək məsləhət görülür.",
        "code": "Purify Hydra Shampoo",
        "madein": "İtaliya",
        "price": 12,
        "size": "300ml",
        "photo": "shampoo26.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d36c79f0ae66120eb1b34",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Quru saçlar üçün şampun",
        "descr": "Düyü proteinləri və badam yağı tərkibli şampun quru və zəif saçlar üçün nəzərdə tutulmuşdur. Tərkibində olan düyü proteinləri saçları qidalandırır və nəmləndirir. Badam yağı isə xüsusi yumşaqlıq və parlaqlıq bəxş edir. Saçlar daha həcmli, sağlam və parlaq görünür. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 2 dəqiqə masaj edin və yuyun. Şampundan sonra Hydra kondisionerindən istifadə etmək məsləhət görülür.",
        "code": "Purify Hydra Shampoo",
        "madein": "İtaliya",
        "price": 18,
        "size": "1000ml",
        "photo": "shampoo27.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d37209f0ae66120eb1b35",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Zədəli saçlar üçün bərpaedici şampun",
        "descr": "Bu şampun işçi bal arılarının hazırladığı təmiz arı südü ilə zənginləşdirilmişdir. Xüsusi vitaminləri,proteinləri və mineralları özündə cəmləşdirən arı südü, saçın dərin qatlarına nüfuz edərək,  onları yüksək dərəcədə bərpa edir.Limnanthes yağı isə saçlara xüsusi yumşaqlıq, möhkəmlik və parlaqlıq bəxş edir. İstifadə qaydası: Nəm saçlara çəkin,  kopükləndirin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Reale Shampoo",
        "madein": "İtaliya",
        "price": 23,
        "size": "1000ml",
        "photo": "shampoo30.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d37709f0ae66120eb1b36",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Həcmverici şampun",
        "descr": "Şampun zəif saçlar üçün nəzərdə tutulmuşdur. Tərkibi Limnanthes  yağı ilə zənginləşdirilmiş şampun, saç soğanaqlarına nüfuz edərək , onları kökdən qaldırır və həcm verir. Beləliklə,saçlar daha gözəl, sıx və həcmli görünür. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Volume Shampoo",
        "madein": "İtaliya",
        "price": 12,
        "size": "300ml",
        "photo": "shampoo32.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d379f9f0ae66120eb1b37",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Həcmverici şampun",
        "descr": "Şampun zəif saçlar üçün nəzərdə tutulmuşdur. Tərkibi Limnanthes  yağı ilə zənginləşdirilmiş şampun, saç soğanaqlarına nüfuz edərək , onları kökdən qaldırır və həcm verir. Beləliklə,saçlar daha gözəl, sıx və həcmli görünür. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Volume Shampoo",
        "madein": "İtaliya",
        "price": 18,
        "size": "1000ml",
        "photo": "shampoo33.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d37f79f0ae66120eb1b38",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Tonlaşdırıcı şampun",
        "descr": "Blonde Elevation açıq rəngli, kimyəvi burulmuş və ağardılmış saçlar üçün hazırlanmışdır. İpək proteinləri ilə zənginləşdirilmiş tərkibi, boyalı və kimyəvi buruqlu saçlara yumşaqlıq, elastiklik və parlaqlıq bəxş edir.Ağ  və ağardılmış saçlarda xoşagəlməz sarılığı aradan qaldırır. İstifadə qaydası: Nəm saçlara çəkin, köpükləndirin, 1-2 dəqiqə saxlayın və yuyun.",
        "code": "Blonde Elevation Shampoo",
        "madein": "İtaliya",
        "price": 15,
        "size": "300ml",
        "photo": "shampoo34.jpg",
        "__v": 0
    },
    {
        "_id": "5f5d38759f0ae66120eb1b39",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Boyalı saçlar üçün şampun",
        "descr": "Colorpro rəngli saçlar üçün hazırlanmışdır. Hidrolizə edilmiş ipək proteinləri və keratinlə zənginləşdirilmiş şampun,boyalı saçları bərpa edir,  saç dərisini nəmləndirir və boyanın uzun müddət qalmasını təmin edir. Aşağı PH balansı saç kutikullarını bağlayır və boyanın axmasının qarşısını alır.  Sulfatsız. Parabensiz. Slikonsuz.",
        "code": "ColorPro Shampoo",
        "madein": "İtaliya",
        "price": 17,
        "size": "300ml",
        "photo": "shampoo35.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dd1a69f0ae66120eb1b3a",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Boyalı üçün qidalandırıcı şampun",
        "descr": "Monoi de Taiti və keratin tərkibli sulfatsız şampun, əsasən, zəif, boyalı və quru saçlar üçün nəzərdə tutulmuşdur. Dərindən qidalandırır, nəmləndirir, saç tellərini bərpa edir, saçlara sağlam və parlaq görünüş verir. İstifadə Qaydası: Qatı emulsiya şəklində olduğu üçün çox su ilə köpükləndirin və yuyun.",
        "code": "Maraes Color Nourishing Shampoo",
        "madein": "İtaliya",
        "price": 17,
        "size": "300ml",
        "photo": "shampoo15.png",
        "__v": 0
    },
    {
        "_id": "5f5dd1df9f0ae66120eb1b3b",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Boyalı üçün qidalandırıcı şampun",
        "descr": "Monoi de Taiti və keratin tərkibli sulfatsız şampun, əsasən, zəif, boyalı və quru saçlar üçün nəzərdə tutulmuşdur. Dərindən qidalandırır, nəmləndirir, saç tellərini bərpa edir, saçlara sağlam və parlaq görünüş verir. İstifadə Qaydası: Qatı emulsiya şəklində olduğu üçün çox su ilə köpükləndirin və yuyun.",
        "code": "Maraes Color Nourishing Shampoo",
        "madein": "İtaliya",
        "price": 31,
        "size": "1000ml",
        "photo": "shampoo16.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dd3fe9f0ae66120eb1b3c",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Qıvrım saçlar üçün bərpaedici şampun",
        "descr": "Monoi de Taiti və Marual yağı tərkibli sulfatsız şampun, əsasən, qıvrım  və dalğalı saçlar üçün nəzərdə tutulmuşdur. Saçlarda təbii elastikliyi bərpa edir, saçlara yumşaq və parlaq görünüş verir. İstifadə Qaydası: Yaş saça tətbiq edin. Saç və saç dərisini masaj edin və yuyun",
        "code": "Curl Revitalizing Shampoo",
        "madein": "İtaliya",
        "price": 17,
        "size": "300ml",
        "photo": "shampoo18.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dd4119f0ae66120eb1b3d",
        "brand": "Kaaral",
        "category": 71,
        "subcat": "Qıvrım saçlar üçün bərpaedici şampun",
        "descr": "Monoi de Taiti və Marual yağı tərkibli sulfatsız şampun, əsasən, qıvrım  və dalğalı saçlar üçün nəzərdə tutulmuşdur. Saçlarda təbii elastikliyi bərpa edir, saçlara yumşaq və parlaq görünüş verir. İstifadə Qaydası: yaş saça tətbiq edin, masaj edin və yuyun.",
        "code": "Curl Revitalizing Shampoo",
        "madein": "İtaliya",
        "price": 31,
        "size": "1000ml",
        "photo": "shampoo19.png",
        "__v": 0
    },
    {
        "_id": "5f5ddb1f9f0ae66120eb1b3e",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Yağlı saçlar üçün şampun",
        "descr": "Ayı pəncəsi, ağcaqayın, kakao ekstratlı Dermopure şampun saçları arzu edilməyən kəpəklərdən azad edir və saçların bakterial florasını, sebum balansını bərpa edir. Saçlara yenidən yumşaq, sağlam və parlaq görünüş verir. İçindəki zəngin minerallar və oliqoelementlər və gil, toksinləri özünə çəkərək, saçı ölü hüceyrələrdən təmizləyir və saç dərisini bərpa edir. İstifadə qaydası: yaş saça tətbiq edin, masaj edin və yuyun.",
        "code": "Dermopure Shampoo",
        "madein": "İtaliya",
        "price": 20,
        "size": "500ml",
        "photo": "sacaqulluqvasiteleri01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5ddc5f9f0ae66120eb1b3f",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Tonlaşdırıcı şampun",
        "descr": "Tərkibində olan aktiv komponentlər ağardılmış və təbii ağ saçlarda olan xoşagəlməz sarılığı aradan qaldırır. Saçlara yumşaqlıq və elastiklik verir. İstifadə qaydası: Saçınıza vurun, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Anti Yellow Shampoo",
        "madein": "İtaliya",
        "price": 11,
        "size": "250ml",
        "photo": "sacaqulluqvasiteleri02.jpg",
        "__v": 0
    },
    {
        "_id": "5f5debd69f0ae66120eb1b42",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Saç üçün skrab",
        "descr": "Badam və fındıq  mikroqranulları baş dərisinin üst qatını yumşaq bir şəkildə yeniləyir. Çirkləri, həddindən artıq yağı, kəpəyi və saç şəkilləndirmə məhsullarının baş dərisinə hopmuş qalıqlarını təmizləyir. Saçları K-05 müalicəvi bölümü üçün hazırlayır. İstifadə qaydası: Şampunla yumazdan əvvəl skrabı nəmli saça çəkin,  3-5 dəqiqə masaj edin və yuyun. ",
        "code": "K-05 Exfoliating Scrub",
        "madein": "İtaliya",
        "price": 33,
        "size": "100ml",
        "photo": "maska06.jpg",
        "__v": 0
    },
    {
        "_id": "5f5df26c9f0ae66120eb1b43",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Kəpəyə qarşı losyon",
        "descr": "Çay ağacı yağı və Pirokton Olamin maddəsi sayəsində losyon saç dərisini normallaşdırır, qidalandırır və qabıqlanmanın qarşısını alır. Yağlı və quru kəpəklərə qarşı effektivdir. İstifadə qaydası: saçınızı Anti Dandruff şampunu ilə yuduqdan sonra losyonu saçınıza çəkin, 3-5 dəqiqə masaj edin və darayın (Yuyulmur). Losyonun həftədə 2 dəfə, 6-12 həftə muddətində istifadə olunması tövsiyyə olunur.",
        "code": "K-05 Dandruff Removing Lotion",
        "madein": "İtaliya",
        "price": 35,
        "size": "10ml.x12ədəd",
        "photo": "maska12.jpg",
        "__v": 0
    },
    {
        "_id": "5f5df51a9f0ae66120eb1b44",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Boyalı və quru saçlar üçün maska",
        "descr": "Monoi De Taiti, Şi yağı və Keratin tərkibli maska boyalı, zədəli və quru saçları dərindən qidalandırır. Zəif və zədəli saç tellərini bərpa edir və möhkəmləndirir, boyanın axmasının qarşısını alır və parlaqlıq verir. İstifadə qaydası: nəm saçlara çəkin, masaj edin, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Maraes Nourishing Mask",
        "madein": "İtaliya",
        "price": 19,
        "size": "200ml.",
        "photo": "maska13.jpg",
        "__v": 0
    },
    {
        "_id": "5f5df5669f0ae66120eb1b45",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Boyalı və quru saçlar üçün maska",
        "descr": "Monoi De Taiti, Şi yağı və Keratin tərkibli maska boyalı, zədəli və quru saçları dərindən qidalandırır. Zəif və zədəli saç tellərini bərpa edir və möhkəmləndirir, boyanın axmasının qarşısını alır və parlaqlıq verir. İstifadə qaydası: nəm saçlara çəkin, masaj edin, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Maraes Nourishing Mask",
        "madein": "İtaliya",
        "price": 33,
        "size": "1000ml.",
        "photo": "maska14.jpg",
        "__v": 0
    },
    {
        "_id": "5f5df8d39f0ae66120eb1b46",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Zədəli və quru saçlar üçün yağ",
        "descr": "Zədəli və zəif saçlar ücün xüsusi qidalandırıcı müalicəvi yağ tərkibində olan, Monoi De Taiti, Kətan toxumu yağı, E vitamini ilə zənginləşdirilimişdir. Saç tellərinin qırılmasının, haçalanmasının qarşını alır, saç rəngini qoruyur,yumşaqlıq, parlaqlıq verir. İstifadə qaydası: nəm saça çəkin və qurudun.",
        "code": "Maraes Nourishing Olium",
        "madein": "İtaliya",
        "price": 24,
        "size": "30ml.",
        "photo": "maska17.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dfcdb9f0ae66120eb1b47",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Qıvrım saçlar üçün bərpaedici maska",
        "descr": "Qıvrım və dalğalı saçlar ücün nəmləndirici və canlandırıcı müalicəvi maska tərkibindəki, Monoi De Taiti, Marula yağı ilə zənginləşdirilimişdir. Saç kutikullarını hamarlayır və saçın natural elastikliyini bərpa edir. İstifadə qaydası: saçın uzunluğuna uyğun olaraq əvvəlcədən yuyulmuş və dəsmalla qurulanmış saça çəkin və masaj edin. Darayın, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Maraes Curl Revitalizing Mask",
        "madein": "İtaliya",
        "price": 19,
        "size": "200ml.",
        "photo": "maska20.jpg",
        "__v": 0
    },
    {
        "_id": "5f5dfd019f0ae66120eb1b48",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Qıvrım saçlar üçün bərpaedici maska",
        "descr": "Qıvrım və dalğalı saçlar ücün nəmləndirici və canlandırıcı müalicəvi maska tərkibindəki, Monoi De Taiti, Marula yağı ilə zənginləşdirilimişdir. Saç kutikullarını hamarlayır və saçın natural elastikliyini bərpa edir. İstifadə qaydası: Saçın uzunluğuna uyğun olaraq,əvvəlcədən yuyulmuş və dəsmalla qurulanmış saça çəkin və masaj edin. Darayın, 2-3 dəqiqə saxlayın və yuyun.",
        "code": "Maraes Curl Revitalizing Mask",
        "madein": "İtaliya",
        "price": 33,
        "size": "1000ml.",
        "photo": "maska21.png",
        "__v": 0
    },
    {
        "_id": "5f5e0be79f0ae66120eb1b49",
        "brand": "Kaaral",
        "category": 72,
        "subcat": "Rəngli və quru saçlar üçün sprey",
        "descr": "Sprey, Monoi De Taiti və Limantes yağının qidalandırıcı komponentlərini özündə birləşdirir.Saça tətbiq olunan boyaların saç tellərini zədələməsinin qarşısını alır, saçı hava fenlərindən və saç burma alətlərindən qoruyur.İstifadə qaydası: yuyulmuş və dəsmalla qurudulmuş saça çəkin və darayın. (Yuyulmur)",
        "code": "Maraes Color Nourishing Shield",
        "madein": "İtaliya",
        "price": 15,
        "size": "150ml.",
        "photo": "maska22.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e3fcc9f0ae66120eb1b4a",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Quru saçlar üçün nəmləndirici kondisioner",
        "descr": "Düyü ekstraktı ilə zənginləşdirilmiş unikal tərkibli kondisioner quru və zəif saçlar üçün işlənib hazırlanmışdır. Şirin badam yağı saçları yumşaldır, qidalandırır və parlaqlıq verir. Saçlar həcmli və sağlam görünür. Rəng qoruyucudur.İstifadə qaydası: saçınızı şampunla yuduqdan sonra kondisioneri saçınıza çəkin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Hydra Conditioner",
        "madein": "İtaliya",
        "price": 12,
        "size": "250ml.",
        "photo": "maska28.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e3fe79f0ae66120eb1b4b",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Quru saçlar üçün nəmləndirici kondisioner",
        "descr": "Quru və zəif saçlar üçün işlənib hazırlanmışdır. Düyü ekstraktları ilə zənginləşdirilmiş unikal tərkib, saçları anında qidalandırır və nəmləndirir. Şirin badam yağı saçları yumşaldır və parlaqlıq verir. Saçlar həcmli və sağlam gürünür. Rəng qoruyucudur.İstifadə qaydası: saçınızı şampunla yuduqdan sonra kondisioneri saçınıza çəkin, 2 dəqiqə masaj edin və yuyun.",
        "code": "Purify Hydra Conditioner",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "1000ml.",
        "photo": "maska29.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e42829f0ae66120eb1b4c",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Zədəli saçlar üçün bərpaedici kondisioner",
        "descr": "Arı südü, zeytun və limnanthes yağı tərkibli kondisioner zədəli saçlar üçün idealdır. Saçları maksimum qidalandırır, saç tellərini yenidən canlandırır, saçların dolğunluğunu və parlaqlığını bərpa edir. İstifadə qaydası: nəm saça çəkin, masaj edin, 2-5 dəqiqə gözlədikdən sonra yuyun.",
        "code": "Purify Reale Conditioner",
        "madein": "İtaliya",
        "price": 24,
        "size": "1000ml.",
        "photo": "maska31.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e4d879f0ae66120eb1b4d",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Həcmverici kondisioner",
        "descr": "Tərkibi limnanthes yağı ilə zənginləşdirilmiş kondisioner, saç soğanaqlarına nüfuz edir və onları kökdən qaldırır. Saçlar daha gözəl, sıx və həcmli görünür. İstifadə qaydası: şampunla yuduqdan sonra,kondisioneri saç boyu saçınıza çəkin, 2 dəqiqə masaj edin  və yuyun.",
        "code": "Purify Volume Conditioner",
        "madein": "İtaliya",
        "price": 12,
        "size": "250ml.",
        "photo": "maska34.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e4d9b9f0ae66120eb1b4e",
        "brand": "Kaaral",
        "category": 73,
        "subcat": "Həcmverici kondisioner",
        "descr": "Tərkibi limnanthes yağı ilə zənginləşdirilmiş kondisioner, saç soğanaqlarına nüfuz edir və onları kökdən qaldırır. Saçlar daha gözəl, sıx və həcmli görünür. İstifadə qaydası: şampunla yuduqdan sonra,kondisioneri saç boyu çəkin, 2 dəqiqə masaj edin  və yuyun.",
        "code": "Purify Volume Conditioner",
        "madein": "İtaliya",
        "price": 19.9,
        "size": "1000ml.",
        "photo": "maska35.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e50ad9f0ae66120eb1b4f",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Parıldadıcı Sprey",
        "descr": "Quru, boyalı və zəif saçlara parlaqlıq verir. Anti-statikdir və istilikdən qorumaq üçün ideal vasitədir. Bütün saç növlərinə uyğundur. İstifadə qaydası: şəkilləndirilmiş saçlara tətbiq edin. ",
        "code": "Style Perfetto Bling",
        "madein": "İtaliya",
        "price": 20.5,
        "size": "300ml.",
        "photo": "maska36.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e53969f0ae66120eb1b50",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Saç lakı",
        "descr": "Pantenol sayəsində saçı istidən qoruyur və ağırlıq yaratmadan saçları fiksasiya edir. Şakilləndirilimiş saçları uzun müddət saxlayır. İstifadə qaydası: şəkilləndirilmiş saçlara 20-25 sm məsafədən tətbiq edin.",
        "code": "Style Perfetto Fixer",
        "madein": "İtaliya",
        "price": 16,
        "size": "400ml.",
        "photo": "maska44.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e56089f0ae66120eb1b51",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Nəmləndirici və qoruyucu serum",
        "descr": "Quru və saçqıranlı saçları qidalandırır və nəmləndirir. Kətan yağı haçalanmış saçları müalicə edir, fenin və ütünün isti təsirindən qoruyur. Saçlar yumşaq, parlaq və sağlam görünür. Yağlılıq effekti yaratmır. İstifadə qaydası: saçları şəkilləndirmədən əvvəl tətbiq edin.",
        "code": "Style Perfetto Tri Action",
        "madein": "İtaliya",
        "price": 21,
        "size": "115ml.",
        "photo": "maska63.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e581c9f0ae66120eb1b52",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Mat Pasta",
        "descr": "Tərkibində olan gil ekstraktı, arı mumu və vitamin E saçları istənilən formada fiksasiya edir və mat görüntü yaradır. İstifadə qaydası: quru saçlara tətbiq edilir.",
        "code": "Style Perfetto Molding",
        "madein": "İtaliya",
        "price": 17,
        "size": "80ml.",
        "photo": "maska49.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e5ad19f0ae66120eb1b53",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Fiber krem",
        "descr": "Jojoba, badam yağı, düyü ekstraktı və vitamin E şəkilləndirilmiş saçlara həcmli görünüş verir. İstifadə qaydası: ovcunuzun içərisində isidin, təmiz və quru saçlara tətbiq edin.",
        "code": "Style Perfetto Unfinished",
        "madein": "İtaliya",
        "price": 17,
        "size": "80ml.",
        "photo": "maska64.png",
        "__v": 0
    },
    {
        "_id": "5f5e5c659f0ae66120eb1b54",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Qazsız lak",
        "descr": "Qazsız ekstra güclü lak şəkilləndirilmiş saçların uzun müddət qalmasını təmin edir və saçlara parlaqlıq verir. İstifadə qaydası: 20-25 sm məsafədən saçlarınıza tətbiq edin.",
        "code": "Style Perfetto Definer Extra Strong",
        "madein": "İtaliya",
        "price": 18,
        "size": "350ml.",
        "photo": "maska42.png",
        "__v": 0
    },
    {
        "_id": "5f5e63849f0ae66120eb1b55",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Natural şəkilləndirici",
        "descr": "Təbii yağsız yağ saçları şəkilləndirmədən əvvəl qıvrımların qarşısını almaq üçün tətbiq olunur. Saçları ağırlaşdırmadan elastiklik və natural parlaqlıq verir. İstifadə qaydası: nəm saça çəkilir, daranır və qurudulur. Beləliklə, qurumuş saçlara yüngül saç formasl verir.",
        "code": "Style Perfetto Primer",
        "madein": "İtaliya",
        "price": 16,
        "size": "200ml.",
        "photo": "maska58.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e65cd9f0ae66120eb1b56",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Həcmverici muss",
        "descr": "Orta fiksasiyalı həcmverici muss saçları fenin və ütünün təsirindən qoruyur, yumşaq və parlaq edir. Gicitkən və gülümbahar ekstraktları saçları nəmləndirir. İstifadə qaydası: təmiz, nəm saçlara tətbiq edin və qurudun.",
        "code": "Style Perfetto Volook",
        "madein": "İtaliya",
        "price": 17,
        "size": "300ml.",
        "photo": "maska65.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e678d9f0ae66120eb1b57",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Saç düzləşdirici krem",
        "descr": "Şəkilləndirməyə tabe olmayan saçları nəmləndirir və arzu etdiyiniz şəkildə düzləşdirir. Fenin, ütünün, rütubətin təsirindən qoruyur və düzləşdirmə effektini uzun müddət saxlayır. Saçlar parlaq və yumuşaq görünür. İstifadə qaydası: təmiz nəm saçlara çəkin, darayın və qurudun.",
        "code": "Style Perfetto Dazzling",
        "madein": "İtaliya",
        "price": 17,
        "size": "250ml.",
        "photo": "maska41.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e69279f0ae66120eb1b58",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Qıvrım saçlar üçün krem",
        "descr": "Qıvrım və dalğalı saçları qidalandırır və nəmləndirir. Vitamin E, günəbaxan və zeytun yağı tərkibli krem qıvrım saçları ağırlaşdırmadan, onlara yumşaqlıq və elastiklik verir. Fenin və ütünün isti təsirindən qoruyur. İstifadə qaydası: az miqdarda təmiz nəm saçlara çəkilir,təbii və ya diffüzorla forma verilir.",
        "code": "Style Perfetto İnsta Curls",
        "madein": "İtaliya",
        "price": 17,
        "size": "150ml.",
        "photo": "maska47.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5e6bfe9f0ae66120eb1b59",
        "brand": "Kaaral",
        "category": 75,
        "subcat": "Quru şampun",
        "descr": "Saçları yuyulmadan anında qaydaya salır. Bir az istifadə saçlara təmizlik və həcm verir. Düyü kraxmalı artıq yağı özünə hopdurur və iz salmadan təmizləyir. İstifadə qaydası: 20 sm məsafədən saça tətbiq edilir, 1 dəqiqə saxladıqdan sonra daranır. İstifadədən əvvəl yaxşı çalxalamaq lazımdır.",
        "code": "Style Perfetto Express",
        "madein": "İtaliya",
        "price": 17,
        "size": "150ml.",
        "photo": "maska43.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e74da9f0ae66120eb1b5a",
        "brand": "Kaaral",
        "category": 74,
        "subcat": "Boya",
        "descr": "Ipək proteini və keratinlə zənginləştirilmiş piqment saçlara dəbdəbəli rənglər verir.",
        "code": "Color Splash 11",
        "madein": "İtaliya",
        "price": 20.5,
        "size": "200ml.",
        "photo": "maska38.jpg",
        "__v": 0
    },
    {
        "_id": "5f5e74ed9f0ae66120eb1b5b",
        "brand": "Kaaral",
        "category": 74,
        "subcat": "Boya",
        "code": "Color Splash 7",
        "descr": "Ipək proteini və keratinlə zənginləştirilmiş piqment saçlara dəbdəbəli rənglər verir.",
        "madein": "İtaliya",
        "price": 32,
        "size": "200ml.",
        "photo": "maska39.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f46f39f0ae66120eb1b5c",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Bərpaedici şampun",
        "descr": "Keratin və hialuron tərkibli şampun zəif və kimyəvi təsirə məruz qalmış saçları yeniləyir və qidalandırır. Keratin və hialuron turşularının birləşməsi saçları dərindən bərpa edir və yaşlanmaya qarşı mübarizə aparır. Birinci istifadədən sonra saçlar canlı və sağlam görünüş alır.Tərkibində əlavə boya və SLS yoxdur. TƏRKİBİ: amin turşuları,hialuron turşusu,minerallar,vitaminlər",
        "code": "Kerayonic Restructuring Shampoo",
        "madein": "İtaliya",
        "price": 14,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri10.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f482a9f0ae66120eb1b5d",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Müalicəvi şampun",
        "descr": "Keratin  şampunu saçları intensiv bərpa  edir, amin turşularının unikal formulası zədəli saçlara dərindən təsir edərək, onların təbii quruluşunu yaxşılaşdırır, yulaf proteinləri və vitamin B saç örtüyunu yeniləyir və saçların uzanmasına kömək edir. TƏRKİBİ: amin turşuları, proteinlər, vitaminlər və s.",
        "code": "Therapy Repair Shampoo",
        "madein": "İtaliya",
        "price": 15.4,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri16.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f48459f0ae66120eb1b5e",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Müalicəvi şampun",
        "descr": "Keratin  şampunu saçları intensiv bərpa  edir, amin turşularının unikal formulası zədəli saçlara dərindən təsir edərək, onların təbii quruluşunu yaxşılaşdırır, yulaf proteinləri və vitamin B saç örtüyunu yeniləyir və saçların uzanmasına kömək edir. TƏRKİBİ: amin turşuları, proteinlər, vitaminlər və s.",
        "code": "Therapy Repair Shampoo",
        "madein": "İtaliya",
        "price": 22,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri17.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f49149f0ae66120eb1b5f",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Bərpaedici şampun",
        "descr": "Keratin və hialuron tərkibli şampun zəif və kimyəvi təsirə məruz qalmış saçları yeniləyir və qidalandırır. Keratin və hialuron turşularının birləşməsi saçları dərindən bərpa edir və yaşlanmaya qarşı vasitədir.Birinci istifadədən sonra saçlar canlı və sağlam görünüş alır. Tərkibində əlavə boya və SLS yoxdur. TƏRKİBİ:amin turşuları, hialuron turşusu, minerallar, vitaminlər",
        "code": "Kerayonic Shampoo",
        "madein": "İtaliya",
        "price": 25.2,
        "size": "1000ml.",
        "photo": "sacaqulluqvasiteleri11.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f4a0d9f0ae66120eb1b60",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Boya qoruyucu şampun",
        "descr": "Tərkibindəki dəniz elastinləri boyamadan sonra saçda qalan alkalin artıqlarını təmizləyir və boyanın axmasının qarşısını alır. Alfa hidroksid turşuları hüceyrə yenilənməsini stimullaşdırır və saçda nəmlənməni artırır. Saçlar daha elastik və parlaq görünür. İstifadə qaydası: Saçdakı boyanı yuduqdan sonra şampunu vurun və masaj edin. Şampun saça 2 dəfə tətbiq edilir. İkinci dəfə istifadədən sonra 2-3 dəqiqə saxlanılır və yuyulur.",
        "code": "Colour Save Shampoo",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri19.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f4b259f0ae66120eb1b61",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Qıvrım saçlar üçün tənzimləyici şampun",
        "descr": "Qıvrım, buruq, tabe olmayan saçları nəmləndirir və onları qaydaya salır. Dəridə olan quruluğu aradan qaldıraraq saçlara yumşaqlıq və həcm verir. E vitamini isə hüceyrə yenilənməsini təmin edir. Həmçinin şampunun tərkibində olan arqan yağı buruqları ağırlaşdırmadan, onlara ipək görünüşü bəxş edir.",
        "code": "Perfect Curls Defining Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri20.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f52169f0ae66120eb1b62",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Şampun",
        "descr": "Saç spreylərini, gelləri və digər məhsul artıqlarını saç köklərindən dərindən təmizləyir. Tərkibindəki komponentlər saç düzləşdirmə və saç burmadan əvvəl ölü dərinin uzunmüddətli təmizlənməsini təmin edir.",
        "code": "Salt-plus shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri21.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f52df9f0ae66120eb1b63",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Şampun",
        "descr": "Salt-free bütün dəri və saç tiplərinə uyğun olub, əsasən, düzləşdirmə və permanentdən sonra istifadəsi nəzərədə tutulmuşdur. Həmçinin tərkibində duz olmadığı üçün rənglənmədən sonra zədəyə məruz qalmış və zəifləmiş saçlara tətbiq olunur, eyni zamanda dəniz və hovuzdan sonra saça tətbiq olunması tövsiyyə olunur.",
        "code": "Salt-free shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri22.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f53a59f0ae66120eb1b64",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Kəpəyə qarşı şampun",
        "descr": "Gicitkən və propolis tərkibli şampun saçlarda olan kəpəyi və qıcıqlanmanı aradan qaldırır. Gicitkən saç dərisini stimullaşdırır, saç köklərini qidalandıraraq, onlara parlaqlıq verir. Propolis saçda olan kəpəkləri aradan qaldırır, həmçinin saçda yenidən kəpək yaranmasının qarşısını alır.",
        "code": "Anti-dandruff shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri23.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f53e99f0ae66120eb1b65",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Kəpəyə qarşı şampun",
        "descr": "Gicitkən və propolis tərkibli şampun saçlarda olan kəpəyi və qıcıqlanmanı aradan qaldırır. Gicitkən saç dərisini stimullaşdırır, saç köklərini qidalandıraraq, onlara parlaqlıq verir. Propolis saçda olan kəpəkləri aradan qaldırır, həmçinin saçda yenidən kəpək yaranmasının qarşısını alır.",
        "code": "Anti-dandruff shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml.",
        "photo": "sacaqulluqvasiteleri03.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f546c9f0ae66120eb1b66",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Yağlı qarşı şampun",
        "descr": "B6 vitamini ilə zəngin olan şampun yağ əmələ gəlməsinin qarşısını alaraq, yağlı saç dərisini normallaşdırır. Tərkibində olan oligoelementlər saçda hüceyrə yenilənməsini asanlaşdırır, saçları dərindən nəmləndirir və qan dövranını yaxşılaşdırır. İstifadə qaydası: yaş saça tətbiq edilərək, masaj edilir və daha sonra durulanır.",
        "code": "Freelimix oily hair, scalp shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml.",
        "photo": "sacaqulluqvasiteleri13.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f55989f0ae66120eb1b67",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Yeniləyici maska",
        "descr": "Keratin və hialuron turşusu saçın keratin təbəqəsinə dərindən təsir edir və zədələnmiş hissələri bərpa edir.Saça möhkəmlik verir və epiderm təbəqəsindəki məsamələri bağlayır. Maska saçı gücləndirir,havalandıraraq saçı daha möhkəm və parlaq göstərir. İstifadə qaydası: Yaş saça vurun, 5-10 dəqiqə saxlayın və yuyun.",
        "code": "Kerayonic restructuring mask",
        "madein": "İtaliya",
        "price": 16.3,
        "size": "250ml.",
        "photo": "sacaqulluqvasiteleri24.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f56c29f0ae66120eb1b68",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Yeniləyici krem",
        "descr": "Keratin tərkibli kristal-krem saçda qoruyucu təbəqə əmələ gətirərək, saçı bərpa edir və saçqıran əmələ gəlməsinin qarşısını alır. Saçlar parlaq görünüş alır. İstifadı qaydası: nəm və ya quru saçlara vurulur,istənilən forma verilir.",
        "code": "Kerayonic Restructuring Crystal Cream",
        "madein": "İtaliya",
        "price": 10,
        "size": "150ml.",
        "photo": "sacaqulluqvasiteleri25.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f5ba69f0ae66120eb1b69",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Yeniləyici krem",
        "descr": "Dəniz elastinləri ilə zənginləşdirilmiş maska,saç tellərinə dərindən nüfuz edərək,onları qidalandırır. Alfa hidroksid turşusu saçları nəmləndirir və qoruyur. Maska saç boyasının matlaşmasının qarşısını alır və saçları möhkəmləndirir. Istifadə qaydası: maskanı saça çəkin,3-5 dəqiqə saxlayın və yuyun.",
        "code": "Colour Save Mask",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "200ml.",
        "photo": "sacaqulluqvasiteleri26.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f5cad9f0ae66120eb1b6a",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Müalicəvi keratin kondisioneri",
        "descr": "Keratin kondisioneri saçları intensiv bərpa edir. Amin turşuları və bambuk ekstraktı ilə zəngindir.Saçları nəmləndirir və onlara parlaqlıq verir. İstifadə qaydası: yuyulmuş nəm  saça cəkilir ,3-5 dəqiqədən sonra  yuyulur.",
        "code": "Therapy repair cheratin conditioner",
        "madein": "İtaliya",
        "price": 14.5,
        "size": "150ml.",
        "photo": "sacaqulluqvasiteleri27.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f5ee29f0ae66120eb1b6b",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Qıvrımlar üçün tənzimləyici spray maska",
        "descr": "Maska arqan yağı, böyürtkən sirkəsi, keramidlər vasitəsilə saç dərisində olan quruluğu aradan qaldırır və buruqlara həcm, parlaqlıq, yumşaqlıq verir. Arqan yağı saçı nəmləndirərək saç buruqlarına ipək parlaqlığı bəxş edir. İstifadə qaydası: yuyulmuş nəm saçlara vurulur, bir neçə dəqiqə saxladıqdan sonra yuyulur.",
        "code": "Perfect Curls Defining spray mask",
        "madein": "İtaliya",
        "price": 7,
        "size": "200ml.",
        "photo": "sacaqulluqvasiteleri29.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f5f7f9f0ae66120eb1b6c",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Düzləşdirici spray maska",
        "descr": "Kənar elementlərin saça hopmasının qarşısını alır, saçın daxilinə nüfuz edərək zədələnmiş saçları bərpa edir, onlara təbii və güclü görüntü bəxş edir. Şaftalı ekstraktının nəmləndirici effekti saçı kənar təsirlərdən mühafizə edir. Quşburnu ekstraktı saçların asan düzləşdirilməsini təmin edir və saxlonlara uzunmüddətli düzlük bəxş edir. Istifadə qaydası: saçları şampunla yuduqdan sonra maska saça tətbiq edilir. Bir neçə dəqiqə saçda saxlanılır və daha sonra ilıq su ilə yuyulur.",
        "code": "Lixperfection Smoothing spray mask",
        "madein": "İtaliya",
        "price": 10,
        "size": "200ml.",
        "photo": "sacaqulluqvasiteleri30.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f616c9f0ae66120eb1b6d",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Balzam",
        "descr": "Haçalanmış saç uclarını bərpa edir , qidalandırır və saçqıranların yaranmasının qarşısını alır.Istifadə qaydası: yuyulmuş və dəsmal ilə qurudulmuş saçlara saç boyunca tətbiq edilir,bir neçə dəqiqə saxlanılır və yuyulur.",
        "code": "Freelimix Split-ends balm",
        "madein": "İtaliya",
        "price": 11,
        "size": "1000ml.",
        "photo": "balm01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f61e19f0ae66120eb1b6f",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Bərpaedici maska",
        "descr": "Maska quru və zədəli saçları amin turşuları ilə təmin edərək,onların elastikliyini və parlaqlığını geri qaytarır. Düyü proteinləri və E vitamini saçlara həcm və quruluş verir. Istifadə qaydası: yuyulmuş nəm saça tətbiq edin, masaj edin, 5 dəqiqə saxlanıldıqdan sonra yuyun.",
        "code": "Reconstruction mask",
        "madein": "İtaliya",
        "price": 15,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri28.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f62859f0ae66120eb1b70",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Enerji balzamı",
        "descr": "Balzam saçları lazım olan protein və vitaminlərlə təmin edir və sağlam görünüşünü geri qaytarır. Tərkibində olan xüsusi komponentlər saçları yumşaldır və asan daranmasına səbəb olur. Istifadə qaydası: yuyulmuş və dəsmal ilə qurudulmuş saçlara saç boyunca tətbiq edilir, bir neçə dəqiqə saxlanılır və yuyulur.",
        "code": "Energizing balm",
        "madein": "İtaliya",
        "price": 11,
        "size": "1000ml.",
        "photo": "balm02.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f62f39f0ae66120eb1b71",
        "brand": "Freelimix",
        "category": 73,
        "subcat": "Həcmverici və formaverici balzam",
        "descr": "Zəif və nazik saçlar üçün ideal vasitədir.  Saçları ağırlaşdırmadan onlara həcm verir və gücləndirir. Xüsusilə quru və zədələnmiş saçlar üçün nəzərdə tutulmuşdur. Istifadə qaydası: yuyulmuş və dəsmal ilə qurudulmuş saçlara saç boyunca tətbiq edilir,bir neçə dəqiqə saxlanılır və yuyulur.",
        "code": "Volume and shape balm",
        "madein": "İtaliya",
        "price": 11,
        "size": "1000ml.",
        "photo": "balm03.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5f700d9f0ae66120eb1b72",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Bərpaedici losyon",
        "descr": "Silikon komponentləri zəif saç tellərini yenidən canlandırır, saçlara parlaqlıq və yumşaqlıq verir. Xüsusilə kimyəvi təsirə məruz qalmış zədəli saçlar üçün idealdır. İstifadə qaydası: losyonu saç dərisinə çəkin, dairəvi hərəkətlə masaj edin (yumayın). Həftədə 2-3 dəfə tətbiq olunmalıdır.",
        "code": "Restructuring Treatment",
        "madein": "İtaliya",
        "price": 24,
        "size": "12x10ml.",
        "photo": "ampul01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5f701f9f0ae66120eb1b73",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Saç tökülməsinə qarşı ampul",
        "descr": "Losyon saç dərisindəki qan dövranını sürətləndirir və saç follikullarını qidalandırır. Saçlara canlılıq və möhkəmlik verir. Daha yaxşı nəticə üçün FreeLimix Hair Loss Shampoo ilə birlikdə istifadə olunması tövsiyyə olunur. İstifadə qaydası: Losyonu saç dərisinə çəkin, dairəvi masaj edin (yumayın). Həftədə 2-3 dəfə tətbiq olunması tövsiyyə olunur.",
        "code": "Hair Loss Adjuvant Treatment",
        "madein": "İtaliya",
        "price": 32,
        "size": "12x10ml.",
        "photo": "ampul02.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fad5029d32f55081c0ba5",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Qidalandırıcı maska",
        "descr": "Keratin kondisioneri saçları intensiv bərpa edir. Amin turşuları və bambuk ekstraktı ilə zəngindir. Saçları nəmləndirir və onlara intensiv  parlaqlıq verir. İstifadə qaydası: yuyulmuş nəm  saçlara çəkilir, 3-5 dəqiqədən sonra  yuyulur.",
        "code": "Nourishing mask",
        "madein": "İtaliya",
        "price": 14.5,
        "size": "500ml.",
        "photo": "sacaqulluqvasiteleri27.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fadc229d32f55081c0ba6",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Şampun",
        "descr": "Vitamin,mineral duzlar və sarımsaq ekstratı ile zengindir. Xüsusi olaraq,cansız və zədəli saclar ücün nəzərdə tutulmuşdur.",
        "code": "Garlic Vita Mineral",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml.",
        "photo": "shampoo42.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fb75529d32f55081c0ba7",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Bərpaedici şampun",
        "descr": "Düyü proteinləri və E vitamini saçlara həcm və dolğun görünüş verir. Kimyəvi təsirə məruz qalmış və zəif saçlar üçün ideal vasitədir.",
        "code": "Reconstruction Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml",
        "photo": "shampoo36.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fb92a29d32f55081c0ba8",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Gündəlik Şampun",
        "descr": "Tərkibindəki yulaf vitaminləri saçları təmizləyir və fizioloji balansını pozmadan nəmləndirir. Jojoba yağı saçları möhkəmləndirir və qidalandırır. Saçlar daha parlaq, yumşaq görünüş alır. Günlük istifadə üçün idealdır.",
        "code": "Frequent Wash Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml",
        "photo": "shampoo37.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fb93e29d32f55081c0ba9",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Gündəlik Şampun",
        "descr": "Tərkibindəki yulaf vitaminləri saçları təmizləyir və fizioloji balansını pozmadan nəmləndirir. Jojoba yağı saçları möhkəmləndirir və qidalandırır. Saçlar daha parlaq, yumşaq görünüş alır. Günlük istifadə üçün idealdır.",
        "code": "Frequent Wash Shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml",
        "photo": "shampoo37.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fbe8729d32f55081c0baa",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Yeniləyici Şampun",
        "descr": "Lavanda və nanə tərkibli şampun günlük müalicə üçün ideal vasitədir. Lavandanın antiseptik komponentləri saç rəngini bərpa edir və saçı rahatladır. Nanə isə saçları canlandırır və yumşaqlıq verir.",
        "code": "Refreshing Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml",
        "photo": "shampoo38.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fbea129d32f55081c0bab",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Yeniləyici Şampun",
        "descr": "Lavanda və nanə tərkibli şampun günlük müalicə üçün ideal vasitədir. Lavandanın antiseptik komponentləri saç rəngini bərpa edir və saçı rahatladır. Nanə isə saçları canlandırır və yumşaqlıq verir.",
        "code": "Refreshing Shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml",
        "photo": "shampoo39.jpg",
        "__v": 0
    },
    {
        "_id": "5f5fbfe729d32f55081c0bac",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Qidalandırıcı Şampun",
        "descr": "Gülümbahar və marakuya saçları qidalandırır və dərindən nəmləndirir. Gülümbaharın rahatlatıcı, qoruyucu xüsusiyyətləri saçın dərisini yeniləyir, rəngini və canlılığını bərpa edir. Marakuya ekstratı isə saçları nəmləndirir və yumşaqlıq verir.",
        "code": "Nourisihing Shampoo",
        "madein": "İtaliya",
        "price": 9,
        "size": "250ml",
        "photo": "shampoo41.png",
        "__v": 0
    },
    {
        "_id": "5f5fbffd29d32f55081c0bad",
        "brand": "Freelimix",
        "category": 71,
        "subcat": "Qidalandırıcı Şampun",
        "descr": "Gülümbahar və marakuya saçları qidalandırır və dərindən nəmləndirir. Gülümbaharın rahatlatıcı, qoruyucu xüsusiyyətləri saçın dərisini yeniləyir, rəngini və canlılığını bərpa edir. Marakuya ekstratı isə saçları nəmləndirir və yumşaqlıq verir.",
        "code": "Nourisihing Shampoo",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml",
        "photo": "shampoo40.jpeg",
        "__v": 0
    },
    {
        "_id": "5f5fc30629d32f55081c0bae",
        "brand": "Freelimix",
        "category": 72,
        "subcat": "Zədələnmiş saçlar üçün müalicəvi maska",
        "descr": "Sarımsaqdan alınmış vitaminlər və mineral duzlar kimyəvi təsirə qalmış zədəli saçlar üçün ideal vasitədir. Saçı bərpa edir, yumşaqlıq və parlaqlıq verir. İstifadə qaydası: Saçlarınızı Vita-Mineral şampunu ilə yuduqdan sonra saç uclarına qədər çəkin, masaj edin, 5 dəqiqə gözləyin, ilıq su ilə yuyun.",
        "code": "Vita-Mineral",
        "madein": "İtaliya",
        "price": 16,
        "size": "1000ml",
        "photo": "maska66.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e78c29d32f55081c0baf",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Apex Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 9.5,
        "size": "150ml",
        "photo": "baximseriyasi01.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e7ab29d32f55081c0bb0",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Dynamic Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 10.8,
        "size": "150ml",
        "photo": "baximseriyasi02.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e80e29d32f55081c0bb1",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Gravity Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 11.4,
        "size": "150ml",
        "photo": "baximseriyasi03.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e82e29d32f55081c0bb2",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Matte Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 10.9,
        "size": "150ml",
        "photo": "baximseriyasi04.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e84e29d32f55081c0bb3",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç geli",
        "code": "Mnemonic Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 7.7,
        "size": "250ml",
        "photo": "baximseriyasi05.png",
        "__v": 0
    },
    {
        "_id": "5f60e86929d32f55081c0bb4",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç wax’ı",
        "code": "Resist Hair Styling Wax",
        "madein": "Türkiyə",
        "price": 8.2,
        "size": "150ml",
        "photo": "baximseriyasi06.png",
        "__v": 0
    },
    {
        "_id": "5f60e89629d32f55081c0bb5",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Losyon",
        "code": "Black Fig Eau De Cologne",
        "madein": "Türkiyə",
        "price": 8.9,
        "size": "370ml",
        "photo": "baximseriyasi07.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e8a629d32f55081c0bb6",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Losyon",
        "code": "Fresh Lemon Eau De Cologne",
        "madein": "Türkiyə",
        "price": 8.9,
        "size": "370ml",
        "photo": "baximseriyasi08.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e8c929d32f55081c0bb7",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Krem Losyon",
        "code": "Blue Ice After Shave Cream Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi09.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e8ee29d32f55081c0bb8",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Krem Losyon",
        "code": "Kick Start After Shave Cream Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi10.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e90929d32f55081c0bb9",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Krem Losyon",
        "code": "Shine Out After Shave Cream Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi11.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e93029d32f55081c0bba",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Losyon",
        "code": "Premium After Shave Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi12.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e93e29d32f55081c0bbb",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təraşdan sonra Losyon",
        "code": "Platinum After Shave Cologne",
        "madein": "Türkiyə",
        "price": 14.3,
        "size": "370ml",
        "photo": "baximseriyasi13.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e95729d32f55081c0bbc",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saç üçün Losyon",
        "code": "Cocomama After Shave Cologne",
        "madein": "Türkiyə",
        "price": 13,
        "size": "260ml",
        "photo": "baximseriyasi14.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e97a29d32f55081c0bbd",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Şampun",
        "code": "Extension Hair Shampoo",
        "madein": "Türkiyə",
        "price": 18.6,
        "size": "370ml",
        "photo": "baximseriyasi15.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e9a629d32f55081c0bbe",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Şampun",
        "code": "Pro Active Hair Shampoo",
        "madein": "Türkiyə",
        "price": 17.5,
        "size": "370ml",
        "photo": "baximseriyasi16.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e9da29d32f55081c0bbf",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Şampun",
        "code": "Thick & Strong Hair Shampoo",
        "madein": "Türkiyə",
        "price": 7,
        "size": "260ml",
        "photo": "baximseriyasi17.jpg",
        "__v": 0
    },
    {
        "_id": "5f60e9f829d32f55081c0bc0",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Üz kremi",
        "code": "Lucky Blue Face Cream",
        "madein": "Türkiyə",
        "price": 13,
        "size": "275ml",
        "photo": "baximseriyasi18.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ea1a29d32f55081c0bc1",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Üz üçün gil maska",
        "code": "Mud Face Clay Mask",
        "madein": "Türkiyə",
        "price": 16.4,
        "size": "250ml",
        "photo": "baximseriyasi19.jpg",
        "__v": 0
    },
    {
        "_id": "5f60eb0c29d32f55081c0bc2",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saqqal yağı",
        "code": "Mustache Hipster Beard",
        "madein": "Türkiyə",
        "price": 13.5,
        "size": "75ml",
        "photo": "baximseriyasi20.jpg",
        "__v": 0
    },
    {
        "_id": "5f60eb2329d32f55081c0bc3",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saqqal şampunu",
        "code": "Mustache Hipster Beard Shampoo",
        "madein": "Türkiyə",
        "price": 11.3,
        "size": "230ml",
        "photo": "baximseriyasi21.jpg",
        "__v": 0
    },
    {
        "_id": "5f60eb4029d32f55081c0bc4",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saqqal kondisioneri",
        "code": "Mustache Hipster Conditioner",
        "madein": "Türkiyə",
        "price": 11.8,
        "size": "125ml",
        "photo": "baximseriyasi22.jpg",
        "__v": 0
    },
    {
        "_id": "5f60eb9829d32f55081c0bc5",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təmizləyici üz losyonu",
        "code": "Relief Face Tonic",
        "madein": "Türkiyə",
        "price": 11.5,
        "size": "260ml",
        "photo": "baximseriyasi24.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ec0d29d32f55081c0bc6",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Təmizləyici üz losyonu",
        "code": "Təraş üçün gel",
        "madein": "Türkiyə",
        "price": 14,
        "size": "1000ml",
        "photo": "baximseriyasi25.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ecaa29d32f55081c0bc7",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Duş geli",
        "code": "Shower Body Gel",
        "madein": "Türkiyə",
        "price": 10.5,
        "size": "370ml",
        "photo": "baximseriyasi26.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ecc229d32f55081c0bc8",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Üz skrabı",
        "code": "Showy Face Scrub",
        "madein": "Türkiyə",
        "price": 16.3,
        "size": "250ml",
        "photo": "baximseriyasi27.jpg",
        "__v": 0
    },
    {
        "_id": "5f60ed5c29d32f55081c0bc9",
        "brand": "Vasso",
        "category": 76,
        "subcat": "Saqqal yağı(Təraşdan əvvəl)",
        "code": "Mustache Hipster Pre Shave Oil",
        "madein": "Türkiyə",
        "price": 18.5,
        "size": "75ml",
        "photo": "baximseriyasi23.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f22929d32f55081c0bca",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Titanium tərkibli mum",
        "madein": "İtaliya",
        "price": 9,
        "size": "400ml",
        "photo": "mum01.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f24829d32f55081c0bcb",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Bal tərkibli mum",
        "madein": "İtaliya",
        "price": 9,
        "size": "400ml",
        "photo": "mum02.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f25829d32f55081c0bcc",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Xlorofil tərkibli mum",
        "madein": "İtaliya",
        "price": 9,
        "size": "400ml",
        "photo": "mum03.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f27329d32f55081c0bcd",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Azulen tərkibli mum",
        "madein": "İtaliya",
        "price": 9,
        "size": "400ml",
        "photo": "mum04.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f29e29d32f55081c0bce",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün şuqarinq mum",
        "code": "Sugaring Spatula mum",
        "madein": "İtaliya",
        "price": 8.5,
        "size": "500ml",
        "photo": "mum05.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f2b129d32f55081c0bcf",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün şuqarinq mum",
        "code": "Sugaring Paste mum",
        "madein": "İtaliya",
        "price": 8.5,
        "size": "500ml",
        "photo": "mum06.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f2de29d32f55081c0bd0",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Titanium tərkibli mum",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "800ml",
        "photo": "mum07.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f2fd29d32f55081c0bd1",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Bal tərkibli mum",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "800ml",
        "photo": "mum08.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f30d29d32f55081c0bd2",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Xlorofil tərkibli mum",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "800ml",
        "photo": "mum09.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f32629d32f55081c0bd3",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün mum (Kağızla qopan)",
        "code": "Azulen tərkibli mum",
        "madein": "İtaliya",
        "price": 16.5,
        "size": "800ml",
        "photo": "mum10.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f34629d32f55081c0bd4",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün şuqarinq mum",
        "code": "Sugaring Spatula mum",
        "madein": "İtaliya",
        "price": 17,
        "size": "1000ml",
        "photo": "mum11.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f37229d32f55081c0bd5",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün şuqarinq mum",
        "code": "Sugaring Paste mum",
        "madein": "İtaliya",
        "price": 17,
        "size": "1000ml",
        "photo": "mum12.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f3ac29d32f55081c0bd6",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Titanium tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "1000ml",
        "photo": "mum13.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f3ce29d32f55081c0bd7",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Bal tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "100ml",
        "photo": "mum14.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f40229d32f55081c0bd8",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Talk tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "100ml",
        "photo": "mum15.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f41729d32f55081c0bd9",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Gold tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "100ml",
        "photo": "mum16.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f42c29d32f55081c0bda",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün kartric mum",
        "code": "Xlorofil tərkibli Kartric mum",
        "madein": "İtaliya",
        "price": 3.5,
        "size": "100ml",
        "photo": "mum17.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f44e29d32f55081c0bdb",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Titanium tərkibli Disk mum",
        "madein": "İtaliya",
        "price": 11,
        "size": "400qr",
        "photo": "mum18.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f45d29d32f55081c0bdc",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Bal tərkibli Disk mum",
        "madein": "İtaliya",
        "price": 11,
        "size": "400qr",
        "photo": "mum19.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f47e29d32f55081c0bdd",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Titanium tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum20.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f49129d32f55081c0bde",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Bal tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum21.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f4a529d32f55081c0bdf",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Azulen tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum22.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f4ef29d32f55081c0be0",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Xlorofil tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum23.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f50829d32f55081c0be1",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün isti mum",
        "code": "Şokolad tərkibli isti mum",
        "madein": "İtaliya",
        "price": 9.5,
        "size": "500qr",
        "photo": "mum24.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f52029d32f55081c0be2",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Bal tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum25.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f53629d32f55081c0be3",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Xlorofil tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum26.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f54729d32f55081c0be4",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Azulen tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum27.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f55729d32f55081c0be5",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Titanium tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum28.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f57029d32f55081c0be6",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya üçün disk mum",
        "code": "Şokolad tərkibli disk mum",
        "madein": "İtaliya",
        "price": 19,
        "size": "1000qr",
        "photo": "mum29.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f5ac29d32f55081c0be7",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya sonrası yağ",
        "code": "Arqan tərkibli depilyasiya sonrası yağ",
        "madein": "İtaliya",
        "price": 13,
        "size": "500ml",
        "photo": "mum30.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f5ca29d32f55081c0be8",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya sonrası yağ",
        "code": "Evkalipt tərkibli depilyasiya sonrası yağ",
        "madein": "İtaliya",
        "price": 13,
        "size": "500ml",
        "photo": "mum31.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f5cf29d32f55081c0be9",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya sonrası yağ",
        "code": "Çobanyastığı tərkibli depilyasiya sonrası yağ",
        "madein": "İtaliya",
        "price": 13,
        "size": "500ml",
        "photo": "mum32.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f5d729d32f55081c0bea",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Depilyasiya sonrası yağ",
        "code": "Aloe Vera tərkibli depilyasiya sonrası yağ",
        "madein": "İtaliya",
        "price": 13,
        "size": "500ml",
        "photo": "mum33.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f78729d32f55081c0beb",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Parafin",
        "code": "Neutral tərkibli paraffin",
        "madein": "İtaliya",
        "price": 10,
        "size": "500ml",
        "photo": "parafin01.jpg",
        "__v": 0
    },
    {
        "_id": "5f60f7b629d32f55081c0bec",
        "brand": "Xanitalia",
        "category": 81,
        "subcat": "Parafin",
        "code": "Şaftalı tərkibli paraffin",
        "madein": "İtaliya",
        "price": 10,
        "size": "500ml",
        "photo": "parafin02.jpg",
        "__v": 0
    },
    {
        "_id": "5f6511740f313379283cf18c",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1001L",
        "madein": "Azərbaycan",
        "price": 140,
        "size": "110*52*73sm",
        "photo": "manic01.jpg",
        "material": "Laminant",
        "__v": 0
    },
    {
        "_id": "5f6511ed0f313379283cf18d",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1002M",
        "madein": "Azərbaycan",
        "price": 570,
        "size": "135*50*75sm",
        "photo": "manic02.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6512510f313379283cf18e",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1003LM",
        "madein": "Azərbaycan",
        "price": 250,
        "size": "120*55*75sm",
        "photo": "manic03.jpg",
        "material": "Laminat / MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6512980f313379283cf18f",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1004M",
        "madein": "Azərbaycan",
        "price": 600,
        "size": "135*50*75sm",
        "photo": "manic04.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6512d80f313379283cf190",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Manikür Masası",
        "code": "1005M",
        "madein": "Azərbaycan",
        "price": 450,
        "size": "120*50*75sm",
        "photo": "manic05.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f65139d0f313379283cf192",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Dırnaq Boyası Rəfi",
        "code": "1007RM",
        "madein": "Azərbaycan",
        "price": 70,
        "size": "50*10*80sm",
        "photo": "manic07.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6513cb0f313379283cf193",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Dırnaq Boyası Rəfi",
        "code": "1008RM",
        "madein": "Azərbaycan",
        "price": 90,
        "size": "50*10*80sm",
        "photo": "manic08.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f6513e80f313379283cf194",
        "brand": "Aksa Cosmetics",
        "category": 43,
        "subcat": "Dırnaq Boyası Rəfi",
        "code": "1009RM",
        "madein": "Azərbaycan",
        "price": 90,
        "size": "50*10*80sm",
        "photo": "manic09.jpg",
        "material": "MDF boya",
        "__v": 0
    },
    {
        "_id": "5f68999219fdff1a747e8bd1",
        "brand": "FreeLimix",
        "category": 75,
        "subcat": "Nazik saçlar üçün yağ",
        "descr": "FreeLimix Delicate and Thin hair Linseed Oil saçlara güç və həcm verərək onları qidalandırır və canlandırır. Saçları xüsusi təbəqə ilə örtərək atmosferin təsirlərindən qoruyur. Xüsusilə qurulamadan öncə istifadə olunması tövsiyyə olunur. İstifadə qaydası: Nəm saçlara 4-5 damcı tətbiq olunur. Ehtiyac olduğu halda qurulamadan sonra 3-4 damcı istifadə oluna bilər.",
        "code": "Delicate & Thin hair Linseed Oil",
        "madein": "Italiya",
        "price": 16.9,
        "size": "100ml",
        "photo": "oil22.jpg",
        "__v": 0
    },
    {
        "_id": "5f689e4d19fdff1a747e8bd2",
        "brand": "FreeLimix",
        "category": 75,
        "subcat": "Saç yağı",
        "descr": "FreeLimix Frizzy və Flyaway Hair Linseed Oil (Uçan saçlar üçün kətan yağı) saçı quruluğa qarşı qoruyur və düzləşdirməni asanlaşdıraraq qıvrım saçları kontrol altına alır. Yaxşı daranmanı təmin etmək xüsusiyyəti sayəsində saçlar yumşaq, hamar və ipəksayağı olur. Onları xüsusi təbəqə ilə örtərək atmosferin təsirlərindən qoruyur. İstifadə qaydası: nəm saça 4-5 damcı tətbiq edilir. Ehtiyac olarsa, qurulamadan sonra 3-4 damcı istifadə edilir.",
        "code": "Oil for Dry Frizzy & Flyaway Hair",
        "madein": "Italiya",
        "price": 16.9,
        "size": "100ml",
        "photo": "oil23.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a08419fdff1a747e8bd3",
        "brand": "FreeLimix",
        "category": 75,
        "subcat": "Saç yağı",
        "descr": "Uzun saçlar üçün əla vasitə olmaqla, FreeLimix End and Length Rebuilding Linseed Oil onlara parlaqlıq və güc verir. Haçalanmış ucları ağırlaşdırmadan parlaqlıq və yumşaqlıq verərək bərpa edir və onları xüsusi təbəqə ilə örtərək atmosferin təsbrərindən qoruyur. İstifadə qaydası: nəm saça 4-5 damcı tətbiq edilir. Ehtiyac olarsa qurulamadan sonra 3-4 damcı istifadə edilir.",
        "code": "Ends and Length Rebuilding Linseed Oil",
        "madein": "Italiya",
        "price": 18,
        "size": "100ml",
        "photo": "oil24.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a40819fdff1a747e8bd4",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Saç və bədən üçün qel",
        "descr": "Free Limix Sun Summer xüsusi tərkibilə günəşin bronzlaşma təsirini itirmədən dərini təmizləyir. Tərkibindəki mentol kristalları günəşin təsirinə məruz qalmış saç və bədəni təravətləndirir, təzələyir və enerji verir. Alantoin maddəsi öz antioksidant gücü ilə sərbəst radikallarla mübarizə aparır. Günəş, külək və duz nəticəsində yaranmış dəri zədələnmələrini nəmləndirir və bərpa edir.",
        "code": "Shower Gelsun",
        "madein": "Italiya",
        "price": 13,
        "size": "250ml",
        "photo": "shower1.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a49f19fdff1a747e8bd5",
        "brand": "FreeLimix",
        "category": 75,
        "subcat": "Bədən və saç üçün yağ",
        "descr": "Saç və dəri üçün günəş filtri olan Free Limix Sun Summer biphasic oil (Free Limix Sun Summer iki mərhələli yağ) tərkibindəki  aloe və E vitamini sayəsində dərini və saçı yağlandırmadan yumşaqlıq və parlaqlıq verir. Aloenin nəmləndirici və sakitləşdirici təsiri, radiasiyadan qoruyucu günəş filtri və vitamin E-nin antioksidant gücü nəmliyini itirmiş quru saç və dəri üçün optimal məhsuldur. Günəş filtrinin olması duza qarşı təsir etmə vəzifəsini yerinə yetirir. İstifadə qaydası: məhsul günəş təsirinə məruz qalmadan və ya günəş vannası qəbul etmədən öncə istifadə olunur.",
        "code": "Bi-Sun Biphasic",
        "madein": "Italiya",
        "price": 25.7,
        "size": "250ml",
        "photo": "shower2.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a81419fdff1a747e8bd6",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Saç Tökülməsinə qarşı şampun",
        "descr": "FreeLimix Grow şampun saç tökülməsinə qarşı effektiv məhsuldur. Onun zəngin elementlərdən ibarət formulu hər saç növü üçün tövsiyyə edilir və gündəlik istifadə üçün ideal vasitədir. Şampun, qan dövranını sürətləndirərək, dərinin fizioloji balansını yenidən bərpa edir və saçın təbii gücünü geri qaytarır. İstifadə qaydası: nəm saça tətbiq edin. Yaxalayın.",
        "code": "Grow Hair Loss",
        "madein": "Italiya",
        "price": 18,
        "size": "1000ml",
        "photo": "shampoo43.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a91919fdff1a747e8bd7",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Saç Tökülməsinə qarşı şampun",
        "descr": "FreeLimix Grow şampun saç tökülməsinə qarşı effektiv məhsuldur. Onun zəngin elementlərdən ibarət formulu hər saç növü üçün tövsiyyə edilir və gündəlik istifadə üçün ideal vasitədir. Şampun, qan dövranını sürətləndirərək, dərinin fizioloji balansını yenidən bərpa edir və saçın təbii gücünü geri qaytarır. İstifadə qaydası: nəm saça tətbiq edin. Yaxalayın.",
        "code": "Grow Hair Loss",
        "madein": "Italiya",
        "price": 12,
        "size": "250ml",
        "photo": "shampoo43.jpg",
        "__v": 0
    },
    {
        "_id": "5f68a9ba19fdff1a747e8bd8",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Bio şampun",
        "descr": "Bio Struct şampunu zədələnmiş, cod və masaməli saçlar üçündür. Təbii qəhvəyi yosun və aqave nektarı ilə zəngin şampun, saç strukturunu yaxşılaşdırır, kapilyar lif dözümlülüyünü təmin edir və xarici təsirlərdən qoruyur. Saça parlaqlıq, nəmlilik verir və yeniləyir.İstifadə qaydası: dəsmalla qurulanmış saç boyu və uclara tətbiq edin. Darayıb 3-5 dəqiqə saxlayın. Yaxşı yaxalayın.",
        "code": "Bio Struct Shampoo",
        "madein": "Italiya",
        "price": 23.5,
        "size": "500ml",
        "photo": "shampoo44.jpeg",
        "__v": 0
    },
    {
        "_id": "5f68aa8e19fdff1a747e8bd9",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Dolğu şampunu",
        "descr": "Botutech şampun nazik və cansız saçlara dolğu effekti verir. Tərkibindəki hialuron turşusu, keramidlər və üzüm sapı elementləri saçları yumşaldır və həcm verir. Nazik saç tellərini ağırlaşdırmadan nəmləndirir və qoruyur. Şampundan müntəzəm istifadə nəticəsində saçlar yumşaqlıq, elastiklik və təbii parlaqlıq qazanır. Optimal nəticə üçün şampunu digər Botutech məhsulları ilə birgə istifadə etmək tövsiyyə olunur. İstifadə qaydası: məhsulu nəm saça tətbiq edin və durulayın.",
        "code": "Botutech Filler Shampoo",
        "madein": "Italiya",
        "price": 16,
        "size": "250ml",
        "photo": "shampoo45.jpeg",
        "__v": 0
    },
    {
        "_id": "5f68aad819fdff1a747e8bda",
        "brand": "FreeLimix",
        "category": 71,
        "subcat": "Dolğu şampunu",
        "descr": "Botutech şampun nazik və cansız saçlara dolğu effekti verir. Tərkibindəki hialuron turşusu, keramidlər və üzüm sapı elementləri saçları yumşaldır və həcm verir. Nazik saç tellərini ağırlaşdırmadan nəmləndirir və qoruyur. Şampundan müntəzəm istifadə nəticəsində saçlar yumşaqlıq, elastiklik və təbii parlaqlıq qazanır. Optimal nəticə üçün şampunu digər Botutech məhsulları ilə birgə istifadə etmək tövsiyyə olunur. İstifadə qaydası: məhsulu nəm saça tətbiq edin və durulayın.",
        "code": "Botutech Filler Shampoo",
        "madein": "Italiya",
        "price": 25,
        "size": "1000ml",
        "photo": "shampoo46.jpeg",
        "__v": 0
    },
    {
        "_id": "5f68accf19fdff1a747e8bdb",
        "brand": "FreeLimix",
        "category": 73,
        "subcat": "Dolğu kondisioner",
        "descr": "Botutech Hair concept sprey kondisioner saç qalınlığını tez müddətdə artırır, ağırlaşdırmadan onlara canlılıq və ipək görünüşü verir. İstifadə qaydası: Bir neçə damcı dəsmalla qurulanmış saçlara tətbiq edin, zərif masaj hərəkətləri ilə saça hopdurun, 1-2 dəqiqə saçda saxlayın, ilıq su ilə nəmləndirərək darayın və yaxalayın.",
        "code": "Botutech Filler Conditoiner",
        "madein": "Italiya",
        "price": 19.8,
        "size": "200ml",
        "photo": "botutech01.jpeg",
        "__v": 0
    },
    {
        "_id": "5f68adf319fdff1a747e8bdc",
        "brand": "FreeLimix",
        "category": 72,
        "subcat": "Saç Dolğunlaşdırıcı Gel",
        "descr": "Tərkibindəki hialuron turşusu, keramidlər və üzüm sapı elementləri sayəsində Botutech Filler saçın daxilinə işləyərək, onu yenidən bərpa edir, saç liflərini gücləndirir və elastiklik verir. Saçlara dərhal qalınlıq effekti verir. İstifadə qaydası: Tam hopdurana qədər tətbiq edin, darayın, 5 dəqiqə saxlayın, durulamayın, Filler System Kondisioner tətbiq edin.",
        "code": "Botutech Filler Gel",
        "madein": "Italiya",
        "price": 25.9,
        "size": "150ml",
        "photo": "botutech02.jpeg",
        "__v": 0
    },
    {
        "_id": "5f6a0b5e19fdff1a747e8bdd",
        "brand": "Silver Fox",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1014",
        "madein": "Çin",
        "price": 200,
        "size": "54*40*83",
        "photo": "ctroller01.jpg",
        "material": "Dəmir / Şüşə",
        "__v": 0
    },
    {
        "_id": "5f6a0b9919fdff1a747e8bde",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1010L",
        "madein": "Azərbaycan",
        "price": 160,
        "size": "57*40*87",
        "photo": "ctroller02.jpg",
        "material": "Laminat",
        "__v": 0
    },
    {
        "_id": "5f6a0bcb19fdff1a747e8bdf",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1011DL",
        "madein": "Azərbaycan",
        "price": 200,
        "size": "55*35*87.5",
        "photo": "ctroller03.png",
        "material": "Dəmir / Laminat",
        "__v": 0
    },
    {
        "_id": "5f6a0c0c19fdff1a747e8be0",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1012L",
        "madein": "Azərbaycan",
        "price": 80,
        "size": "57*40*87",
        "photo": "ctroller04.jpg",
        "material": "Laminat",
        "__v": 0
    },
    {
        "_id": "5f6a0c2d19fdff1a747e8be1",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1013L",
        "madein": "Azərbaycan",
        "price": 160,
        "size": "40*38.5*83.5",
        "photo": "ctroller05.jpg",
        "material": "Laminat",
        "__v": 0
    },
    {
        "_id": "5f6a0c4419fdff1a747e8be2",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1015L",
        "madein": "Azərbaycan",
        "price": 160,
        "size": "50*40*87",
        "photo": "ctroller06.jpg",
        "material": "Laminat",
        "__v": 0
    }
]
