import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class CosmeticBeds extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f6a0b5e19fdff1a747e8bdd",
        "brand": "Silver Fox",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1014",
        "madein": "Çin",
        "price": 200,
        "size": "54*40*83",
        "photo": "ctroller01.jpg",
        "material": "Dəmir / Şüşə",
        "__v": 0
    },
    {
        "_id": "5f6a0b9919fdff1a747e8bde",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1010L",
        "madein": "Azərbaycan",
        "price": 160,
        "size": "57*40*87",
        "photo": "ctroller02.jpg",
        "material": "Laminat",
        "__v": 0
    },
    {
        "_id": "5f6a0bcb19fdff1a747e8bdf",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1011DL",
        "madein": "Azərbaycan",
        "price": 200,
        "size": "55*35*87.5",
        "photo": "ctroller03.png",
        "material": "Dəmir / Laminat",
        "__v": 0
    },
    {
        "_id": "5f6a0c0c19fdff1a747e8be0",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1012L",
        "madein": "Azərbaycan",
        "price": 80,
        "size": "57*40*87",
        "photo": "ctroller04.jpg",
        "material": "Laminat",
        "__v": 0
    },
    {
        "_id": "5f6a0c2d19fdff1a747e8be1",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1013L",
        "madein": "Azərbaycan",
        "price": 160,
        "size": "40*38.5*83.5",
        "photo": "ctroller05.jpg",
        "material": "Laminat",
        "__v": 0
    },
    {
        "_id": "5f6a0c4419fdff1a747e8be2",
        "brand": "Aksa Cosmetics",
        "category": 62,
        "subcat": "Kosmetoloji Troller",
        "code": "1015L",
        "madein": "Azərbaycan",
        "price": 160,
        "size": "50*40*87",
        "photo": "ctroller06.jpg",
        "material": "Laminat",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(
          <Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>
        );
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Kosmetoloji Arabalar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
