import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Brushes extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f4cdba895919438c44b3344",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1401",
        "madein": "Italiya",
        "price": 14.2,
        "photo": "daraq01.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cdbbf95919438c44b3345",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1402",
        "madein": "Italiya",
        "price": 14.2,
        "photo": "daraq02.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cdbca95919438c44b3346",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1403",
        "madein": "Italiya",
        "price": 17.6,
        "photo": "daraq03.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cde3495919438c44b3347",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1404",
        "madein": "Italiya",
        "price": 17.6,
        "photo": "daraq04.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cde6595919438c44b3348",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1406",
        "madein": "Italiya",
        "price": 10.3,
        "photo": "daraq05.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cde7995919438c44b3349",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1407",
        "madein": "Italiya",
        "price": 10.3,
        "photo": "daraq06.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cde9495919438c44b334a",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1408",
        "madein": "Italiya",
        "price": 13.6,
        "photo": "daraq07.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cdee595919438c44b334b",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14101",
        "madein": "Italiya",
        "price": 25.1,
        "photo": "daraq08.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce06195919438c44b334c",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1413",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq09.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce06e95919438c44b334d",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1414",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq10.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce08695919438c44b334e",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1415",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq11.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce0c595919438c44b334f",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1430",
        "madein": "Italiya",
        "price": 26.3,
        "photo": "daraq12.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce0ef95919438c44b3350",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14301",
        "madein": "Italiya",
        "price": 29.4,
        "photo": "daraq13.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce10795919438c44b3351",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14302",
        "madein": "Italiya",
        "price": 36.9,
        "photo": "daraq14.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce14295919438c44b3352",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14478",
        "madein": "Italiya",
        "price": 33.9,
        "photo": "daraq15.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce17795919438c44b3353",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14488",
        "madein": "Italiya",
        "price": 38.1,
        "photo": "daraq16.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce19295919438c44b3354",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "14498",
        "madein": "Italiya",
        "price": 42.4,
        "photo": "daraq17.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce1a895919438c44b3355",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1405",
        "madein": "Italiya",
        "price": 25.9,
        "photo": "daraq18.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce1c695919438c44b3356",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1411",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq19.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce1d795919438c44b3357",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "1412",
        "madein": "Italiya",
        "price": 10.5,
        "photo": "daraq20.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce1f095919438c44b3358",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2901",
        "madein": "Italiya",
        "price": 13.4,
        "photo": "daraq21.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce21795919438c44b3359",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2902",
        "madein": "Italiya",
        "price": 13.4,
        "photo": "daraq22.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce23795919438c44b335a",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2903",
        "madein": "Italiya",
        "price": 16,
        "photo": "daraq23.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce27b95919438c44b335b",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2904",
        "madein": "Italiya",
        "price": 16,
        "photo": "daraq24.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce29195919438c44b335c",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2905",
        "madein": "Italiya",
        "price": 22.2,
        "photo": "daraq25.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce2af95919438c44b335d",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2906",
        "madein": "Italiya",
        "price": 28.1,
        "photo": "daraq26.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce2da95919438c44b335e",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2907",
        "madein": "Italiya",
        "price": 35.1,
        "photo": "daraq27.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce2f495919438c44b335f",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2014",
        "madein": "Italiya",
        "price": 9.4,
        "photo": "daraq28.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce30c95919438c44b3360",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2015",
        "madein": "Italiya",
        "price": 9.4,
        "photo": "daraq29.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce32995919438c44b3361",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2201",
        "madein": "Italiya",
        "price": 19.7,
        "photo": "daraq30.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce35b95919438c44b3362",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2202",
        "madein": "Italiya",
        "price": 20.3,
        "photo": "daraq31.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce37f95919438c44b3363",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2203",
        "madein": "Italiya",
        "price": 21.2,
        "photo": "daraq32.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce3c295919438c44b3364",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2204",
        "madein": "Italiya",
        "price": 26,
        "photo": "daraq33.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce3db95919438c44b3365",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2205",
        "madein": "Italiya",
        "price": 27.8,
        "photo": "daraq34.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce41095919438c44b3366",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "3203",
        "madein": "Italiya",
        "price": 16.6,
        "photo": "daraq35.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce42995919438c44b3367",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "3205",
        "madein": "Italiya",
        "price": 21.6,
        "photo": "daraq36.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce45695919438c44b3368",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "3206",
        "madein": "Italiya",
        "price": 28,
        "photo": "daraq37.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce46d95919438c44b3369",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "3492",
        "madein": "Italiya",
        "price": 28.7,
        "photo": "daraq38.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce47e95919438c44b336a",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Qaynaqlı Saçlar üçün Fırça Daraq",
        "code": "3420",
        "madein": "Italiya",
        "price": 32.8,
        "photo": "daraq39.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce4aa95919438c44b336b",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "3445",
        "madein": "Italiya",
        "price": 24.4,
        "photo": "daraq40.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ce57c95919438c44b336c",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "2555",
        "madein": "Italiya",
        "price": 37.5,
        "photo": "daraq41.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce59495919438c44b336d",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "2556",
        "madein": "Italiya",
        "price": 39.6,
        "photo": "daraq42.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5ab95919438c44b336e",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "2557",
        "madein": "Italiya",
        "price": 40.1,
        "photo": "daraq43.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5ba95919438c44b336f",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "2558",
        "madein": "Italiya",
        "price": 42,
        "photo": "daraq44.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5d395919438c44b3370",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2601",
        "madein": "Italiya",
        "price": 21,
        "photo": "daraq45.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5e895919438c44b3371",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2602",
        "madein": "Italiya",
        "price": 22.2,
        "photo": "daraq46.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce5fc95919438c44b3372",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2603",
        "madein": "Italiya",
        "price": 23.4,
        "photo": "daraq47.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce62395919438c44b3373",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2604",
        "madein": "Italiya",
        "price": 24.4,
        "photo": "daraq48.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4ce63395919438c44b3374",
        "brand": "Maestri",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "2605",
        "madein": "Italiya",
        "price": 28,
        "photo": "daraq49.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4cec2c95919438c44b337b",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5720",
        "madein": "Italiya",
        "price": 15.6,
        "photo": "daraq56.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cec9f95919438c44b337c",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5725",
        "madein": "Italiya",
        "price": 15.8,
        "photo": "daraq57.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ced0895919438c44b337d",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5733",
        "madein": "Italiya",
        "price": 16.7,
        "photo": "daraq58.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ced2095919438c44b337e",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5743",
        "madein": "Italiya",
        "price": 19,
        "photo": "daraq59.jpg",
        "__v": 0
    },
    {
        "_id": "5f4ced4695919438c44b337f",
        "brand": "Kiepe",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "5545",
        "madein": "Italiya",
        "price": 16.3,
        "photo": "daraq60.jpeg",
        "__v": 0
    },
    {
        "_id": "5f4cefe395919438c44b3390",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "55SP26",
        "madein": "Italiya",
        "price": 42.2,
        "photo": "daraq77.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf07195919438c44b3391",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "55SP28",
        "madein": "Italiya",
        "price": 41.4,
        "photo": "daraq78.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf09695919438c44b3392",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "55SP29",
        "madein": "Italiya",
        "price": 48.5,
        "photo": "daraq79.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf10995919438c44b3394",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "80SP302.13",
        "madein": "Italiya",
        "price": 35.6,
        "photo": "daraq81.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf21795919438c44b3395",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "80SP302.14",
        "madein": "Italiya",
        "price": 38.1,
        "photo": "daraq82.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf23995919438c44b3396",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "SP111",
        "madein": "Italiya",
        "price": 14.6,
        "photo": "daraq83.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf25795919438c44b3397",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Keramik Fırça Daraq",
        "code": "SP114",
        "madein": "Italiya",
        "price": 23.1,
        "photo": "daraq84.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf28395919438c44b3398",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "AUSP232T1",
        "madein": "Italiya",
        "price": 52.3,
        "photo": "daraq85.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf31595919438c44b3399",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "71SP227.ASS",
        "madein": "Italiya",
        "price": 30.9,
        "photo": "daraq86.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf32f95919438c44b339a",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "SP105 NER",
        "madein": "Italiya",
        "price": 22.3,
        "photo": "daraq87.jpg",
        "__v": 0
    },
    {
        "_id": "5f4cf34e95919438c44b339b",
        "brand": "Janeke",
        "category": 94,
        "subcat": "Fırça Daraq",
        "code": "SP08GV MAC",
        "madein": "Italiya",
        "price": 60.4,
        "photo": "daraq88.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card key={this.state.products[a]._id}
          id={this.state.products[a]._id} productimg={prodLogo}
          loaded={true} pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Fırçalar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
