import React, { Component } from "react";
import Logo from './aksalogo.png'
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Col } from 'react-bootstrap';

export default class Navbars extends Component{
  render() {
    return (
      <div className="Navbar" style={{backgroundColor: 'black'}}>
        <Col>
        <div className="logoDiv">
        <a href='/'><img className='Logo' src={Logo} alt="Aksa Cosmetics" /></a>
        </div>
        </Col>
        <Col className="colcol" >
        <div className="contacts">
          <div style={{height: '2rem'}}>
          </div>
          <a className='Instagram' href='https://www.instagram.com/aksa.cosmetics/'> <FontAwesomeIcon icon={faInstagram} size="2x"/> </a>
          <a className='Instagram' href='https://www.facebook.com/aksa.cosmetics/'> <FontAwesomeIcon icon={faFacebook} size="2x"/> </a>
          <a className='Instagram' href='https://api.whatsapp.com/send?phone=994502458539'> <FontAwesomeIcon icon={faWhatsapp} size="2x"/> </a>
        </div>
        </Col>
      </div>

    );
  }
}
