import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class Stools extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f513968a693e7417c40af28",
        "brand": "Ceiotti",
        "category": 33,
        "subcat": "Taburet",
        "code": "Jerry",
        "madein": "Italiya",
        "photo": "stul01.jpg",
        "__v": 0
    },
    {
        "_id": "5f5139a5a693e7417c40af2a",
        "brand": "Ceiotti",
        "category": 33,
        "subcat": "Taburet",
        "code": "Tao",
        "madein": "Italiya",
        "photo": "stul03.jpg",
        "__v": 0
    },
    {
        "_id": "5f513a66a693e7417c40af2b",
        "brand": "Alpeda",
        "category": 33,
        "subcat": "Uşaq Qoltuğu",
        "code": "Car Seat",
        "madein": "Türkiyə",
        "photo": "stul04.jpg",
        "__v": 0
    },
    {
        "_id": "5f513a73a693e7417c40af2c",
        "brand": "Alpeda",
        "category": 33,
        "subcat": "Uşaq Qoltuğu",
        "code": "Cat",
        "madein": "Türkiyə",
        "photo": "stul05.jpg",
        "__v": 0
    },
    {
        "_id": "5f513a7ba693e7417c40af2d",
        "brand": "Alpeda",
        "category": 33,
        "subcat": "Uşaq Qoltuğu",
        "code": "Cooper",
        "madein": "Türkiyə",
        "photo": "stul06.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(<Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}/>);
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Stullar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
