import React, { Component } from "react";
import Navbar from './Navbar'
import Lowernav from './Lowernav'
import Card from './Card'
import { Row, Col, Container } from 'react-bootstrap';
import './WomanFurniture.css'
import axios from 'axios';
import Footer from './Footer'
import ThirdHeader from './ThirdHeader'

export default class CosmeticBeds extends Component{
  constructor(props) {
    super(props);
    this.state = {
      products: [
    {
        "_id": "5f58dd4821ef2c36dc83b430",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Kosmetoloji Yataq",
        "code": "01",
        "madein": "Çin",
        "photo": "yataq05.jpg",
        "__v": 0
    },
    {
        "_id": "5f58ddd621ef2c36dc83b431",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Kosmetoloji Yataq",
        "code": "42",
        "madein": "Çin",
        "photo": "yataq06.jpg",
        "__v": 0
    },
    {
        "_id": "5f58dde021ef2c36dc83b432",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Kosmetoloji Yataq",
        "code": "06F",
        "madein": "Çin",
        "photo": "yataq07.jpg",
        "__v": 0
    },
    {
        "_id": "5f58de0921ef2c36dc83b433",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Elektrikli Kosmetoloji Yataq",
        "code": "14B",
        "madein": "Çin",
        "photo": "yataq08.jpg",
        "__v": 0
    },
    {
        "_id": "5f58de1321ef2c36dc83b434",
        "brand": "Silver Fox",
        "category": 41,
        "subcat": "Elektrikli Kosmetoloji Yataq",
        "code": "46",
        "madein": "Çin",
        "photo": "yataq09.jpg",
        "__v": 0
    }
],
      loaded: true
    };
  }


  renderElements = () => {
    var elements = [];
    if(this.state.loaded){
      for(var a=0; a<this.state.products.length; a++){
        const prodLogo = require('./images/'+this.state.products[a].photo);
        elements.push(
          <Card
          key={this.state.products[a]._id}
          id={this.state.products[a]._id}
          productimg={prodLogo}
          loaded={true}
          pname={this.state.products[a].brand}
          descr={this.state.products[a].subcat + " " + this.state.products[a].code}
          price={this.state.products[a].price}/>
        );
      }
    }
    return (
      <Row className='rows'>
        {elements}
      </Row>
    );
  }

  render() {

    return (
      <div className="womanFirnutire">
        <Navbar/>
        <Lowernav/>
        <ThirdHeader cat={'Kosmetoloji Yataqlar'}/>
        <div className="listDiv">
          <Container style={{backgroundColor: 'white'}}>
            {this.renderElements()}
          </Container>
        </div>
        <Footer/>
      </div>
    );
  }
}
